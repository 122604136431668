import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CommonSettingSection from "../CommonSettingSection";
import useTranslation from "../../../localization/useTranslation";
import { TrashIcon } from "../../common/icons";
import SubUsersRules from "./SubUsersRules";
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useDispatch, useSelector } from "react-redux";
import { userCredsSelector } from "../../../redux/selectors";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { enforceSameAzureTenant } from "../../../redux/ducks/userDucks";

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TrialTip = styled.div`
  border-radius: 7px;
  background: ${(props) => props.theme.primaryColorMidLight};
  padding: 10px;
  width: fit-content;
  max-width: 300px;
`;

const Section = styled.div`
  width: 50%;
  position: relative;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

const ListStyles = {
  width: "300px",
  border: `1px solid #9b9b9b44`,
  borderRadius: "6px",
  height: "120px",
  overflowX: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    height: "8px",
    width: "4px",
    backgroundColor: "#e0e0e0",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888888",
    borderRadius: "6px",
  },
};

const SubUsers = ({
  subUserEmail = "",
  onChangeSubUserEmail,
  addNewSubUser,
  isValidSubUserEmail = true,
  resetSubUserEmailValidation,
  subUsersEmailsList,
  availableSeats = 0,
  canAddMoreSeats = true,
  removeSubUser,
  isSeatsUpdateInProgress = false,
  // isTrialUser,
}) => {
  const { labelStrings } = useTranslation();
  const dispatch = useDispatch();

  const { azureAdData } = useSelector(userCredsSelector) || {};

  const [checked, setChecked] = useState(false);

  const handleChange = ({ target }) => {
    const { checked } = target || {}
    setChecked(checked);
    dispatch(enforceSameAzureTenant({
      isEnforced: checked
    }))
  };

  useEffectOnce(() => {
    setChecked(azureAdData.azureSameTenantEnforced);
  }, azureAdData.azureSameTenantEnforced);

  const Content = (
    <>
      <ContentWrapper>
        <SubUsersRules />
        <Section>
          <Title noBold>
            {canAddMoreSeats
              ? labelStrings.ableToAddMoreMembers(
                  availableSeats >= 1 ? availableSeats - 1 : availableSeats,
                )
              : labelStrings.unableToAddMoreMembers}
          </Title>
          <Box sx={{ my: 1, maxWidth: "299px" }}>
            <OutlinedInput
              size='small'
              disabled={!canAddMoreSeats}
              placeholder={labelStrings.enterCompanyMemberEmail}
              onChange={onChangeSubUserEmail}
              value={subUserEmail}
              error={!isValidSubUserEmail}
              onFocus={resetSubUserEmailValidation}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addNewSubUser();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    disabled={!subUserEmail}
                    aria-label='Add'
                    onClick={addNewSubUser}
                    edge='end'
                  >
                    <AddRoundedIcon
                      sx={{ fill: subUserEmail ? "#008000" : "default" }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ width: "100%" }}
            />
          </Box>

          {!!subUsersEmailsList?.length && (
            <List sx={ListStyles} dense={true}>
              {subUsersEmailsList?.map((email) => (
                <ListItem
                  key={email}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FFEFEB",
                    },
                  }}
                >
                  <ListItemText primary={email} />
                  <ListItemIcon sx={{ mr: 0 }} onClick={removeSubUser(email)}>
                    <TrashIcon height='15px' width='15px' />
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          )}
        </Section>
      </ContentWrapper>
      {azureAdData.azureTenantId && (
        <Stack sx={{ mt: 4 }}>
          <Typography
            sx={{ fontFamily: "Inter", fontWeight: 600, fontSize: "15px" }}
          >{`${labelStrings.azureAdAccountSettingsLabel} (Tenant ID: ${azureAdData.azureTenantId})`}</Typography>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Checkbox checked={checked} onChange={handleChange} />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                whiteSpace: "pre-wrap",
              }}
            >
              {labelStrings.azureAdAccountSettingsCheckBoxLabel}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );

  const DisabledTip = <TrialTip>{labelStrings.notAvailableInTrial}</TrialTip>;

  return (
    <CommonSettingSection
      title={labelStrings.companyMembers}
      Content={Content}
      withLoading
      isLoading={isSeatsUpdateInProgress}
      // isDisabled={isTrialUser} //THIS MIGHT CHANGE IN FUTURE
      DisabledTip={DisabledTip}
    />
  );
};

SubUsers.propTypes = {
  onChangeSubUserEmail: PropTypes.func,
  subUserEmail: PropTypes.string,
  addNewSubUser: PropTypes.func,
  isValidSubUserEmail: PropTypes.bool,
  resetSubUserEmailValidation: PropTypes.func,
  subUsersEmailsList: PropTypes.arrayOf(PropTypes.string),
  availableSeats: PropTypes.number,
  canAddMoreSeats: PropTypes.bool,
  removeSubUser: PropTypes.func,
  isSeatsUpdateInProgress: PropTypes.bool,
};

export default SubUsers;
