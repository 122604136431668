import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { Stack } from "@mui/material"
import { NavbarLinks } from "./NavbarLinks"
import {
  isUserAuthenticatedSelector,
  userGrantSelector,
} from "../../../redux/selectors"
import { toggleSignIn } from "../../../redux/ducks/appSettingsDucks"

const NavbarContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isUserAuthorized = useSelector(isUserAuthenticatedSelector)
  const { enable_collections, enable_tracking } = useSelector(userGrantSelector)

  const onLoginClick = () => dispatch(toggleSignIn())

  return (
    <Stack sx={{ p: 1.5, width: "100%" }} direction='row' alignItems='center'>
      <NavbarLinks
        isUserAuthorized={isUserAuthorized}
        allowCollections={!!enable_collections}
        allowTracking={!!enable_tracking}
        onLoginClick={onLoginClick}
        currentPagePath={location.pathname}
      />
    </Stack>
  )
}

export default NavbarContainer
