import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../../localization/useTranslation'
import { AddCampaignIcon } from '../../../../components/common/icons'
import EditCampaignModal from '../../../../components/campaigns/campaignTools/EditCampaignModal'
import WithUpgradeTip from '../../../../components/common/popups/WithUpgradeTip'


const CreateCampaignWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  justify-content: flex-start;
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
  ${props => props.disabled && props.theme.disableBlock}
`

const CreateCampaignTitleWrapper = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 15px;
  margin: 0 10px;
`

const CreateCampaignItem = ({ canCreateCampaigns = true }) => {
  const { labelStrings } = useTranslation()
  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = useState(false)

  const toggleIsCreateCampaignOpen = () => {
    if (!canCreateCampaigns) return
    setIsCreateCampaignOpen(!isCreateCampaignOpen)
  }

  const CreateCampaignComponent = (
    <CreateCampaignWrapper disabled={!canCreateCampaigns} onClick={toggleIsCreateCampaignOpen}>
      <AddCampaignIcon isActiveIcon={false} tabIcon />
      <CreateCampaignTitleWrapper>{labelStrings.createNewCampaign}</CreateCampaignTitleWrapper>
    </CreateCampaignWrapper>
  )

  return canCreateCampaigns ? (
    <>
      {CreateCampaignComponent}
      {isCreateCampaignOpen && (
        <EditCampaignModal isNewCampaign closeModal={toggleIsCreateCampaignOpen} />
      )}
    </>
  ) : (
    <WithUpgradeTip tipText={labelStrings.createUnlimitedCampaigns}>
      {CreateCampaignComponent}
    </WithUpgradeTip>
  )
}

CreateCampaignItem.propTypes = {
  canCreateCampaigns: PropTypes.bool,
  createNewCampaign: PropTypes.func,
}

export default CreateCampaignItem
