import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { withReduxActions } from '../hocs/withRedux'
import { setError } from '../redux/ducks/errorDucks'
import { ERROR_MSG } from '../constants/errorMessages'
import httpService from '../services/httpService'

import { WithPopupWrapper } from '../components/common/styledWrappers'
import ResetPasswordForm from '../components/forms/forgetPassword/ResetPasswordForm'
import SuccessResetPasswordDialog from '../components/common/dialogs/SuccessResetPasswordDialog'
import LoadingSpinner from '../components/common/spinners/LoadingSpinner'

const ResetPasswordPage = ({ setError }) => {
  const location = useLocation()
  const history = useHistory()

  const [userData, setUserData] = useState({
    password: '', // will be new password
    token: '',
    successReset: false,
    isLoading: false,
  })

  useEffect(() => {
    const { token } = queryString.parse(location.search);
    // Redirect if any parameter is missing
    if (!token) history.push('/search');
    setUserData({ ...userData, token });
  }, []);

  const successResetPasswordHandler = () => {
    history.push('/search')
  }

  const resetPasswordHandler = async (password) => {
    const { token } = userData;
    if (!token) return;
  
    try {
      setUserData({ ...userData, isLoading: true });
      const response = await httpService.fetchResetPassword(
        JSON.stringify({
          password,
          token, // Include the token here
        })
      );
      if (response.ok) {
        setUserData({ ...userData, successReset: true, isLoading: false });
      } else {
        throw ERROR_MSG.resetPassFail;
      }
    } catch (err) {
      setError(ERROR_MSG.resetPassFail);
      setUserData({ ...userData, isLoading: false });
    }
};
  

  return (
    <WithPopupWrapper fullScreen width="440px">
      {userData.isLoading && <LoadingSpinner isTransparent showOnFullPage isOuterPageLoad />}
      <Popup open={true} position="right center" closeOnDocumentClick={false} modal>
        {userData.successReset ? (
          <SuccessResetPasswordDialog successResetPasswordHandler={successResetPasswordHandler} />
        ) : (
          <ResetPasswordForm resetPasswordHandler={resetPasswordHandler} />
        )}
      </Popup>
    </WithPopupWrapper>
  )
}

ResetPasswordPage.propTypes = {
  setError: PropTypes.func,
}

export default withReduxActions({ setError })(ResetPasswordPage)
