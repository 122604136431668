import React from "react";
import PropTypes from "prop-types";
import { Stack, Box, Typography } from "@mui/material";
import useTranslation from "../../../localization/useTranslation";
import ProgressBar from "../../common/progressBar";
import { formatDateToDDMMYYY } from "../../../utils";

const StatSection = ({
  title,
  current,
  limit,
  remainingText,
  labelStrings,
}) => (
  <Stack>
    <Typography sx={styles.title}>{title}</Typography>
    {limit === -1 ? (
      <Typography sx={styles.subtitle}>{labelStrings.unlimited}</Typography>
    ) : (
      <Stack>
        <ProgressBar current={current} limit={limit} />
        <Typography sx={styles.subtitle}>{remainingText}</Typography>
      </Stack>
    )}
  </Stack>
);
const TokensRemainingStat = ({
  audienceTokensTotal = 20,
  searchProfileRequestTokensTotal = 0,
  profileAnalyticsTokensTotal = 0,

  usedAudienceTokens = 0,
  usedProfileAnalyticsTokens = 0,
  usedSearchResultsTokens = 0,

  audienceTokensAvailable = 0,
  searchResultsTokensAvailable = 0,
  profileAnalyticsTokensAvailable = 0,

  TrialComponent = null,
  // audienceReportTokensResetDate = 0,
  analyticsNextResetDate = '',
  isUnlimitedAudienceReports = false
}) => {
  const { labelStrings } = useTranslation();
  return (
    <Box sx={styles.container}>
      <Stack spacing={1}>
        <StatSection
          title={labelStrings.audienceReportTokens}
          current={audienceTokensAvailable}
          limit={isUnlimitedAudienceReports ? -1 : audienceTokensTotal}
          remainingText={`${audienceTokensAvailable} ${labelStrings.remaining} (${labelStrings.of} ${audienceTokensTotal})`}
          labelStrings={labelStrings}
        />
        <StatSection
          title={labelStrings.searchResultsLimit}
          current={searchResultsTokensAvailable}
          limit={searchProfileRequestTokensTotal}
          remainingText={`${searchResultsTokensAvailable} ${labelStrings.remaining} (${labelStrings.of} ${searchProfileRequestTokensTotal})`}
          labelStrings={labelStrings}
        />
        <StatSection
          title={labelStrings.profileAnalyticsLimit}
          current={profileAnalyticsTokensAvailable}
          limit={profileAnalyticsTokensTotal}
          remainingText={`${profileAnalyticsTokensAvailable} ${labelStrings.remaining} (${labelStrings.of} ${profileAnalyticsTokensTotal})`}
          labelStrings={labelStrings}
        />
      </Stack>

      {analyticsNextResetDate && (
        <Typography sx={styles.resetDate}>
          {labelStrings.tokensWillBeAddedOnAnalytics}{" "}
          {formatDateToDDMMYYY({
            dateStr: new Date(analyticsNextResetDate).toString(),
          })}
        </Typography>
      )}

      {TrialComponent && <Box sx={styles.trialComponent}>{TrialComponent}</Box>}
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: "#60403F",
    minHeight: "80px",
    px: 1,
    py: 2,
    color: "#fff",
  },
  title: {
    textAlign: "center",
    fontSize: "11px",
    fontFamily: "Inter",
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  },
  resetDate: {
    textTransform: "initial",
    width: "100%",
    mt: "12px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "11px",
    letterSpacing: "0.5px",
    textAlign: 'center'
  },
  trialComponent: {
    fontSize: "10px",
    fontWeight: 600,
    fontFamily: "Inter",
    textTransform: "none",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: "10px 5px 0 5px",
    "& .click_trial_activate": {
      fontWeight: 600,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
};

TokensRemainingStat.propTypes = {
  audienceTokensTotal: PropTypes.number,
  profileAnalyticsTokensTotal: PropTypes.number,
  searchProfileRequestTokensTotal: PropTypes.number,

  audienceTokensAvailable: PropTypes.number,
  profileAnalyticsTokensAvailable: PropTypes.number,
  searchResultsTokensAvailable: PropTypes.number,

  usedAudienceTokens: PropTypes.number,
  usedProfileAnalyticsTokens: PropTypes.number,
  usedSearchResultsTokens: PropTypes.number,
  
  // audienceReportTokensResetDate: PropTypes.number,
  analyticsNextResetDate: PropTypes.string,
  isUnlimitedAudienceReports: PropTypes.bool,
  TrialComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
};

export default TokensRemainingStat;
