import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CenterWrapper } from '../common/styledWrappers'
import { ArrowTop, ExclamationPoint } from '../common/icons'
import useTranslation from '../../localization/useTranslation'

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TipMainTextWrapper = styled.div`
  max-width: ${props => props.isExplore ? '100%' : '500px'};
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  color: ${props => props.theme.textColor};
  letter-spacing: 0.8px;
  ${props => props.noPlan && `
    ${props.theme.getTextGradientStyles({ platform: props.platform })};
    font-size: 30px;
    font-family: Inter;
    font-weight: 700;
    margin-left: 0;
    text-transform: none;
    `
    };
`

const TipForUnregisteredWrapper = styled(TipMainTextWrapper)`
  letter-spacing: 0px;
  width: 510px;
  font-weight: 400;
  text-transform: unset;
  font-size: 14px;
`

const UseTipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TipIconWrapper = styled.span`
  ${props => props.theme.flex.centerAll};

  & svg {
    ${props =>
      props.withBorder &&
      `
      border: 2px solid ${props.theme.textColor};
      padding: 2px;
    `}
    height: ${props => props.height || 18}px;
    width: ${props => props.width || 18}px;
    border-radius: 4px;
    stroke: ${props => props.theme.textColor};
  }
`

const CenterTipWrapper = styled(CenterWrapper)`
  min-height: 86vh;
`

const SearchResultsPageTip = ({ isUserAuthenticated = false, noResults = true, noPlan = false, isExplore = false, platform = '' }) => {
  const { labelStrings } = useTranslation()

  let tipTitle = isExplore
    ? labelStrings.explorePage.useRecentSearchesOnTheLeft
    : labelStrings.useSearchEngine
  let tipText = labelStrings.useSearchEngineText
  let icon = (
    <TipIconWrapper withBorder>
      <ArrowTop />
    </TipIconWrapper>
  )
  // console.log('CHECK USER AUTH', isUserAuthenticated)
  if (!isUserAuthenticated) {
    tipTitle = labelStrings.notLoggedInTitle
    tipText = labelStrings.notLoggedInText
  } else if (noPlan) {
    tipTitle = labelStrings.notActivatedAccountTipTitle
    tipText = labelStrings.notActivatedAccountTipText
    icon = null
  } else if (noResults) {
    tipTitle = labelStrings.noResultsFound
    icon = null
  }
  return (
    <CenterTipWrapper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <MainWrapper>
        <UseTipWrapper>
          {isUserAuthenticated && icon}
          <TipMainTextWrapper isExplore={isExplore} noPlan={noPlan} platform={platform?.toLocaleLowerCase()}>
            {tipTitle}
          </TipMainTextWrapper>
        </UseTipWrapper>
        {tipText && (
          <TipForUnregisteredWrapper style={{ textAlign: 'center' }}>
            {tipText}
          </TipForUnregisteredWrapper>
        )}
      </MainWrapper>
    </CenterTipWrapper>
  )
}

SearchResultsPageTip.propTypes = {
  isUserAuthenticated: PropTypes.bool,
  noResults: PropTypes.bool,
  noPlan: PropTypes.bool,
}

export default SearchResultsPageTip
