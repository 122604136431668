import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { contentListSelector } from '../../../../../redux/selectors'
import useTranslation from '../../../../../localization/useTranslation'
import { StyledCardTitle, StyledPaper } from '../../../../../new-ui/sections/dashboard/styles'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { fDateTime } from '../../../../../new-ui/utils/formatTime'
import { fShortenNumber } from '../../../../../new-ui/utils/formatNumber'
import {
  CARD_HEIGHT,
  CARD_HEIGHT_BRAND_CONTENT,
  getPercent,
} from '../../../../../new-ui/components/carousel/published-content/utils'
import { GroupItemCarousel } from '../../../../../new-ui/components/carousel/published-content/GroupsItemCarousel'
import { WrapperMediaItem } from '../../../../../new-ui/components/carousel/published-content/WrapperMediaItem'
import { PlayingVideo } from '../../../../../new-ui/components/carousel/published-content/PlayingVideo'
import Image from '../../../../../new-ui/components/image'
import { pretifyBigNum } from '../../../../../utils'
import { USER_INDEXES } from '../../../../../constants/appSettings'

export const BrandedContentItem = props => {
  const { item, isNotWidget, isDataEnrichment, currencyFromStorage, setCurrentCreatorsData, onGetSimilarContentPieces } = props

  const {
    nonRecognizedContent: { isLoading, dataSuccess },
  } = useSelector(contentListSelector) || {}

  const { labelStrings } = useTranslation()

  const {
    imageUrl,
    uploaded,
    likes,
    comments,
    reach,
    valueTo,
    valueFrom,
    contentType,
    platform,
    videoUrl,
    videoUrls,
    imageUrls,
    // engagementRate,
    platformLink,
    username,
    user_followers,
    mentions,
    contentId
  } = item

  const isDownloadContentModal =
    item.platform === USER_INDEXES.instagram && (contentType === 'post' || contentType === 'reel')

  const [startVideo, setStartVideo] = useState(false)

  const [isLoadingAddToCampaign, setIsLoadingAddToCampaign] = useState(false)
  const [addToCampaignSuccess, setIsAddToCampaignSuccess] = useState(false)

  const elementRef = useRef(null)
  const [heightElement, setHeightElement] = useState(0)
  const [widthElement, setWidthElement] = useState(0)

  useEffect(() => {
    if (isLoadingAddToCampaign && !addToCampaignSuccess) {
      setIsAddToCampaignSuccess(dataSuccess)
      setIsLoadingAddToCampaign(isLoading)
    }
  }, [dataSuccess, isLoadingAddToCampaign])

  useLayoutEffect(() => {
    setHeightElement(elementRef.current.offsetHeight)
    setWidthElement(elementRef.current.offsetWidth)
  }, [heightElement, widthElement])

  const onHandlePlayVideo = () => {
    setStartVideo(!startVideo)
  }

  const onHandleSetCurrCreatorsData = data => {
    setCurrentCreatorsData && setCurrentCreatorsData(data)
  }

  return (
    <>
      <StyledPaper
        sx={{
          marginBottom: isNotWidget ? '16px' : 0,
          height: '570px',
          backgroundColor: '#FFF',
          border: `0.5px solid #CBB3B3`,
        }}
      >
        <Box
          sx={{
            p: 1,
            position: 'relative',
            height: `calc(${CARD_HEIGHT}px - ${heightElement}px)`,
          }}
        >
          <Box
            sx={{
              height: `calc(520px - ${heightElement}px)`,
            }}
          >
            {videoUrls?.length || imageUrls?.length || contentType === 'story_group' ? (
              <GroupItemCarousel
                videoUrls={videoUrls}
                imageUrls={imageUrls}
                heightElement={heightElement}
                widthElement={widthElement}
                onHandleSetCurrCreatorsData={() => onHandleSetCurrCreatorsData(item)}
                isDownloadContentModal={isDownloadContentModal}
              />
            ) : (
              <>
                {videoUrl ? (
                  <WrapperMediaItem
                    contentType={contentType}
                    platform={platform}
                    widthElement={widthElement}
                    heightElement={heightElement}
                    isDataEnrichment={isDataEnrichment}
                    isNotWidget={isNotWidget}
                    contentVideo={videoUrl}
                    contentImg={imageUrl}
                    onHandleSetCurrCreatorsData={() => onHandleSetCurrCreatorsData(item)}
                    isDownloadContentModal={isDownloadContentModal}
                    onGetSimilarContentPieces={() => onGetSimilarContentPieces(contentId)}
                  >
                    <Card sx={{ width: '100%', height: '100%' }}>
                      <PlayingVideo
                        singleVideo={videoUrl}
                        singleVideoPreview={imageUrl}
                        contentImg={imageUrl}
                        startVideo={startVideo}
                        setStartVideo={setStartVideo}
                        onHandlePlayVideo={onHandlePlayVideo}
                      />
                    </Card>
                  </WrapperMediaItem>
                ) : (
                  <>
                    <WrapperMediaItem
                      contentType={contentType}
                      platform={platform}
                      widthElement={widthElement}
                      heightElement={heightElement}
                      isDataEnrichment={isDataEnrichment}
                      isNotWidget={isNotWidget}
                      contentVideo={videoUrl}
                      contentImg={imageUrl}
                      onHandleSetCurrCreatorsData={() => onHandleSetCurrCreatorsData(item)}
                      isDownloadContentModal={isDownloadContentModal}
                      onGetSimilarContentPieces={() => onGetSimilarContentPieces(contentId)}
                    >
                      {platformLink ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.open(platformLink, '_blank')}
                        >
                          <Image
                            alt="cover"
                            src={imageUrl}
                            ratio="1/1"
                            sx={{ borderRadius: '9px', height: `calc(520px - ${heightElement}px)` }}
                          />
                        </div>
                      ) : (
                        <Image
                          alt="cover"
                          src={imageUrl}
                          ratio="1/1"
                          sx={{ borderRadius: '9px', height: `calc(520px - ${heightElement}px)` }}
                        />
                      )}
                    </WrapperMediaItem>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
        <Stack>
          <Typography sx={{ mb: 1, px: 3, fontSize: '18px', fontWeight: 700, color: '#BC9797' }}>
            {username}
          </Typography>
          <Divider variant="fullWidth" sx={{ borderColor: '#CBB3B3' }} />
        </Stack>
        <Stack spacing={2.5} sx={{ p: 3, pb: 2.5, pt: '10px' }} ref={elementRef}>
          <Stack direction="row" alignItems="center" spacing={3} sx={{ color: 'text.secondary' }}>
            <Stack direction="column" alignItems="center" spacing={1}>
              <Stack direction="row" justifyContent="start" sx={{ width: '100%' }}>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: 'block',
                    color: '#BC9797',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {fDateTime(uploaded)}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }} spacing={2}>
                {typeof likes !== 'undefined' && likes !== null && (
                  <Typography
                    variant="caption"
                    sx={{ color: '#442424', fontSize: '12px', fontWeight: 700 }}
                  >
                    <StyledCardTitle sx={{ color: '#BC9797', fontSize: '12px' }}>
                      {labelStrings.likes}:
                    </StyledCardTitle>{' '}
                    {fShortenNumber(likes)}
                  </Typography>
                )}
                {typeof comments !== 'undefined' && comments !== null && (
                  <Typography
                    variant="caption"
                    sx={{ color: '#442424', fontSize: '12px', fontWeight: 700 }}
                  >
                    <StyledCardTitle sx={{ color: '#BC9797', fontSize: '12px' }}>
                      {labelStrings.comments}:{' '}
                    </StyledCardTitle>
                    {fShortenNumber(comments)}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }} spacing={2}>
                <Typography
                  variant="caption"
                  sx={{ color: '#442424', fontSize: '12px', fontWeight: 700 }}
                >
                  <StyledCardTitle sx={{ color: '#BC9797', fontSize: '12px' }}>
                    {labelStrings.reach}:
                  </StyledCardTitle>
                  {fShortenNumber(reach)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: '#442424', fontSize: '12px', fontWeight: 700, whiteSpace: 'nowrap' }}
                >
                  <StyledCardTitle sx={{ color: '#BC9797', fontSize: '12px' }}>
                    {labelStrings.value}:
                  </StyledCardTitle>
                  {' ~ '}
                  {`${pretifyBigNum((valueTo + valueFrom) / 2)}
                  ${' '}${currencyFromStorage?.toUpperCase()}`}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }}>
                <Typography
                  variant="caption"
                  sx={{ color: '#442424', fontSize: '12px', fontWeight: 700 }}
                >
                  <StyledCardTitle sx={{ color: '#BC9797', fontSize: '12px' }}>
                    Mentions:
                  </StyledCardTitle>
                  {mentions?.map((el, idx) => (idx === mentions?.length - 1 ? el : `${el},${' '}`))}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }}>
                {contentType !== 'story' && (contentType === 'reel' || contentType === 'post') && (
                  <Typography
                    variant="caption"
                    sx={{ color: '#442424', fontSize: '12px', fontWeight: 700 }}
                  >
                    <StyledCardTitle sx={{ color: '#BC9797', fontSize: '12px' }}>
                      {labelStrings.engagementMean}:
                    </StyledCardTitle>
                    {contentType === 'reel' && getPercent((likes + comments) / reach)}
                    {contentType === 'post' && getPercent((likes + comments) / user_followers)}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </StyledPaper>
    </>
  )
}

BrandedContentItem.propTypes = {
  item: PropTypes.shape({
    username: PropTypes.string,
    uploaded: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    likes: PropTypes.number,
    comments: PropTypes.number,
    reach: PropTypes.number,
    valueTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    valueFrom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    found: PropTypes.array,
    contentImg: PropTypes.string,
    contentType: PropTypes.string,
    platform: PropTypes.string,
  }),
  isNotWidget: PropTypes.bool,
  isDataEnrichment: PropTypes.bool,
  currencyFromStorage: PropTypes.string,
  setCurrentCreatorsData: PropTypes.func,
  onGetSimilarContentPieces: PropTypes.func
}
