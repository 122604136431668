import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'

import { Header, FaqTipWrapper, SidebarTitleWrapper } from './collectionsSidebarStyles'
import LinkToFaqQuestion from '../../../../components/faqs/LinkToFaqQuestion'

const CollectionsBarHeader = ({ showHeaderForDrag, isNavType }) => {
  const { labelStrings } = useTranslation()
  return (
    <Header isNavType={isNavType}>
      <SidebarTitleWrapper>
        {showHeaderForDrag ? (
          <>{labelStrings.bulkAddToCollections}</>
        ) : (
          <>
            {labelStrings.yourCollections}
            <FaqTipWrapper>
              <LinkToFaqQuestion
                extraRatio={10}
                faqQuestionId="" //TODO: FAQLINK collections sidebar title
                userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
              />
            </FaqTipWrapper>
          </>
        )}
      </SidebarTitleWrapper>
    </Header>
  )
}

CollectionsBarHeader.propTypes = {
  showHeaderForDrag: PropTypes.bool,
  isNavType: PropTypes.string
}
CollectionsBarHeader.defaultProps = {
  showHeaderForDrag: false,
  isNavType: 'default'
}

export default CollectionsBarHeader
