import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"

import { debouncedAsync } from "../../../../../utils"
import { Button } from "@mui/material"
import useTranslation from "../../../../../localization/useTranslation"
import { NewHeartEmpty, NewHeartFull } from "../../../../../components/common/icons"
import WithTip from "../../../../../components/common/popups/WithTip"
import WithUpgradeTip from "../../../../../components/common/popups/WithUpgradeTip"

const IconWrapper = styled.div`
  ${(props) => props.disabled && props.theme.disableBlock};
  position: relative;
`

const ProfileFavsTool = ({
  isFav,
  removeFromFavs,
  addToFavs,
  userindex,
  canAddToFavs,
  enableFavorites,
  isUserAuthenticated,
  isQuickPreview
}) => {
  const [favSelected, toggleFavSelected] = useState(isFav) // bool
  const { labelStrings } = useTranslation()

  const [isLoaded, setIsLoaded] = useState(false)

  const changeUserFavs = debouncedAsync(async (latestFav) => {
      setIsLoaded(true)
      if (!latestFav && !canAddToFavs) return await removeFromFavs()
      if (enableFavorites && canAddToFavs) {
        return await (latestFav ? addToFavs() : removeFromFavs())
      }
    })

  useEffect(() => {
    if (!canAddToFavs && favSelected && !isFav) toggleFavSelected(false)
  }, [canAddToFavs])

  useEffect(() => {
    toggleFavSelected(isFav)
  }, [isFav])

  useEffect(() => {
    if (isFav === favSelected) return
    ;(async () => {
      const success = await changeUserFavs(favSelected)

      if (success) setIsLoaded(false)
      if (!success) toggleFavSelected(!favSelected)
    })()
  }, [favSelected])

  const FullHeartIcon = (
    <IconWrapper disabled={!enableFavorites}>
      <NewHeartFull userindex={userindex} />
    </IconWrapper>
  )
  const EmptyHeartIcon = (
    <IconWrapper disabled={!enableFavorites}>
      <NewHeartEmpty userindex={userindex} isActiveIcon={false} />
    </IconWrapper>
  )

  const clickHandler = () => {
    if (isLoaded) return
    if (!enableFavorites) return
    if (!canAddToFavs && !isFav) return
    toggleFavSelected(!favSelected)
  }

  return (
    <Button
      onClick={clickHandler}
      disableFocusRipple
      disableRipple
      sx={{
        fontWeight: 400,
        width: "fit-content",
        minWidth: "30px",
        cursor: isLoaded ? "wait" : "pointer",
        "&:hover": {
          backgroundColor: "transparent",
          span: {
            cursor: isLoaded ? "wait" : "pointer",
            svg: {
              cursor: isLoaded ? "wait" : "pointer",
            },
          },
        },
        svg: {
          opacity: isLoaded ? 0.5 : 1,
        },
        p: 0,
      }}
    >
      {!enableFavorites || !canAddToFavs ? (
        !canAddToFavs && favSelected ? (
          <WithTip
            tipText={labelStrings.removeFromFavorites}
            position={isQuickPreview ? 'bottom center' : 'top center'}
            arrow={isQuickPreview ? false : true}
            userindex={userindex}
          >
            <span>{FullHeartIcon}</span>
          </WithTip>
        ) : (
          <WithUpgradeTip
            tipText={labelStrings.addMoreUsers}
            userindex={userindex}
            withSignup={!isUserAuthenticated}
            position={isQuickPreview ? 'bottom center' : 'top center'}
            arrow={isQuickPreview ? false : true}
          >
            {EmptyHeartIcon}
          </WithUpgradeTip>
        )
      ) : (
        <WithTip
          tipText={
            favSelected
              ? labelStrings.removeFromFavorites
              : labelStrings.addToFavorites
          }
          position={isQuickPreview ? 'bottom center' : 'top center'}
          arrow={isQuickPreview ? false : true}
          userindex={userindex}
        >
          <span>{favSelected ? FullHeartIcon : EmptyHeartIcon}</span>
        </WithTip>
      )}
    </Button>
  )
}

export default ProfileFavsTool
