import React from "react"
import {
  StyledSocialPlatformTab,
  StyledSocialPlatformTabs,
} from "../../../components/nav-section/CreatorsSuggestions/styles"
import {
  InstagramSwitchIcon,
  TikTokSwitchIcon,
  YouTubeSwitchIcon,
} from "../../../components/nav-section/CreatorsSuggestions/icons"
import { SOCIAL_PLATFORMS_NAMES } from "../../../../constants/appSettings"
import { userGrantSelector } from "../../../../redux/selectors"
import { shallowEqual, useSelector } from "react-redux"

export const ChipPlatforms = ({ platform, changeSocialPlatform }) => {
  const { enable_ig_search, enable_tt_search, enable_yt_search } = useSelector(
    userGrantSelector,
    shallowEqual,
  )

  return (
    <StyledSocialPlatformTabs value={platform} onChange={changeSocialPlatform}>
      <StyledSocialPlatformTab
        disabled={!enable_ig_search}
        sx={{ marginRight: "6px !important", width: "42px" }}
        currentTab={platform}
        isActive={platform === SOCIAL_PLATFORMS_NAMES.instagram.name}
        aria-label='Instagram'
        value={SOCIAL_PLATFORMS_NAMES.instagram.name}
        icon={
          <InstagramSwitchIcon
            sx={{ fontSize: 22 }}
            color={
              platform === SOCIAL_PLATFORMS_NAMES.instagram.name
                ? "white"
                : "#A06E6E"
            }
          />
        }
      />
      <StyledSocialPlatformTab
        disabled={!enable_tt_search}
        sx={{ marginRight: "6px !important", width: "42px" }}
        currentTab={platform}
        isActive={platform === SOCIAL_PLATFORMS_NAMES.tiktok.name}
        aria-label='TikTok'
        value={SOCIAL_PLATFORMS_NAMES.tiktok.name}
        icon={
          <TikTokSwitchIcon
            sx={{ fontSize: 18 }}
            color={
              platform === SOCIAL_PLATFORMS_NAMES.tiktok.name
                ? "white"
                : "#A06E6E"
            }
          />
        }
      />
      <StyledSocialPlatformTab
        disabled={!enable_yt_search}
        sx={{ marginRight: "0 !important", width: "42px" }}
        currentTab={platform}
        isActive={platform === SOCIAL_PLATFORMS_NAMES.youtube.name}
        aria-label='YouTube'
        value={SOCIAL_PLATFORMS_NAMES.youtube.name}
        icon={
          <YouTubeSwitchIcon
            sx={{ fontSize: 22 }}
            color={
              platform === SOCIAL_PLATFORMS_NAMES.youtube.name
                ? "white"
                : "#A06E6E"
            }
          />
        }
      />
    </StyledSocialPlatformTabs>
  )
}
