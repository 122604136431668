import React from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput
} from "@mui/material"
import { getPngCountryFlags } from "../../../../../utils"

const langLabels = {
  ['en']: 'gb',
  ['da']: 'dk',
  ['iw']: 'il',
  ['ja']: 'jp',
  ['el']: 'gr',
  ['zh']: 'cn',
  ['hi']: 'in',
  ['ko']: 'kr',
  ['fa']: 'ir',
  ['sw']: 'za',
  ['uk']: 'ua',
  ['ur']: 'pk',
  ['ta']: 'lk',
  ['te']: 'in'
}

export const LanguageFilter = (props) => {
  const { selectedPlatform, query, handlersMap } = props || {}

  const { labelStrings, languageOptions } = useTranslation()

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {}
    handlersMap?.language(value)
  }

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.selectLanguage}
        </InputLabel>
        <Select
          size='small'
          value={query.language ? query.language : ""}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.selectLanguage} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                color: "#442424",
              },
            },
          }}
        >
          {languageOptions?.map((lang) => (
            <MenuItem key={lang?.isoCode} value={lang?.value} sx={{ fontSize: "12px", fontWeight: 700, fontFamily: "Inter" }}>
              <Stack direction='row' alignItems='center' sx={{ textTransform: 'capitalize' }}>
                {getPngCountryFlags(langLabels[lang.value] || lang.value)}
                {lang.label}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
