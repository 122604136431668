import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { searchContentTypes } from "../../constants/search";
import { useSelector } from "react-redux";
import { isUserAuthenticatedSelector } from "../../redux/selectors";
import { Stack, Button, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import useTranslation from "../../localization/useTranslation";
import { BrandIcon, ContentIcon, CreatorIcon } from "../common/icons/icons";
import { bgGradients } from "../../new-ui/components/nav-section/CreatorsSuggestions/styles";

export const StyledBtn = styled(Button)(({ isActive, userindex }) => ({
  width: "60px",
  minWidth: "60px",
  '& .MuiTypography-root': {
    fontSize: "11px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: isActive ? "#FFFFFF" : "#442424",
    "&:disabled": {
      color: "#442424",
    },
  },

  '& svg': {
    color: isActive ? "#FFFFFF" : "#442424",
    "&:disabled": {
      color: "#442424",
    },
  },

  height: "55px",
  background: isActive
    ?  bgGradients[userindex?.toUpperCase()]
    : "#F6DBDB",
  "&:hover": {
    background: isActive
      ?  bgGradients[userindex?.toUpperCase()]
      : "#F6DBDB",
    color: isActive ? "#FFFFFF" : "#442424",
    filter: "brightness(95%)",
  },
  "&:disabled": {
    opacity: 0.5,
    color: "#442424",
    backgroundColor: "#F6DBDB",
    background: "#F6DBDB",
  },
}));

export const ToggleSwitchSearchContent = (props) => {
  const { checked, onChange, isContentSearch, userindex } = props;

  const { labelStrings } = useTranslation();

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);

  useEffect(() => {
    if (!isContentSearch && checked === searchContentTypes.CONTENT) {
      onChange();
    }
  }, [checked, isContentSearch]);

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <StyledBtn
        onClick={() => onChange(searchContentTypes.CREATOR)}
        disabled={!isUserAuthenticated}
        isActive={checked === searchContentTypes.CREATOR}
        variant='contained'
        userindex={userindex}
      >
        <Stack alignItems='center' spacing={0.5}>
          <CreatorIcon />
          <Typography>{labelStrings.creator}</Typography>
        </Stack>
      </StyledBtn>
      <StyledBtn
        onClick={() => onChange(searchContentTypes.BRAND)}
        disabled={!isUserAuthenticated}
        isActive={checked === searchContentTypes.BRAND}
        userindex={userindex}
        variant='contained'
        sx={{ lineHeight: "1.2" }}
      >
        <Stack alignItems='center' spacing={0.5}>
          <BrandIcon />
          <Typography>{labelStrings.brand}</Typography>
        </Stack>
      </StyledBtn>
      <Tooltip
        title={
          !isContentSearch ? labelStrings.disabledFunctionInYourPlanTip : ""
        }
        arrow
        placement='bottom-start'
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: "250px",
              fontSize: "13px",
              fontFamily: "Inter",
              p: 1,
              px: 2,
              backgroundColor: "#F6DBDB",
              color: "black",
              "& .MuiTooltip-arrow": {
                color: "#F6DBDB",
              },
            },
          },
        }}
      >
        <div>
          <StyledBtn
            onClick={() => onChange(searchContentTypes.CONTENT)}
            disabled={!isUserAuthenticated || !isContentSearch}
            isActive={checked === searchContentTypes.CONTENT}
            variant='contained'
            userindex={userindex}
          >
            <Stack alignItems='center' spacing={0.5}>
              <ContentIcon />
              <Typography>{labelStrings.content}</Typography>
            </Stack>
          </StyledBtn>
        </div>
      </Tooltip>
    </Stack>
  );
};

ToggleSwitchSearchContent.propTypes = {
  checked: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isContentSearch: PropTypes.bool.isRequired,
};

ToggleSwitchSearchContent.defaultProps = {
  isContentSearch: false,
  checked: "",
  onChange: () => {},
};
