import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Avatar, ListItemAvatar, IconButton } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import useTranslation from "../../../../../localization/useTranslation"
import { StyledListItem, StyledListItemText } from "./styles"
import { TooltipComponent } from "../../../tooltip/TooltipComponent"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"

export const CreatorsListItem = (props) => {
  let {
    creator = {},
    showList = false,
    isAllowedToAddCreators = false,
    creatorIdFromState = "",
    setCreatorIdFromState,
    onClearInput,
    isSetCreatorId = true,
    removeBrandMentions,
    brandMentionsValuesToArray,
  } = props || {}

  isAllowedToAddCreators = true

  const { labelStrings } = useTranslation()

  const [loadingCreator, setLoadingCreator] = useState(false)
  const [selectedCreatorId, setSelectedCreatorId] = useState(null)

  const findCreatorInTable = (creatorId) => creatorId === creatorIdFromState
  const findCreatorsInTable = (creatorId) =>
    creatorIdFromState?.includes(creatorId)
  const findMentionsFromQuery = (username) =>
    brandMentionsValuesToArray?.includes(username)

  const onOpenCreatorProfile = () => {
    window.open(
      `${process.env.REACT_APP_PREFIX}profile/${creator._id}`,
      "_blank",
    )
  }

  const handleAddCreator = (e, creator) => {
    e.stopPropagation()

    if (!isAllowedToAddCreators) return

    setCreatorIdFromState(creator)
    setSelectedCreatorId(creator)

    if (isSetCreatorId) {
      setLoadingCreator(true)
      onClearInput()
    }
  }

  useEffect(() => {
    if (findCreatorInTable(selectedCreatorId) && loadingCreator) {
      setLoadingCreator(false)
      setSelectedCreatorId(null)
    }
  }, [creatorIdFromState])

  return (
    <StyledListItem
      size='small'
      onClick={onOpenCreatorProfile}
      secondaryAction={
        <>
          {(findMentionsFromQuery(creator.username)
            ? findMentionsFromQuery(creator.username)
            : findCreatorsInTable(creator._id)) && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                removeBrandMentions(creator)
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          )}

          {!findCreatorInTable(creator._id) &&
            !findCreatorsInTable(creator._id) &&
            !findMentionsFromQuery(creator.username) && (
              <TooltipComponent
                text={
                  !isAllowedToAddCreators
                    ? labelStrings.addCreatorsLimitMessage
                    : ""
                }
              >
                <div>
                  <LoadingButton
                    loading={loadingCreator && showList}
                    variant='outlined'
                    onClick={(e) => {
                      handleAddCreator(
                        e,
                        isSetCreatorId ? creator._id : creator,
                      )
                    }}
                    sx={{ width: "32px", height: "28px", minWidth: "32px" }}
                    disabled={!isAllowedToAddCreators}
                  >
                    {!loadingCreator && <AddRoundedIcon />}
                  </LoadingButton>
                </div>
              </TooltipComponent>
            )}
        </>
      }
    >
      <ListItemAvatar>
        <Avatar
          alt={creator.username ? creator.username : creator.displayName}
          src={creator.profilePicURL}
        />
      </ListItemAvatar>
      <StyledListItemText
        primary={
          creator.username ? `@${creator.username}` : `@${creator.displayName}`
        }
      />
    </StyledListItem>
  )
}

CreatorsListItem.propTypes = {
  creator: PropTypes.object,
  showList: PropTypes.bool,
  isAllowedToAddCreators: PropTypes.bool,
  isPipeline: PropTypes.bool,
  onAssignCreators: PropTypes.func,
  creatorIdFromState: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setCreatorIdFromState: PropTypes.func,
  onClearInput: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
  removeBrandMentions: PropTypes.func,
  brandMentionsValuesToArray: PropTypes.array,
}
