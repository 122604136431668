import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Box, IconButton, InputAdornment, Stack } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import { SocialPlatformSwitcher } from "./SocialPlatformSwitcher"
import { CreatorsList } from "./CreatorsList"
import { borderColorFromPlatform, StyledCreatorsSuggestedInput } from "./styles"
import useOutsideClick from "../../../../hooks/useOutsideClick"
import useTranslation from "../../../../localization/useTranslation"

export const SuggestedCreatorsInput = (props) => {
  const {
    creatorsData = [],
    notFoundData = false,
    debouncedInputValue = '',
    setInputValue,
    setCreatorsData,
    setLoadingCreators,
    setNotFoundData,
    inputValue = '',
    currentPlatform = '',
    loadingCreators = false,
    error = false,
    disabled = false,
  } = props || {}

  const { labelStrings } = useTranslation()

  const inputRef = useRef(null)
  const containerRef = useRef(null)

  const [showListCreators, setShowListCreators] = useState(true)

  useOutsideClick({
    ref: containerRef,
    callback: () => setShowListCreators(false),
  })

  const toInputFocus = () => {
    inputRef.current.focus()
  }

  const onFocusInput = () => {
    if (!showListCreators) {
      setShowListCreators(true)
    }
  }

  const onChangeInputValue = (e) => {
    const value = e.target.value
    setInputValue(value)
  }

  const onClearInput = () => {
    setInputValue("")
    setCreatorsData([])
    setLoadingCreators(false)
    setNotFoundData(false)
  }

  return (
    <Stack ref={containerRef} sx={{ width: "fit-content" }}>
      <StyledCreatorsSuggestedInput
        disabled={disabled}
        inputRef={inputRef}
        label=''
        size='small'
        variant='outlined'
        value={inputValue}
        onChange={onChangeInputValue}
        placeholder={labelStrings.analyzeYourInfluencer}
        onFocus={onFocusInput}
        autoComplete='off'
        InputProps={{
          autoComplete: "off",
          startAdornment: (
            <InputAdornment position='start'>
              <SocialPlatformSwitcher currentPlatform={currentPlatform} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {inputValue ? (
                <IconButton
                  disabled={disabled}
                  sx={{ width: "32px", height: "32px", color: "#6B4545" }}
                  onClick={onClearInput}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton
                  disabled={disabled}
                  sx={{ width: "32px", height: "32px", color: "#6B4545" }}
                  onClick={toInputFocus}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          '&:hover': {
            '& .MuiInputAdornment-positionEnd': {
              '& .MuiButtonBase-root': {
                '& .MuiSvgIcon-root': {
                  fill: borderColorFromPlatform[currentPlatform]
                }
              }
            }
          },
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderWidth: "1px",
              borderColor: '#E3C5C5',
            },
            "&:hover fieldset": {
              borderWidth: "2px",
              borderColor: borderColorFromPlatform[currentPlatform],
            },
            "&.Mui-focused fieldset": {
              borderWidth: "2px",
              borderColor: borderColorFromPlatform[currentPlatform],
            },
          },
        }}
      />
      <Box sx={{ position: "relative" }}>
        <CreatorsList
          creators={creatorsData}
          notFoundData={notFoundData}
          loadingCreators={loadingCreators}
          currentPlatform={currentPlatform}
          creatorsName={debouncedInputValue}
          showList={showListCreators}
          error={error}
        />
      </Box>
    </Stack>
  )
}

SuggestedCreatorsInput.propTypes = {
  creatorsData: PropTypes.array,
  notFoundData: PropTypes.bool,
  debouncedInputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  setCreatorsData: PropTypes.func,
  setLoadingCreators: PropTypes.func,
  setNotFoundData: PropTypes.func,
  inputValue: PropTypes.string,
  currentPlatform: PropTypes.string,
  loadingCreators: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}
