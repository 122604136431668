export const MSAL_CONFIG = {
  auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
      redirectUri: window.location.origin
  },
  cache: {
      cacheLocation: "sessionStorage", // You can use 'sessionStorage' as well
      storeAuthStateInCookie: false // Set to true if you have issues with IE11 or Edge
  }
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'email']
};

export const LOGIN_REQUEST = {
  scopes: ["openid", "offline_access"]
};

export const TOKEN_REQUEST = {
  scopes: ["User.ReadWrite.All"]
};

export const GRAPH_CONFIG = {
  graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users"
};

// export const PUBLIC_CLIENT_APPLICATION = new PublicClientApplication(MSAL_CONFIG);
// export async function initializeMsal() {
//   await PUBLIC_CLIENT_APPLICATION.initialize();
// }
