import React from "react";
import { Box, Stack, Typography, Modal, Fade, Backdrop } from "@mui/material";
import useTranslation from "../../../localization/useTranslation";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "460px",
  maxWidth: "460px",
  userSelect: "none",
};

const textStyles = {
  fontFamily: "Inter",
  color: "#000",
};

const gradientBackground =
  "linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)";

export const PlanInactiveModal = () => {
  const { labelStrings, currentLang } = useTranslation()
  return (
    <Modal
      open={true}
      onClose={() => {}}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        height: "calc(100vh - 64px)",
        top: "unset",
        zIndex: "999 !important",
        "& > .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 241, 241, 0.94)",
          height: "calc(100vh - 64px)",
          top: "unset",
        },
      }}
    >
      <Fade in={true}>
        <Box sx={modalStyle}>
          <Stack
            sx={{ position: "absolute", top: "-130px", alignItems: "center" }}
            spacing={2}
          >
            <Typography
              sx={{
                fontSize: currentLang === "de" ? "23px" : "30px",
                fontWeight: 700,
                background: gradientBackground,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {labelStrings.notActivatedAccountTipTitle}
            </Typography>
            <Typography
              textAlign='center'
              sx={{
                ...textStyles,
                maxWidth: currentLang === "de" ? "400px" : "350px",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: '16px'
              }}
            >
              {labelStrings.notActivatedAccountTipText}
            </Typography>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};