import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
// import { USER_INDEXES } from "../constants/appSettings"
import { themeCommonStyles } from "../themes/infludataThemes"
import { generateRandomId } from "../utils"

const STROKE_DASHOFFSET = 258

const calcStrokeDashoffset = (ratingValue) => {
  return STROKE_DASHOFFSET - STROKE_DASHOFFSET * (ratingValue / 10) // example: 269 - (269 * 0.95) = 14 // this is the ammount in to stop stroke
}

const createKeyframe = (dasharray, dashOffset) => `
  @keyframes dash {
    0% {
      stroke-dashoffset: ${dasharray};
    }
    100% {
      stroke-dashoffset: ${dashOffset};
    }
  }
`

const RatingMeterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  ${(props) => props.keyframe}
`

const Svg = styled.svg`
  ${(props) => props.keyframe}
`

const RatingMeter = ({
  value = 0,
  userindex = "instagram",
  animated = true,
  size = 70,
  withGradient = false,
  unfilledAreaBgColor = "",
}) => {
  const sizeOffset = size * 0.225
  const ratingCircleSize = size + sizeOffset
  const dashOffset = calcStrokeDashoffset(value)
  const gradientId = `ratingmeter-gradient-${generateRandomId()}`

  const mainGradientColors = themeCommonStyles.getMainGradientColorsForPlatform(userindex)
  const defaultUnfilledAreaBgColor = '#F4E8E8' //themeCommonStyles.colors.lightBrown
  // if (userindex === USER_INDEXES.tiktok) {
  //   defaultUnfilledAreaBgColor = themeCommonStyles.colors.aquaUltraLight
  // }

  return (
    <RatingMeterWrapper>
      <Svg
        className={size > 200 ? "rating_meter-lg" : "rating_meter"}
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'
        width={ratingCircleSize}
        height={ratingCircleSize}
        keyframe={createKeyframe(STROKE_DASHOFFSET, dashOffset)}
      >
        {withGradient && (
          <defs>
            <linearGradient
              id={gradientId}
              fx='50%'
              fy='50%'
              cx='50%'
              cy='50%'
              r='75%'
            >
              <stop
                offset='0%'
                stopColor={mainGradientColors[1]}
                stopOpacity='1'
              />
              <stop
                offset='100%'
                stopColor={mainGradientColors[0]}
                stopOpacity='1'
              />
            </linearGradient>
          </defs>
        )}
        <circle
          cx={50}
          cy={50}
          r={41}
          fill='none'
          strokeWidth='8'
          stroke={unfilledAreaBgColor || defaultUnfilledAreaBgColor}
        />
        <circle
          cx={50}
          cy={50}
          r={41}
          fill={"none"}
          strokeWidth='8'
          strokeDasharray={STROKE_DASHOFFSET}
          strokeDashoffset={dashOffset}
          style={{
            ...(animated && {
              animationName: "dash",
              animationDuration: "0.25s",
              animationDelay: "0s",
            }),
          }}
          stroke={
            withGradient
              ? `url(#${gradientId})`
              : themeCommonStyles.pickColorDependOnPlatform(userindex).primary
          }
        />
      </Svg>
    </RatingMeterWrapper>
  )
}

RatingMeter.propTypes = {
  value: PropTypes.number,
  userindex: PropTypes.string,
  animated: PropTypes.bool,
  size: PropTypes.number,
  withGradient: PropTypes.bool,
  unfilledAreaBgColor: PropTypes.string,
}

export default RatingMeter
