import React from "react"
import { useSelector } from "react-redux"
import { selectedPlatformSelector } from "../../redux/selectors"
import SuggestedLocationsContainer from "./SuggestedLocationsContainer"
import useTranslation from "../../localization/useTranslation"

const GoogleLocationSearchContainer = ({ changeLocation }) => {
  const { labelStrings } = useTranslation()
  const currentPlatform = useSelector(selectedPlatformSelector)

  const onLocationSelect = (locationId) => {
    changeLocation(locationId)
  }

  return (
    <SuggestedLocationsContainer
      selectLocationNextAction={onLocationSelect}
      userindex={currentPlatform}
      id='googleLocationSearch'
      placeholderText={labelStrings.selectLocation}
    />
  )
}

export default GoogleLocationSearchContainer
