import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Stack, Typography } from "@mui/material"
import { ArrowDown, ArrowMiddle, ArrowUp } from "../icons"

const DynamicArrowWrapper = styled.span`
  font-size: 16px;
  top: -2px;
  position: relative;
  ${(props) =>
    props.isAroundZero &&
    `
    transform: rotateZ(45deg);
  `}
`

export const SearchUserGrowthDynamics = ({ growthData }) => {
  const [count, percentValue] = growthData
  const percent = percentValue ? Number(percentValue.toFixed(1)) : 0
  const renderGrowth = count !== undefined && percent !== undefined

  const isHorizontalArrow = percent === 0 || (percent >= -1 && percent <= 1)
  const isArrowUp = percent >= 1.1
  const isArrowDeclining = percent <= -1.1

  const createArrow = () => {
    if (isHorizontalArrow) {
      return <ArrowMiddle />
    } else if (isArrowDeclining) {
      return <ArrowDown />
    } else if (isArrowUp) {
      return <ArrowUp />
    }
  }

  return (
    renderGrowth && (
      <Stack alignItems='center'>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          {<DynamicArrowWrapper>{createArrow()}</DynamicArrowWrapper>}
          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Inter",
              color: "#6B4545",
              fontWeight: 400,
            }}
          >
            {percent === 0
              ? `${percent}.0%`
              : percent > 0
                ? `${percent}%`
                : `${percent}%`}
          </Typography>
        </Stack>
      </Stack>
    )
  )
}

SearchUserGrowthDynamics.propTypes = {
  growthData: PropTypes.array,
}
