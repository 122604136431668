import styled from 'styled-components'
import { searchContentTypes } from '../../constants/search'

// CUSTOM STYLES FOR SEARCH PAGINATION
export const MainWrapper = styled.div`
  width: 100%;
  ${'' /* padding: 0 15px; */}
  height: 50px;
  min-height: auto;
  ${props => props.theme.flex.centerAll}
  ${props => !props.isBottom && 'justify-content: space-between'};
  position: relative;
  ${props =>
    props.tipCords.left &&
    props.tipCords.top &&
    `
    & .collection-sidebar-menu-content {
      position: fixed !important;
      top: ${props.tipCords.top}px !important;
      left: ${props.tipCords.left}px !important;
    }
  `}
  ${props => props.isContentSearch && !props.isBottom && `
    margin-top: 0;
  `}
  ${props => props.isShowMap && `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    margin: 0;
  `}
`

export const PaginationContainerWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  height: 50px;

  & > * {
    font-size: 13px;
    font-family: 'Inter';
  }

  .pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    ${props => props.theme.flex.centerAll};
  }

  .page_num,
  .nav_btn,
  .active_link,
  .page_num:active,
  .page_num:focus,
  .nav_btn:active,
  .nav_btn:focus,
  .active_link:active,
  .active_link:focus {
    outline: none !important;
  }

  .page_num {
    cursor: pointer;
    outline: none;
    padding: 0 5px;
    text-decoration: none;
    color: ${props => props.theme.colors.mauve};

    & * {
      outline: none;
      color: ${props => props.theme.colors.mauve};
    }

    & *:focus {
      outline: none;
      color: ${props => props.theme.colors.mauve};
    }

    ${props =>
      props.isMaxPagesLimitExceded &&
      `
      &:nth-child(1n + ${props.nextPage}) {
        ${props.theme.disableBlock};
        pointer-events: none;
      }
    `}
  }

  .current_page {
    color: ${props => props.theme.platformColor}
    pointer-events: none;
    text-decoration: none;
    font-weight: 700;

    & * {
      color: ${props => props.theme.platformColor} !important;
    }
  }

  .nav_btn {
    margin: 0 20px;
    cursor: pointer;
  }

  .disabled_btn {
    color: ${props => props.theme.colors.lightBrown};
    pointer-events: none;

    & * {
      color: ${props => props.theme.colors.lightBrown} !important;
    }
  }

  .break-me {
    pointer-events: none;
  }

  .prev,
  .next {
    color: ${props => props.theme.colors.mauve};

    & * {
      outline: none;
      color: ${props => props.theme.colors.mauve};
    }

    & *:focus {
      outline: none;
      color: ${props => props.theme.colors.mauve};
    }
  }

  .next {
    ${props =>
      props.isMaxPagesLimitExceded &&
      `
      ${props.theme.disableBlock};
      pointer-events: none;
    `}
  }
`
export const ShowMapWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 6px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: ${props => props.theme.colors.salmon};
    font-family: 'Inter';
  }
`

export const MapTitle = styled.span`
  text-transform: uppercase;
`