export const defaultRangeValues = {
  min: 0,
  max: 290,
}

export const getValuesFromQuery = {
  ["followers"]: ["followerMin", "followerMax"],
  ["views"]: ["viewsMin", "viewsMax"],
  ["plays"]: ["playsMin", "playsMax"],
  ["playsPerReel"]: ["reelPlaysMin", "reelPlaysMax"],
  ["videos"]: ["viewsMin", "viewsMax"],
  ["shorts"]: ["shortsPlaysMin", "shortsPlaysMax"],
  ["reach"]: ["viewsMin", "viewsMax"]
}

export const radiusSelectOptions = [
  { value: "", label: "Off" },
  { value: "0.1", label: "100m / 320ft" },
  { value: "0.2", label: "200m / 650ft" },
  { value: "0.5", label: "500m / 0.3mi" },
  { value: "1", label: "1km / 0.6mi" },
  { value: "10", label: "10km / 6mi" },
  { value: "50", label: "50km / 30mi" },
  { value: "100", label: "100km / 60mi" },
]

export const extractValues = (query, getValuesFromQuery) => {
  const result = {}

  for (const [key, fields] of Object.entries(getValuesFromQuery)) {
    const values = fields
      .map((field) => query[field])
      .filter((value) => value !== null && value !== undefined)

    if (values.length > 0) {
      result[key] = values
    }
  }

  return result
}

export const hasMatchingValues = (dataObject, dataArray) => {
  for (let key in dataObject) {
    const values = dataObject[key]
    for (let value of values) {
      if (dataArray.includes(value)) {
        return true
      }
    }
  }
  return false
}

export const filterTruthyKeys = (obj) => {
  return Object.keys(obj)?.filter((key) => {
    const value = obj[key]

    const isMinOrMax = key.endsWith("Min") || key.endsWith("Max")
    const isExcludedValue = value === 2000 || value === Infinity

    return (
      value !== null &&
      value !== undefined &&
      value !== false &&
      value !== "" &&
      value !== 0 &&
      !(isMinOrMax && isExcludedValue)
    )
  })
}

export const getUniqueId = () => Math.random()

export const marksAmount = [
  {
    value: 0,
    scaledValue: 2000,
    label: "2K",
  },
  {
    value: 25,
    scaledValue: 5000,
    label: "5K",
  },
  {
    value: 50,
    scaledValue: 10000,
    label: "10K",
  },
  {
    value: 75,
    scaledValue: 15000,
    label: "15K",
  },
  {
    value: 100,
    scaledValue: 25000,
    label: "25K",
  },
  {
    value: 125,
    scaledValue: 50000,
    label: "50K",
  },
  {
    value: 150,
    scaledValue: 150000,
    label: "150K",
  },
  {
    value: 175,
    scaledValue: 500000,
    label: "500K",
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: "1M",
  },
  {
    value: 225,
    scaledValue: 3000000,
    label: "3M",
  },
  {
    value: 250,
    scaledValue: 5000000,
    label: "5M",
  },
  {
    value: 275,
    scaledValue: 8000000,
    label: "8M",
  },
  {
    value: 290,
    scaredValue: defaultRangeValues.max,
    label: "∞",
  },
]

export const scale = (value) => {
  if (value === undefined) {
    return undefined
  }
  const previousMarkIndex = Math.floor(value / 25)
  const previousMark = marksAmount[previousMarkIndex]
  const remainder = value % 25
  if (remainder === 0) {
    return previousMark?.scaledValue
  }
  const nextMark = marksAmount[previousMarkIndex + 1]
  const increment = (nextMark?.scaledValue - previousMark?.scaledValue) / 25
  return remainder * increment + previousMark?.scaledValue
}

export const unscale = (scaledValue) => {
  if (!scaledValue) {
    return undefined
  }

  const previousMarkIndex = marksAmount.findIndex((mark, index) => {
    return (
      mark.scaledValue <= scaledValue &&
      (marksAmount[index + 1]?.scaledValue > scaledValue ||
        index === marksAmount.length - 1)
    )
  })

  const previousMark = marksAmount[previousMarkIndex]
  if (scaledValue === previousMark?.scaledValue) {
    return previousMark?.value
  }
  const nextMark = marksAmount[previousMarkIndex + 1]
  const increment = (nextMark?.scaledValue - previousMark?.scaledValue) / 25
  const remainder = scaledValue - previousMark?.scaledValue
  return previousMark?.value + remainder / increment
}

export const getActiveSearchParams = (obj1, obj2) => {
  const result = {}

  for (const [key, values] of Object.entries(obj1)) {
    values.forEach((value) => {
      if (value.endsWith("Min") || value.endsWith("Max")) {
        const obj2Value = obj2[value]
        if (
          typeof obj2Value === "number" &&
          obj2Value > 2000 &&
          obj2Value !== Infinity
        ) {
          result[value] = obj2Value
        }
      }
    })
  }

  return result
}

export const toBoolean = value => 
  typeof value === 'boolean' ? value : value === 'true' ? true : value === 'false' ? false : null
