import { SOCIAL_PLATFORMS_NAMES } from '../constants/appSettings'

export const areSearchQueriesEqual = (targetObj, newObj) => {
  if (Object.keys(targetObj).length !== Object.keys(newObj).length) return false

  for (const key in targetObj) {
    const targetValue = targetObj[key]
    const newValue = newObj[key]

    if ((targetValue === null || targetValue === "") && (newValue === null || newValue === "")) {
      continue
    }

    if (targetValue !== newValue) return false
  }

  return true
}

export const areSearchQueriesEqualFroSuggestions = (targetObj, newObj) => {
  if (Object.keys(targetObj).length !== Object.keys(newObj).length) return false

  for (const key in targetObj) {
    const targetValue = targetObj[key]
    const newValue = newObj[key]

    if (key === 'index') {
      continue
    }

    if ((targetValue === null || targetValue === "") && (newValue === null || newValue === "")) {
      continue
    }

    if (targetValue !== newValue) return false
  }

  return true
}

// filter search params function, can be updated and reused, filters can be applied depending on platform, or general, like skipCount
export const filterQueryParams = (
  query,
  newParamsObj = {}, // params can come by one, there is no situation when few diff params exist, exept followers, plays and views - they come in pairs(min, max)
  currentPatform = SOCIAL_PLATFORMS_NAMES.instagram.name
) => {
  const queryToFilter = { ...query }

  // reset skipCount if we don't paginate. note that skipCount can be zero
  if (newParamsObj.skipCount === undefined) queryToFilter.skipCount = 0

  // set sorting to default, except pagination case. We keep sorting when skipCount is greater than 0
  if (!newParamsObj.skipCount) {
    queryToFilter.sorting = ''
  }

  if (currentPatform === SOCIAL_PLATFORMS_NAMES.tiktok.name) {
    if (newParamsObj.playsMin || newParamsObj.playsMax) {
      const { followerMax, followerMin, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
    if (newParamsObj.followerMin || newParamsObj.followerMax) {
      const { playsMin, playsMax, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
  }
  if (currentPatform === SOCIAL_PLATFORMS_NAMES.instagram.name && queryToFilter.contentTypes === '') {
    if (newParamsObj.viewsMin || newParamsObj.viewsMax) {
      const { followerMin, followerMax, reelPlaysMin, reelPlaysMax, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
    if (newParamsObj.followerMin || newParamsObj.followerMax) {
      const { viewsMin, viewsMax, reelPlaysMin, reelPlaysMax, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
    if (newParamsObj.reelPlaysMin || newParamsObj.reelPlaysMax) {
      const { followerMin, followerMax, viewsMin, viewsMax, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
  }
  if (currentPatform === SOCIAL_PLATFORMS_NAMES.youtube.name) {
    if (newParamsObj.viewsMin || newParamsObj.viewsMax) {
      const { followerMin, followerMax, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
    if (newParamsObj.followerMin || newParamsObj.followerMax) {
      const { viewsMin, viewsMax, ...restOfQuery } = queryToFilter
      return { ...restOfQuery, ...newParamsObj }
    }
  }

  // filter businessSearch if not set to true
  // if ('businessSearch' in newParamsObj) {
  //   if (newParamsObj.businessSearch) {
  //     return { ...queryToFilter, businessSearch: true }
  //   } else {
  //     if ('businessSearch' in queryToFilter) {
  //       const { businessSearch, ...restOfQuery } = queryToFilter
  //       return { ...restOfQuery }
  //     }
  //   }
  // }
  // change 0 growthRate to null to avoid putting it into url string
  if (newParamsObj.growthRate === 0) {
    return { ...queryToFilter, growthRate: null }
  }

  // handle audience sub-search params
  if (newParamsObj.audience) {
    const returnQuery = { ...queryToFilter, ...newParamsObj.audience }
    if (!returnQuery.audienceRealPeople) returnQuery.audienceRealPeople = null
    return returnQuery
  }
  // default return merged
  return { ...queryToFilter, ...newParamsObj }
}

export const transformValuesToNumberOrBoolean = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (typeof value === "string") {
        if (!isNaN(value) && value.trim() !== "") {
          return [key, Number(value)]
        }
        if (value === "true") {
          return [key, true]
        } else if (value === "false") {
          return [key, false]
        }
      }
      if (typeof value === "boolean") {
        return [key, value]
      }
      return [key, value]
    }),
  )
}
