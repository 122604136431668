import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Box, IconButton, InputAdornment, Stack } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import { CreatorsList } from "./CreatorsList"
import { StyledCreatorsSuggestedInput } from "./styles"
import useOutsideClick from "../../../../../hooks/useOutsideClick"

export const SuggestedCreatorsInput = (props) => {
  const {
    creatorsData = [],
    notFoundData = false,
    debouncedInputValue = "",
    setInputValue,
    setCreatorsData,
    setLoadingCreators,
    setNotFoundData,
    inputValue = "",
    currentPlatform = "",
    loadingCreators = false,
    error = false,
    isAllowedToAddCreators,
    creatorIdFromState = "",
    setCreatorIdFromState,
    isSetCreatorId = true,
    removeBrandMentions,
    brandMentionsValuesToArray,

    showListCreators,
    setShowListCreators,
  } = props || {}

  const inputRef = useRef(null)
  const containerRef = useRef(null)

  useOutsideClick({
    ref: containerRef,
    callback: () => setShowListCreators && setShowListCreators(false),
  })

  const toInputFocus = () => {
    inputRef.current.focus()
  }

  const onFocusInput = () => {
    if (!showListCreators) {
      setShowListCreators(true)
    }
  }

  const onChangeInputValue = (e) => {
    const value = e.target.value
    setInputValue(value)
  }

  const onClearInput = () => {
    setInputValue("")
    setCreatorsData([])
    setLoadingCreators(false)
    setNotFoundData(false)
  }

  const isShowCreatorsList = creatorsData?.length && showListCreators

  return (
    <Stack ref={containerRef} sx={{ width: "100%", height: "auto" }}>
      <StyledCreatorsSuggestedInput
        autoFocus
        size='small'
        inputRef={inputRef}
        // label={'Type your brand mention'}
        variant='outlined'
        value={inputValue}
        onChange={onChangeInputValue}
        placeholder={`Type your mentions`}
        onFocus={onFocusInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {inputValue ? (
                <IconButton onClick={onClearInput}>
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton onClick={toInputFocus}>
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          position: "relative",
          height: !!isShowCreatorsList ? "300px" : 0,
        }}
      >
        {!!isShowCreatorsList && (
          <CreatorsList
            creators={creatorsData}
            notFoundData={notFoundData}
            loadingCreators={loadingCreators}
            currentPlatform={currentPlatform}
            creatorsName={debouncedInputValue}
            showList={showListCreators}
            error={error}
            isAllowedToAddCreators={isAllowedToAddCreators}
            creatorIdFromState={creatorIdFromState}
            setCreatorIdFromState={setCreatorIdFromState}
            onClearInput={onClearInput}
            isSetCreatorId={isSetCreatorId}
            removeBrandMentions={removeBrandMentions}
            brandMentionsValuesToArray={brandMentionsValuesToArray}
          />
        )}
      </Box>
    </Stack>
  )
}

SuggestedCreatorsInput.propTypes = {
  creatorsData: PropTypes.array,
  notFoundData: PropTypes.bool,
  debouncedInputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  setCreatorsData: PropTypes.func,
  setLoadingCreators: PropTypes.func,
  setNotFoundData: PropTypes.func,
  inputValue: PropTypes.string,
  currentPlatform: PropTypes.string,
  loadingCreators: PropTypes.bool,
  error: PropTypes.bool,
  isAllowedToAddCreators: PropTypes.bool,
  creatorIdFromState: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setCreatorIdFromState: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
  removeBrandMentions: PropTypes.func,
  brandMentionsValuesToArray: PropTypes.array,
  showListCreators: PropTypes.bool,
  setShowListCreators: PropTypes.func,
}
