import React, { useEffect, useState, useMemo, useCallback } from "react"
import { styled, Button, Stack } from "@mui/material"
import { bgGradients } from "../../nav-section/CreatorsSuggestions/styles"
import { USER_INDEXES } from "../../../../constants/appSettings"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { currentSearchQuerySelector } from "../../../../redux/selectors"
import { updateSearch } from "../../../../redux/ducks/searchDucks"

const SocialPlatformBtn = styled(Button)(({ isSelected, ownIndex }) => ({
  fontSize: "12px",
  minWidth: "60px !important",
  height: '30px',
  color: isSelected ? "#FFFFFF" : "#442424",
  svg: {
    path: {
      fill: isSelected ? "#FFFFFF" : "#442424",
    },
  },
  background: isSelected ? bgGradients[ownIndex?.toUpperCase()] : "#F6DBDB",
  "&:hover": {
    background: bgGradients[ownIndex?.toUpperCase()],
    svg: {
      fill: "#FFFFFF",
      filter: "brightness(95%)",
      path: {
        fill: "#FFFFFF",
      },
    },
  },
  width: "100%",
}))

export const ContentTypeBtn = ({ userindex }) => {
  const dispatch = useDispatch()
  const { contentTypes } = useSelector(currentSearchQuerySelector, shallowEqual)

  const initialSelectedTypes = useMemo(
    () =>
      userindex === USER_INDEXES.instagram
        ? { posts: true, stories: true, reels: true }
        : { short: true, video: true },
    [userindex],
  )

  const [selectedTypes, setSelectedTypes] = useState(initialSelectedTypes)

  const onChange = useCallback(
    (type) => {
      const activeButtons = Object.values(selectedTypes).filter(Boolean).length
      if (activeButtons === 1 && selectedTypes[type]) {
        return
      }

      const newSelectedTypes = {
        ...selectedTypes,
        [type]: !selectedTypes[type],
      }

      setSelectedTypes(newSelectedTypes)
      const payloadTypes = Object.keys(newSelectedTypes)
        .filter((k) => newSelectedTypes[k])
        .join()
      dispatch(updateSearch({ newValuesObj: { contentTypes: payloadTypes, isSimilarContentPieces: false } }))
    },
    [selectedTypes, dispatch],
  )

  useEffect(() => {
    if (contentTypes) {
      const valueFromQuery =
        userindex === USER_INDEXES.instagram
          ? {
              posts: contentTypes?.includes("posts"),
              stories: contentTypes?.includes("stories"),
              reels: contentTypes?.includes("reels"),
            }
          : {
              video: contentTypes?.includes("video"),
              short: contentTypes?.includes("short"),
            }
      setSelectedTypes(valueFromQuery)
    } else {
      setSelectedTypes(initialSelectedTypes)
    }
  }, [userindex, contentTypes, initialSelectedTypes])

  const buttons = useMemo(
    () =>
      userindex === USER_INDEXES.instagram
        ? [
            { type: "posts", label: "Post" },
            { type: "stories", label: "Story" },
            { type: "reels", label: "Reels" },
          ]
        : [
            { type: "video", label: "Videos" },
            { type: "short", label: "Shorts" },
          ],
    [userindex],
  )

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {buttons.map(({ type, label }) => (
        <SocialPlatformBtn
          sx={{
            maxWidth: userindex === USER_INDEXES.instagram ? "49px" : "100%",
          }}
          key={type}
          onClick={() => onChange(type)}
          isSelected={selectedTypes[type]}
          ownIndex={userindex}
        >
          {label}
        </SocialPlatformBtn>
      ))}
    </Stack>
  )
}
