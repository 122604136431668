import React, { useState } from 'react'
import styled from 'styled-components'
import WithTip from '../../../../../components/common/popups/WithTip'
import { AddToCampaignIcon } from '../../../../../components/common/icons'
import WithUpgradeTip from '../../../../../components/common/popups/WithUpgradeTip'
import EditCampaignModal from '../../../../../components/campaigns/campaignTools/EditCampaignModal'
import useTranslation from '../../../../../localization/useTranslation'
import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../../../constants/appSettings'


const IconWrapper = styled.span`
  position: relative;
  ${props =>
    props.count &&
    `
    &::before {
      content: '${props.count}';
      position: absolute;
      top: -4px;
      right: 0;
      font-size: 10px;
      line-height: 1;
      font-weight: 400;
      height: 14px;
      width: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${props.theme.getColorPaletteForPlatform(props.userindex).main};
      color: ${props.theme.color.white};
      border-radius: 50%;
    }
  `}

  ${props => !props.enableCampaigns && props.theme.disableBlock};
`

const ProfileCampaignTool = ({
  user,
  enableCampaigns = false,
  isUserAuthenticated = false,
  setIsOpenPipelineModal,
  isQuickPreview
}) => {
  const { labelStrings } = useTranslation()

  const [isCreateCampaignOpened, setIsCreateCampaignOpened] = useState(false)

  const isUserFromTikTok = user._index === USER_INDEXES.tiktok
  const userInCampaignsCount = user.campaignArray ? user.campaignArray.length : 0

  const AddIcon = (
    <IconWrapper
      enableCampaigns={enableCampaigns && !isUserFromTikTok}
      count={userInCampaignsCount}
      userindex={user._index}
    >
      <AddToCampaignIcon userindex={user._index} isActiveIcon={false} isGrey={isUserFromTikTok} />
    </IconWrapper>
  )

  const renderVisibleComponent = () => (
    <WithTip
      tipText={
        isUserFromTikTok
          ? labelStrings.notAvaliableCampaignTracking(SOCIAL_PLATFORMS_NAMES.tiktok.prettyName)
          : labelStrings.addToCampaignPipeline
      }
      userindex={user._index}
      position={isQuickPreview ? 'bottom center' : 'top center'}
      arrow={isQuickPreview ? false : true}
    >
      {AddIcon}
    </WithTip>
  )

  return (
    <>
      {enableCampaigns ? (
        <div
          onClick={(e) => {
            if (!isUserFromTikTok) {
              setIsOpenPipelineModal(e, true)
            }
          }}
        >
          {renderVisibleComponent()}
        </div>
      ) : (
        <WithUpgradeTip
          tipText={labelStrings.trackInfluencers}
          userindex={user._index}
          withSignup={!isUserAuthenticated}
          position={isQuickPreview ? 'bottom center' : 'top center'}
          arrow={isQuickPreview ? false : true}
        >
          {AddIcon}
        </WithUpgradeTip>
      )}

      {isCreateCampaignOpened && (
        <EditCampaignModal
          platform={user._index}
          isNewCampaign
          closeModal={() => setIsCreateCampaignOpened(false)}
          withoutPortal
        />
      )}
    </>
  )
}

export default ProfileCampaignTool
