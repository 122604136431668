import React, { useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
// import { UNLOCKED_COLLECTION_NAME } from '../../../../constants/collections'
import CollectionItem from './CollectionItem'
import CollectionItemDroppableIcon from './CollectionItemDroppableIcon'
import { CollectionListItem, SixDotsDnD } from '../collectionsSidebarStyles'
import { SixDotsDnDIcon } from '../../../../../components/common/icons'

const CollectionsBarItem = ({
  collection,
  areFavoritesAllowed,
  canDragToCollection,
  isHoveredForDrop,
  registerDropableHandlers,
  isDropOperationInProgress,
  isDropOperationSuccess,
  enableItemClick,
  isNavType,
  onHandleOpenViewType,
}) => {
  const { collectionId: openedCollectionId } = useParams()
  const { collectionId } = collection
  // const isUnlockedCollection = collectionId === UNLOCKED_COLLECTION_NAME

  const droppableHandlers = useMemo(
    () => (collection ? registerDropableHandlers(collectionId) : {}),
    []
  )

  return (
    <CollectionListItem
      // isHoveredOnDrop={isHoveredForDrop}
      id={collectionId}
      // isUnlockedCollection={isUnlockedCollection}
      to={`/collections/${collectionId}`}
      active={`${openedCollectionId === collectionId}`}
      nav_type={isNavType}
      onClick={() => {
        if (collectionId === 'unlocked') {
          onHandleOpenViewType()
        }
      }}
      {...droppableHandlers}
    >
      {isNavType !== 'min' && (
        <SixDotsDnD className="sixDots">
          <SixDotsDnDIcon />
        </SixDotsDnD>
      )}

      <CollectionItem
        collection={collection}
        areFavoritesAllowed={areFavoritesAllowed}
        enableItemClick={enableItemClick}
        isNavType={isNavType}
        onHandleOpenViewType={onHandleOpenViewType}
      />

      {isHoveredForDrop && isNavType !== 'min' && (
        <CollectionItemDroppableIcon
          canDragToCollection={canDragToCollection}
          isDropOperationInProgress={isDropOperationInProgress}
          isDropOperationSuccess={isDropOperationSuccess}
        />
      )}
    </CollectionListItem>
  )
}

CollectionsBarItem.propTypes = {
  collection: PropTypes.object.isRequired,
  isExportAllowed: PropTypes.bool,
  canDragToCollection: PropTypes.shape({
    forbidden: PropTypes.bool,
    reason: PropTypes.string,
  }),
  isHoveredForDrop: PropTypes.bool,
  registerDropableHandlers: PropTypes.func,
  isDropOperationInProgress: PropTypes.bool,
  isDropOperationSuccess: PropTypes.bool,
  enableItemClick: PropTypes.bool,
  isNavType: PropTypes.string,
  onHandleOpenViewType: PropTypes.func,
}

CollectionsBarItem.defaultProps = {
  isExportAllowed: true,
  canDragToCollection: { forbidden: true, reason: '' },
  isHoveredForDrop: false,
  registerDropableHandlers: () => ({}),
  isDropOperationInProgress: false,
  isDropOperationSuccess: false,
  enableItemClick: false,
  isNavType: 'default',
  onHandleOpenViewType: () => {},
}

export default memo(CollectionsBarItem)
