import React, { useEffect, useRef } from "react"
import {
  Stack,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Chip
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import useTranslation from "../../../../localization/useTranslation"
import { useDispatch, useSelector } from "react-redux"
import {
  // searchSuggestionsSelector,
  selectedPlatformSelector,
} from "../../../../redux/selectors"
import { SOCIAL_PLATFORMS_NAMES } from "../../../../constants/appSettings"
import {
  changeSuggestionsData,
  getSearchBySmartText,
  resetSearchResults,
} from "../../../../redux/ducks/searchDucks"
import { cancelAllFetches } from "../../../components/search-sidebar/searchSidebar/searchbarTipsFetches"
import { searchContentTypes } from "../../../../constants/search"
import urlQueryService from "../../../../services/urlQueryService"
import { defaultSearchQuery } from "../utils"

const gradientColors = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: {
    start: "#FF6B00",
    end: "#FF3B99",
    static: "#FF5500",
  },
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: {
    start: "#00F2EA",
    end: "#02BDC9",
    static: "#4ad1db",
  },
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: {
    start: "#EA3323",
    end: "#bd1a2e",
    static: "#EA3323",
  },
}

export const SearchSmartBar = ({
  inputValue,
  setInputValue,
  onGetSearchBySmartText,
  searchType,
  dataSuggestions
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const platform = useSelector(selectedPlatformSelector)
  // const { isLoading } = useSelector(searchSuggestionsSelector, shallowEqual)
  const inputRef = useRef()

  const toInputFocus = () => {
    inputRef?.current?.focus(), onGetSearchBySmartText()
  }

  const onChangeInputValue = (e) => {
    const value = e.target.value
    setInputValue(value)
  }

  const getInitialSearchBySmartText = () => {
    if (searchType !== searchContentTypes.CREATOR) return
    dispatch([
      getSearchBySmartText({
        inputParam: null,
        platform: null,
        method: "init",
        withoutSearchLoading: true,
      }),
      changeSuggestionsData({}),
    ])
  }

  const clearSearch = () => {
    setInputValue("")
    cancelAllFetches()
    dispatch([resetSearchResults(null, { searchType }), changeSuggestionsData({})])
    localStorage?.removeItem("searchQuery")
    getInitialSearchBySmartText()
    urlQueryService.setNewSearchQuery({
      latestQuery: defaultSearchQuery,
    })
  }

  useEffect(() => {
    if(!dataSuggestions?.length) {
      getInitialSearchBySmartText()
    }
  }, [])

  const placeholderLabels = {
    [searchContentTypes.CREATOR]: labelStrings.searchSuggestions.suggestionsPlaceholder,
    [searchContentTypes.BRAND]: labelStrings.searchSuggestions.suggestionsBrandPlaceholder,
    [searchContentTypes.CONTENT]: labelStrings.searchSuggestions.suggestionsContentPlaceholder
  }

  return (
    <Stack direction='row' alignItems='center' spacing={1} sx={{ pt: '10px' }}>
      <Box sx={{ maxWidth: "809px", width: "100%", position: 'relative' }}>
        <TextField
          disabled={false}
          inputRef={inputRef}
          size='small'
          variant='outlined'
          value={inputValue}
          onChange={onChangeInputValue}
          placeholder={placeholderLabels[searchType]}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onGetSearchBySmartText()
            }
          }}
          autoComplete='off'
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  disabled={false}
                  sx={{ width: "32px", height: "32px", color: "#6B4545" }}
                  onClick={toInputFocus}
                >
                  <SearchIcon
                    sx={{
                      fill: gradientColors[platform].static
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            width: "100%",
            pointerEvents: "auto",
            ".MuiInputBase-root": {
              borderRadius: "10px",
              height: "46px",
              color: gradientColors[platform].static,
              backgroundColor: "white",
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: `Inter, sans-serif`,
            },
            "& .MuiOutlinedInput-root": {
              outline: "unset",
              "& fieldset": {
                borderColor: gradientColors[platform].static,
                borderRadius: "10px",
              },
              "&:hover fieldset": {
                borderWidth: "2px",
                borderColor: gradientColors[platform].static,
                borderRadius: "10px",
              },
              "&.Mui-focused fieldset": {
                borderWidth: "2px",
                borderColor: gradientColors[platform].static,
                borderRadius: "10px",
              },
            },
            "& input::placeholder": {
              fontSize: "16px",
              fontWeight: 400,
              fontFamily: `Inter, sans-serif`,
              color: "#BC9797",
            },
          }}
        />
        <Chip 
          size='small' 
          label={labelStrings.searchSuggestions.promptsByChatGPT} 
          sx={{ 
            position: 'absolute', 
            top: '-10px', 
            left: '15px', 
            fontSize: '9px', 
            fontFamily: `Inter, sans-serif`, 
            color: '#442424', 
            height: '18px', 
            fontWeight: 700, 
            backgroundColor: '#F6DBDB' 
          }}
        />
      </Box>
      <Button
        sx={{
          height: "46px",
          borderRadius: "10px",
          borderWidth: "1px",
          borderColor: gradientColors[platform].static,
          color: gradientColors[platform].static,
          backgroundColor: "white",
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 600,
          fontFamily: `Inter, sans-serif`,
          "&:hover": {
            borderWidth: "1px",
          },
        }}
        disabled={false}
        variant='outlined'
        onClick={clearSearch}
      >
        {labelStrings.searchSuggestions.resetSearch}
      </Button>
    </Stack>
  )
}
