import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../../localization/useTranslation'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import ProgressBar from '../../../common/progressBar'
import { formatDateToDDMMYYY, formatUnixToMDY } from '../../../../utils'
import { ADDON_IDS } from '../../../../constants/addonIds'

const Wrapper = styled.div`
  & * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
  width: 45%;
  margin-right: 20px;
`

const Title = styled.div`
  color: ${props => props.theme.color.mineShaft};
  font-weight: bold;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 10px;
`

const SubTitle = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: 13px;
  text-transform: uppercase;
  margin: 5px 0;
`

const ProgressBarWrapper = styled.div`
  width: 80%;
  margin: 5px 0 10px 0;
  & .progress_outer {
    background: #fff1ef;
    border-radius: 7px;
    margin-left: 0;
    height: 14px;
  }

  & .progress_inner {
    background: #ff726f 0% 0%;
    border-radius: 7px;
    height: 14px;
  }
`

const TokensContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TokensLeft = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: 13px;
`

const TokenValidText = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: 13px;
  margin-top: 10px;
`

const OutOfTokens = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: 15px;
  margin-top: 10px;
`

const TokensAdded = styled.div`
  font-size: 13px;
  color: ${props => props.theme.color.grey};
`

const ButtonWrapper = styled.div`
  margin-top: 10px;
`

const TokensTextsSection = styled.div`
  color: ${props => props.theme.color.mineShaft};
  font-size: 15px;
`

const ReusableAddOnSection = ({
  title = '',
  changeTokenLabel = '',
  onClickChangeToken,
  tokensLeft = '',
  audienceTokensMonthly = 0,
  tokensTexts = {},
  tokensResetDate,
  isChangeTokenDisabled = true,
  progressbar = {},
  isAbleToBuyTokens = false,
  openConfirmModal,
}) => {
  const { labelStrings, currentLang } = useTranslation()

  return (
    <Wrapper>
      <Title>{title}</Title>
      <SubTitle>{labelStrings.yourPlan}</SubTitle>
      <TokensTextsSection>
        <div>{tokensTexts.currentTokens}</div>
        {tokensTexts.oneTimeTokens &&
          tokensTexts.oneTimeTokens.map((token, index) => (
            <div key={index}>
              {labelStrings.getValidTokens(token.extraTokens)}
              {formatUnixToMDY(token.validUntil, currentLang)}
            </div>
          ))}
        {!!tokensTexts.priceTotal && (
          <div>
            <b>{tokensTexts.priceTotal} EUR</b> {labelStrings.perMonth} ({tokensTexts.pricePerSeat}{' '}
            EUR {labelStrings.perSeat})
          </div>
        )}
      </TokensTextsSection>
      <SubTitle>{labelStrings.usage}</SubTitle>
      <ProgressBarWrapper>
        <ProgressBar current={progressbar.current} limit={progressbar.limit} />
      </ProgressBarWrapper>

      <TokensContainer>
        <TokensLeft>{tokensLeft}</TokensLeft>
        {tokensResetDate && (
          <TokensAdded>
            {/*audienceTokensMonthly}{' '*/}
            {labelStrings.tokensWillBeAddedOnReports}{' '} 
            {formatDateToDDMMYYY({ dateStr: new Date(tokensResetDate).toString() })}
          </TokensAdded>
        )}
      </TokensContainer>
      {isAbleToBuyTokens ? (
        <>
          <OutOfTokens>{labelStrings.getMoreTokens}</OutOfTokens>
          <ButtonWrapper>
            <PrimaryButton
              label={labelStrings.moreTokensNow(50)}
              onClick={openConfirmModal(ADDON_IDS[50])}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <PrimaryButton
              label={labelStrings.moreTokensNow(100)}
              onClick={openConfirmModal(ADDON_IDS[100])}
            />
          </ButtonWrapper>
          <TokenValidText>{labelStrings.valid30Days}</TokenValidText>
        </>
      ) : (
        <ButtonWrapper>
          <PrimaryButton
            label={changeTokenLabel}
            onClick={onClickChangeToken}
            isDisabled={isChangeTokenDisabled}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

ReusableAddOnSection.propTypes = {
  title: PropTypes.string,
  changeTokenLabel: PropTypes.string,
  onClickChangeToken: PropTypes.func,
  tokensLeft: PropTypes.string,
  audienceTokensMonthly: PropTypes.number,
  tokensTexts: PropTypes.object,
  isChangeTokenDisabled: PropTypes.bool,
  progressbar: PropTypes.shape({
    current: PropTypes.number,
    limit: PropTypes.number,
  }),
  tokensResetDate: PropTypes.number,
  isAbleToBuyTokens: PropTypes.bool,
  openConfirmModal: PropTypes.func,
}

export default ReusableAddOnSection
