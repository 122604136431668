import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

import useTranslation from '../../localization/useTranslation'
import { PaginationContainerWrapper } from './styles'

const Pagination = ({
  currentPage = 1,
  maxPagesDisplayCount = 1,
  isMaxPagesLimitExceded = false,
  handlePageChange,
  ...restOfProps
}) => {
  const { labelStrings } = useTranslation()

  return (
    <PaginationContainerWrapper
      isMaxPagesLimitExceded={isMaxPagesLimitExceded}
      nextPage={currentPage + 1}
      className="pagination-wrapper"
    >
      <ReactPaginate
        previousLabel={`<< ${labelStrings.paginate.prev}`}
        nextLabel={`${labelStrings.paginate.next} >>`}
        breakLabel=""
        // breakLabel="..."
        // breakClassName="break-me"
        pageCount={maxPagesDisplayCount || 1}
        marginPagesDisplayed={0}
        pageRangeDisplayed={4}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        pageClassName="page_num"
        nextClassName="nav_btn next"
        previousClassName="nav_btn prev"
        activeClassName="current_page"
        disabledClassName="disabled_btn"
        activeLinkClassName="active_link"
        initialPage={currentPage}
        forcePage={currentPage}
        {...restOfProps}
      />
    </PaginationContainerWrapper>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  maxPagesDisplayCount: PropTypes.number,
  isMaxPagesLimitExceded: PropTypes.bool,
  handlePageChange: PropTypes.func,
}

export default Pagination
