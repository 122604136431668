import React from "react"
import {
  Typography,
  Stack,
  Popper,
  Fade,
  ClickAwayListener,
  IconButton,
  Paper,
  Avatar,
  Grid,
  Box,
  Chip,
  ImageList,
  ImageListItem,
} from "@mui/material"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import { IconVerifiedWrapper } from "../../../../components/profileCommon/profileListTable/tableStyles"
import { VerifiedProfileIcon } from "../../../../components/common/icons/icons"
import useTranslation from "../../../../localization/useTranslation"
import Iconify from "../../../components/iconify"
import {
  findCountryIsoCode,
  getPngCountryFlags,
  pretifyBigNum,
} from "../../../../utils"
import Scrollbar from "../../../components/scrollbar/Scrollbar"
import ProfileTools from "./ProfileTools/ProfileTools"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const QuickPreview = (props) => {
  const { data, isOpenQuickPreview, quickPreviewAnchorEl, handleClose, onBlockUnblockUser, setIsOpenPipelineModal } =
    props || {}

  const { languageOptions, countryOptions } = useTranslation()

  const isProfileVerified = !!data?.isInfluDataVerified
  const userLanguage =
    languageOptions?.find((el) => el.value === data?.language) || {}
  const userCountryFlag = getPngCountryFlags(
    findCountryIsoCode(countryOptions, data?.country),
  )

  const imagesPreview = data?.postLinksArray?.slice(0, 4)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={isOpenQuickPreview}
        anchorEl={quickPreviewAnchorEl}
        placement='left-start'
        disablePortal={false}
        transition
        keepMounted
        sx={{
          zIndex: "99999",
          transform: `translate(-100px, 15px) !important`,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper
              sx={{
                mr: 3,
                width: "370px",
                backgroundColor: "#FFFFFF",
                borderRadius: "18px",
                boxShadow: "0px 4px 41.3px 7px rgba(107, 69, 69, 0.15)",
              }}
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  p: 1,
                  width: "100%",
                  borderBottom: "1px solid #FBEBEB",
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <ProfileTools
                    user={data || {}}
                    isSearchView={true}
                    isQuickPreview
                    stopEventBubbling={() => {}}
                    isSelectedProfileView={false}
                    onBlockUnblockUser={onBlockUnblockUser}
                    setIsOpenPipelineModal={setIsOpenPipelineModal}
                    handleClose={handleClose}
                  />
                </Box>
                <IconButton size='small' onClick={handleClose}>
                  <CancelRoundedIcon sx={{ fill: "#784E4E" }} />
                </IconButton>
              </Stack>
              <Stack
                sx={{ p: 1.5, borderBottom: "1px solid #FBEBEB" }}
                direction='row'
                alignItems='center'
                spacing={2}
              >
                <Stack sx={{ position: "relative" }}>
                  <Avatar
                    sx={{ width: "76px", height: "76px" }}
                    alt={data?.displayName}
                    src={data?.profilePicURL}
                  />
                  {isProfileVerified && (
                    <IconVerifiedWrapper>
                      <VerifiedProfileIcon width={25} height={28} />
                    </IconVerifiedWrapper>
                  )}
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#442424",
                      lineHeight: "19.8px",
                    }}
                  >
                    {data?.displayName}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#784E4E",
                      lineHeight: "16.8px",
                    }}
                  >
                    @{data?.username}
                  </Typography>

                  <Stack sx={{ mt: 1 }} direction='row' alignItems='center' spacing={1}>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Account Status
                        </Typography>
                        <Chip
                          size='small'
                          sx={{
                            width: "fit-content",
                            borderColor: "#1FDC00",
                            color: "#1FDC00",
                            fontWeight: 700,
                            fontFamily: "Inter",
                            fontSize: "12.5px",
                            textTransform: "uppercase",
                            lineHeight: "26.5px",
                          }}
                          label={data?.accountStatus || `Active`}
                          variant='outlined'
                        />
                      </Stack>
                </Stack>
              </Stack>
              <Box sx={{ width: "100%", borderBottom: "1px solid #FBEBEB" }}>
                <Grid container>
                  <Grid
                    item
                    size={6}
                    sx={{
                      height: "122px",
                      width: "50%",
                      borderBottom: "1px solid #FBEBEB",
                      position: "relative",
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        width: "1px",
                        height: "100%",
                        backgroundColor: "#FBEBEB",
                        top: 0,
                        bottom: 0,
                        right: 0,
                      },
                    }}
                  >
                    <Stack spacing={1} sx={{ pl: 5, py: 2 }}>
                      <Stack spacing={0.5}>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Account Status
                        </Typography>
                        <Chip
                          size='small'
                          sx={{
                            width: "fit-content",
                            borderColor: "#1FDC00",
                            color: "#1FDC00",
                            fontWeight: 700,
                            fontFamily: "Inter",
                            fontSize: "12.5px",
                            textTransform: "uppercase",
                            lineHeight: "normal",
                          }}
                          label={data?.accountStatus || `Active`}
                          variant='outlined'
                        />
                      </Stack>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Location
                        </Typography>
                        <Stack
                          direction='row'
                          alignItems='center'
                          sx={{
                            color: "#784E4E",
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                          }}
                        >
                          {userCountryFlag}
                          {data?.country}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    size={6}
                    sx={{
                      height: "122px",
                      width: "50%",
                      borderBottom: "1px solid #FBEBEB",
                    }}
                  >
                    <Stack spacing={0} sx={{ pl: 2, py: 2, width: "100%" }}>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography
                          textAlign='right'
                          sx={{
                            minWidth: "95px",
                            color: "#C79C9C",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          views / story
                        </Typography>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "normal",
                          }}
                        >
                          {pretifyBigNum(data?.medianViewsPerStory)}
                        </Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography
                          textAlign='right'
                          sx={{
                            minWidth: "95px",
                            color: "#C79C9C",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          views / post
                        </Typography>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "normal",
                          }}
                        >
                          {pretifyBigNum(data?.medianViewsPerPost)}
                        </Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography
                          textAlign='right'
                          sx={{
                            minWidth: "95px",
                            color: "#C79C9C",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          plays / reel
                        </Typography>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "normal",
                          }}
                        >
                          {pretifyBigNum(data?.medianPlaysPerReel)}
                        </Typography>
                      </Stack>
                      <Stack
                        direction='row'
                        alignItems='center'
                        spacing={1}
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          textAlign='right'
                          sx={{
                            minWidth: "95px",
                            color: "#C79C9C",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          post engagement
                        </Typography>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "normal",
                          }}
                        >
                          {data?.engagementMean}
                        </Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography
                          textAlign='right'
                          sx={{
                            minWidth: "95px",
                            color: "#C79C9C",
                            fontSize: "9px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          reel engagement
                        </Typography>
                        <Typography
                          sx={{
                            color: "#784E4E",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "normal",
                          }}
                        >{data?.engagementReels?.toFixed(1)}%</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    size={6}
                    sx={{
                      height: "122px",
                      width: "50%",
                      position: "relative",
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        width: "1px",
                        height: "100%",
                        backgroundColor: "#FBEBEB",
                        top: 0,
                        bottom: 0,
                        right: 0,
                      },
                    }}
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      sx={{ p: 1.5 }}
                    >
                      <Stack>
                        <Typography
                          textAlign='center'
                          sx={{
                            color: "#784E4E",
                            fontSize: "12.5px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          OVERALL SCORE
                        </Typography>
                        <Stack alignItems='center'>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "62px",
                              fontFamily: "Inter",
                              lineHeight: "60px",
                              background:
                                "var(--Gradient-02-Gradient, linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%))",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              backgroundClip: "text",
                              textFillColor: "transparent",
                            }}
                          >
                            {data?.profileScore?.overall}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "12.5",
                              fontFamily: "Inter",
                              lineHeight: "normal",
                              color: "#C79C9C",
                              textTransform: "uppercase",
                            }}
                          >
                            {`out of 10`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    size={6}
                    sx={{
                      height: "122px",
                      width: "50%",
                    }}
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      sx={{ p: 1.5 }}
                    >
                      <Stack alignItems='center'>
                        <Typography
                          textAlign='center'
                          sx={{
                            color: "#784E4E",
                            fontSize: "12.5px",
                            fontFamily: "Inter",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          AUTHENTICITY SCORE
                        </Typography>
                        <Stack>
                          <Typography
                            textAlign='center'
                            sx={{
                              fontWeight: 600,
                              fontSize: "62px",
                              fontFamily: "Inter",
                              lineHeight: "60px",
                              background:
                                "var(--Gradient-02-Gradient, linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%))",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              backgroundClip: "text",
                              textFillColor: "transparent",
                            }}
                          >
                            {data?.realPeoplePercentage || 0}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "12.5",
                              fontFamily: "Inter",
                              lineHeight: "normal",
                              color: "#C79C9C",
                              textTransform: "uppercase",
                            }}
                          >
                            {`out of 10`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>

                <Scrollbar
                  sx={{
                    maxHeight: `calc(100vh - 480px)`,
                    width: "100%",
                  }}
                >
                  <Box>
                    <ImageList
                      sx={{ width: "100%", height: 370, overflowY: "hidden" }}
                      cols={2}
                      rowHeight={185}
                      gap={1}
                    >
                      {imagesPreview?.map((preview) => (
                        <ImageListItem key={preview}>
                          <LazyLoadImage src={preview} alt='' />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>

                  <Box sx={{ mt: 1 }}>
                    <Stack spacing={2}>
                      <Typography
                        textAlign='center'
                        sx={{
                          fontWeight: 700,
                          fontSize: "12.5px",
                          color: "#784E4E",
                          textTransform: "uppercase",
                        }}
                      >
                        RECENT BRAND MENTIONS
                      </Typography>
                      <Stack sx={{ height: "100px" }}></Stack>
                    </Stack>
                  </Box>
                </Scrollbar>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  )
}
