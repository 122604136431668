import React, { useEffect, useState } from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Box,
  Typography,
  Slider
} from "@mui/material"
import { pretifyBigNum } from "../../../../../utils"
import { defaultRangeValues, extractValues, getValuesFromQuery, marksAmount, scale, unscale } from "../utils"
import { infludataThemes } from "../../../../../themes/infludataThemes"

export const ReachFilter = (props) => {
  const { selectedPlatform, query, handlersMap, permissions, searchType } =
    props || {}

  const { labelStrings } = useTranslation()


  const querySliderValues = extractValues(query, getValuesFromQuery)
  const currentSliderValueFromQuery = querySliderValues['views']

  const [localSliderValue, setLocalSliderValue] = useState([
    scale(defaultRangeValues.min),
    scale(defaultRangeValues.max),
  ])

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue)
  }

  const onChangeQueryValues = (e, values) => {
    const [min, max] = values
    handlersMap['views']([
      scale(min),
      isNaN(scale(max)) || scale(max) > 8000000 ? Infinity : scale(max)
    ])
  }

  useEffect(() => {
    if (currentSliderValueFromQuery?.length) {
      setLocalSliderValue([
        unscale(currentSliderValueFromQuery[0]),
        currentSliderValueFromQuery[1] === Infinity ||
        currentSliderValueFromQuery[1] === ""
          ? defaultRangeValues.max
          : unscale(currentSliderValueFromQuery[1]),
      ])
    } else {
      setLocalSliderValue([defaultRangeValues.min, defaultRangeValues.max])
    }
  }, [currentSliderValueFromQuery?.length])


  return (
    <Box sx={{ minWidth: '350px', height: '70px',}}>
      <Typography
        sx={{
          color: "#784E4E",
          fontWeight: 700,
          fontSize: "11px",
          textTransform: "uppercase",
          fontFamily: "Inter",
        }}
      >
        {labelStrings.reach}
      </Typography>
      <Box sx={{ px: 2, py: 0.5 }}>
        <Slider
          sx={{
            height: "5px",
            borderRadius: "8px",
            color: infludataThemes[selectedPlatform]?.platformColor,
            "& .MuiSlider-markLabel": {
              fontSize: "10px",
              color: infludataThemes[selectedPlatform]?.platformColor,
              fontWeight: "bold",
            },
          }}
          value={localSliderValue}
          onChange={handleChange}
          onChangeCommitted={onChangeQueryValues}
          valueLabelDisplay='off'
          valueLabelFormat={(val) =>
            val > 8000000 || isNaN(val) ? "∞" : pretifyBigNum(val)
          }
          min={defaultRangeValues.min}
          max={defaultRangeValues.max}
          marks={marksAmount}
          scale={scale}
        />
      </Box>
    </Box>
  )
}
