import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import Popup from "reactjs-popup"
import { ProfileButton } from "../../../../components/common/buttons/styledButtons"
import useTranslation from "../../../../localization/useTranslation"
import AudienceReportsTokensStat from "../../../../components/tokens/stats/AudienceReportsTokensStat"
import CampaignsSeatsTokensStat from "../../../../components/tokens/stats/CampaignsSeatsTokensStat"
import { NavbarProfileIcon } from "../navbarIcons"
import { Button, Box, Divider } from "@mui/material"

const Wrapper = styled.div`
  position: relative;

  & * {
    font-family: ${(props) => props.theme.fontFamilyInter};
  }
  .profile-popup-nav-content {
    padding: 0 !important;
    border-radius: 10px !important;
    width: 200px !important;
    z-index: 1000 !important;
    left: -165px !important;
    border: none !important;
  }

  .profile-popup-nav-content,
  .email-popup-content {
    z-index: 400;
    background: ${(props) => props.theme.color.white} !important;
    box-shadow: 0px 6px 20px #8436302b !important;

    & .profile-popup-nav-arrow,
    & .email-popup-arrow {
      z-index: 300;
      left: 184px !important;
      background: ${(props) =>
        props.showTokensStats
          ? props.theme.color.kabul
          : props.theme.color.white} !important;
      box-shadow: 0px 0px 6px transparent !important;
    }
  }
`

const ProfileNavMenu = ({
  onLogoutClick,
  goToSettings,
  showTokensStats,
  showCampaignsTrackStats,
  openMenuManually,
  isTrial,
}) => {
  const { labelStrings } = useTranslation()
  const location = useLocation()

  const [isHovered, setIsHovered] = useState(false)

  const isActive = location?.pathname?.includes("settings")

  return (
    <Wrapper showTokensStats={showTokensStats && showCampaignsTrackStats}>
      <Popup
        trigger={
          <div>
            <Button
              sx={{
                minWidth: "40px",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={goToSettings}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <NavbarProfileIcon isActive={isActive || isHovered} />
            </Button>
          </div>
        }
        className={"profile-popup-nav"}
        on='hover'
        closeOnDocumentClick
        position='bottom right'
        open={openMenuManually}
      >
        {(close) => (
          <>
            {showTokensStats && (
              <Box
                sx={{
                  overflow: "hidden",
                  position: "relative",
                  top: "-1px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <AudienceReportsTokensStat isTrial={isTrial} />
                {showCampaignsTrackStats && (
                  <>
                    <Divider sx={{ width: "100%", borderColor: "#CBB3B3" }} />
                    <CampaignsSeatsTokensStat />
                  </>
                )}
              </Box>
            )}
            <Box sx={{ p: "5px" }}>
              <Button
                disableRipple
                variant='text'
                onClick={goToSettings}
                sx={{
                  color: "#6B4545",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: "unset",
                    textDecoration: "underline #6B4545",
                  },
                }}
              >
                {labelStrings.editYourAccount}
              </Button>
            </Box>
            <Divider sx={{ width: "100%", borderColor: "#CBB3B3" }} />
            <Box sx={{ p: "5px" }}>
              <Button
                disableRipple
                variant='text'
                onClick={onLogoutClick}
                sx={{
                  color: "#6B4545",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: "unset",
                    textDecoration: "underline #6B4545",
                  },
                }}
              >
                {labelStrings.logout}
              </Button>
            </Box>
          </>
        )}
      </Popup>
    </Wrapper>
  )
}

export default ProfileNavMenu
