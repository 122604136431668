import React from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from "@mui/material"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { findCountryIsoCode, getPngCountryFlags } from "../../../../../utils"

export const CountryFilter = (props) => {
  const { selectedPlatform, query, handlersMap } = props || {}

  const { labelStrings, countryOptions } = useTranslation()

  const countriesFromQuery = query?.country

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {}
    handlersMap?.country(value)
  }

  const onDelete = (deleteValue) => {
    const payload = countriesFromQuery
      ?.split(",")
      ?.filter((el) => el !== deleteValue)
    if (payload?.length) {
      handlersMap?.country(payload)
    } else {
      handlersMap?.country([])
    }
  }

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.selectMultiCountries}
        </InputLabel>
        <Select
          sx={{
            "& .MuiMenuItem-root": {
              fontSize: "12px",
            },
          }}
          size='small'
          multiple
          value={query.country ? query.country?.split(",") : []}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.selectMultiCountries} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                color: "#442424",
              },
            },
          }}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    size='small'
                    key={value}
                    label={
                      <Stack direction='row' alignItems='center'>
                        {getPngCountryFlags(
                          findCountryIsoCode(countryOptions, value),
                        )}{" "}
                        {value}
                      </Stack>
                    }
                    variant='contained'
                    onMouseDown={(event) => event.stopPropagation()}
                    onDelete={() => onDelete(value)}
                    deleteIcon={<CloseRoundedIcon sx={{ fill: "#442424" }} />}
                    sx={{
                      color: "#442424",
                      fontWeight: "bold",
                      backgroundColor: "#F6DBDB",
                    }}
                  />
                ))}
              </Box>
            )
          }}
        >
          <MenuItem
            key='clear'
            value=''
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              fontFamily: "Inter",
            }}
          >
            {labelStrings.all}
          </MenuItem>
          {countryOptions?.map((country) => (
            <MenuItem
              key={country?.isoCode}
              value={country?.value}
              sx={{ fontSize: "12px", fontWeight: 700, fontFamily: "Inter" }}
            >
              <Stack direction='row' alignItems='center'>
                {getPngCountryFlags(
                  findCountryIsoCode(countryOptions, country.value),
                )}
                {country?.value}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
