import React, { useState, useEffect, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  selectedPlatformSelector,
  currentSearchQuerySelector,
  userGrantSelector,
  availableCountriesCitiesSelector,
} from "../../../../redux/selectors";
import {
  resetSearchResults,
  // fetchStartupSearch,
  updateSearch,
  // abortSearchProccess,
} from "../../../../redux/ducks/searchDucks";
import useTranslation from "../../../../localization/useTranslation";
import { parseArrValuesToString } from "../../../../utils";
import {
  getSuggestionsFromCacheOrFetch,
  cancelAllFetches,
} from "./searchbarTipsFetches";
import SearchbarMediator from "./SearchbarMediator";
import SocialPlatformTabs from "../../../../components/common/tabs/SocialPlatformTabs_";
import {
  SearchContainerWrapper,
  SearchbarContent,
} from "../../../../components/common/styledWrappers";
import { USER_INDEXES } from "../../../../constants/appSettings";
import WithTip from "../../../../components/common/popups/WithTip";
import { ToggleSwitchSearchContent } from "../../../../components/toggleSwitch/ToggleSwitchSearchContent";
import { ContentTypeBtn } from "./ContentTypeBtn";
import { searchContentTypes } from "../../../../constants/search";
import Scrollbar from "../../scrollbar/Scrollbar";
import { toBoolean } from "./utils";

const SearchBarContainer = ({
  isSearchEnabled,
  userindex,
  searchType,
  onChangeSearchType,
  currentOpenSearchParam,
  setCurrentOpenSearchParam,
}) => {
  const { labelStrings } = useTranslation();
  // const location = useLocation()
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedPlatform = useSelector(selectedPlatformSelector, shallowEqual);
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual);

  const availableCountriesCities = useSelector(
    availableCountriesCitiesSelector,
    shallowEqual,
  );
  const {
    enable_business_search: allowBusinessSearch,
    package_free: isFreeUser,
    no_plan: noPlan,
    audience_search_enabled,
    minimum_growth,
    content_search,
  } = useSelector(userGrantSelector, shallowEqual);

  const [suggestionsKeywords, changeSuggestionsKeywords] = useState([]);

  // useEffect(() => {
  //   isSearchEnabled && dispatch(fetchStartupSearch(location))
  //   return () => {
  //     if (isSearchEnabled) {
  //       dispatch(abortSearchProccess())
  //       cancelAllFetches()
  //     }
  //   }
  // }, [isSearchEnabled])

  useEffect(() => {
    if (isSearchEnabled) {
      (async () => {
        const suggestionsKeywords =
          await getSuggestionsFromCacheOrFetch(currentQuery);
        if (suggestionsKeywords && suggestionsKeywords.length)
          changeSuggestionsKeywords(suggestionsKeywords);
      })();
    }

    return () => {
      isSearchEnabled && cancelAllFetches();
    };
  }, [currentQuery]);

  // reset search params and results
  const clearSearch = () => {
    cancelAllFetches();
    dispatch(resetSearchResults(history));
  };

  // update search reusable handler
  const changeSearch = (newValuesObj) => {
    const payload = {
      ...newValuesObj,
      isSimilarContentPieces: false,
    };
    dispatch(updateSearch({ newValuesObj: payload }));
  };

  // search handlers map
  const handlersMap = useMemo(
    () => ({
      country: (countries) =>
        changeSearch({ country: countries?.join(","), city: "" }),
      city: (cities) => changeSearch({ city: cities?.join(",") }),
      followers: ([min, max]) =>
        changeSearch({ followerMin: min, followerMax: max }),
      plays: ([min, max]) => changeSearch({ playsMin: min, playsMax: max }),
      views: ([min, max]) => changeSearch({ viewsMin: min, viewsMax: max }),
      playsPerReel: ([min, max]) =>
        changeSearch({ reelPlaysMin: min, reelPlaysMax: max }),
      language: (lang) => changeSearch({ language: lang }),
      gender: (selectedGender) => changeSearch({ gender: selectedGender }),
      keywords: (selectedKeywordsArr) =>
        changeSearch({
          keywords: selectedKeywordsArr,
        }),
      brandMentions: (brandMentionsArr) =>
        changeSearch({
          brandMentions: brandMentionsArr,
        }),
      creatorCollaborations: (creatorCollaborationsArr) =>
        changeSearch({
          creatorCollaborations: creatorCollaborationsArr,
        }),
      categories: (categories) => changeSearch({ categories: categories }),
      email: () =>
        changeSearch({
          mustIncludeEmail: toBoolean(!currentQuery.mustIncludeEmail),
        }),
      appearsOn: (selectedValuesArr) =>
        changeSearch({ appearsOn: parseArrValuesToString(selectedValuesArr) }),
      businessSearch: (checked) => changeSearch({ businessSearch: checked }),
      growthRate: (value) => changeSearch({ growthRate: value }),
      audience: (audience) => changeSearch({ audience }),
      accountType: (typeValue) => changeSearch({ isShortsCreator: typeValue }),
      youTubeShorts: ([min, max]) =>
        changeSearch({ shortsPlaysMin: min, shortsPlaysMax: max }),
      youtubeVideos: ([min, max]) =>
        changeSearch({ viewsMin: min, viewsMax: max }),
      location: (placesId) => {
        changeSearch({ placesId, radiusKM: 10 });
      },
      radiusKM: (km) => changeSearch({ radiusKM: km }),
      engagementRate: (value) =>
        changeSearch({ engagementRate: Number(value) }),
      hideCreatorsInCollection: (checked) =>
        changeSearch({ hideCreatorsInCollection: toBoolean(checked) }),
    }),
    [currentQuery],
  );

  const permissions = useMemo(
    () => ({
      allowBusinessSearch: !!allowBusinessSearch,
      allowCitiesSearch: !isFreeUser,
      allowKeywordsSearch: !isFreeUser, //TODO: set it to true, if we want free user to use keywords search
      allowAudienceSearch:
        (userindex === USER_INDEXES.instagram ||
          userindex === USER_INDEXES.youtube) &&
        audience_search_enabled,
    }),
    [allowBusinessSearch, isFreeUser, audience_search_enabled, userindex],
  );

  const OuterWrapper = isSearchEnabled ? Fragment : WithTip;

  return (
    <OuterWrapper
      {...(!isSearchEnabled && {
        withSignup: !noPlan,
        tipText: noPlan
          ? labelStrings.notActivatedAccountTipTitle
          : labelStrings.disabledFunctionInYourPlanTip,
        skipSpecialUpgradeText: noPlan,
        position: "center center",
      })}
    >
      <div>
        <Stack
          sx={{
            ml: 2,
            mb: 1,
            pointerEvents: isSearchEnabled ? "auto" : "none",
            opacity: isSearchEnabled ? 1 : 0.4,
          }}
          spacing={1.5}
        >
          <ToggleSwitchSearchContent
            onChange={onChangeSearchType}
            checked={searchType}
            isContentSearch={content_search}
            userindex={userindex}
          />
          <SocialPlatformTabs userindex={userindex} searchType={searchType} />
          {userindex !== USER_INDEXES.tiktok &&
            searchType === searchContentTypes.CONTENT && (
              <ContentTypeBtn searchType={searchType} userindex={userindex} />
            )}
        </Stack>
        <SearchbarContent isActive={isSearchEnabled}>
          <Scrollbar sx={{ pl: 2, maxHeight: `calc(100vh - 150px)`, p: 0 }}>
            <SearchContainerWrapper isSearchEnabled={isSearchEnabled}>
              <SearchbarMediator
                clearSearch={clearSearch}
                query={currentQuery}
                handlersMap={handlersMap}
                suggestionsKeywords={suggestionsKeywords}
                selectedPlatform={selectedPlatform}
                permissions={permissions}
                availableCountriesCities={availableCountriesCities}
                userindex={userindex}
                grants={{ minimum_growth }}
                searchType={searchType}
                currentOpenSearchParam={currentOpenSearchParam}
                setCurrentOpenSearchParam={setCurrentOpenSearchParam}
              />
            </SearchContainerWrapper>
          </Scrollbar>
        </SearchbarContent>
      </div>
    </OuterWrapper>
  );
};

SearchBarContainer.propTypes = {
  isSearchEnabled: PropTypes.bool,
  userindex: PropTypes.string,
  searchType: PropTypes.string,
  onChangeSearchType: PropTypes.func,
  setCurrentOpenSearchParam: PropTypes.func,
};

export default SearchBarContainer;
