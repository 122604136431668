import React, { useEffect, useMemo } from "react";
import { TableRow, TableCell, Collapse, Box } from "@mui/material";
import { QueryMatchedContentCard } from "./QueryMatchedContentCard";
import Carousel from "react-multi-carousel";
import { useElementHeight } from "../../../hooks/useElementHeight";
import { searchLoadingStatusSelector } from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import { simulateResize } from "../utils";

export const QueryMatchedContent = ({
  openQueryMatchedContent,
  queryMatchedContent,
  tableWidth,
  initialTableWidth,
}) => {
  const isSearchLoading = useSelector(searchLoadingStatusSelector);
  const slideWidth = tableWidth || initialTableWidth;

  const carouselContentHeight = useElementHeight(
    "carousel-wrapper",
    openQueryMatchedContent,
  );

  const countSlideToShow = useMemo(
    () => Math.floor(slideWidth / 200) || 1,
    [slideWidth, isSearchLoading],
  );

  useEffect(() => {
    if (carouselContentHeight === 0 && openQueryMatchedContent) {
      simulateResize()
    }
  }, [carouselContentHeight, openQueryMatchedContent])

  return (
    <TableRow sx={{ borderBottom: "1px solid #FFF5F5", position: "relative" }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse
          easing={{
            enter: "cubic-bezier(0, 1.5, .8, 1)",
            exit: "linear"
          }}
          in={openQueryMatchedContent}
          timeout={{
            enter: '0.8s',
            exit: '0.3s'
          }}
          unmountOnExit
          keepMounted
          sx={{ height: `${carouselContentHeight + 32}px !important` }}
        >
          <Box
            sx={{
              transition: 'backdrop-filter 1s ease-out',
              position: "absolute",
              left: 0,
              right: 0,
              zIndex: 99999,
              px: 1.5,
              my: 2,
              "& .react-multiple-carousel__arrow": {
                background: "rgba(255, 245, 245, 0.8)",
                "&:hover": {
                  background: "rgba(255, 245, 245, 0.8)",
                  opacity: 0.7,
                },
                "&:before": {
                  color: "#784E4E",
                },
              },
              '& .react-multiple-carousel__arrow--left': {
                left: '0px !important'
              },
              '& .react-multiple-carousel__arrow--right': {
                right: '0px !important'
              },
              "& .react-multi-carousel-track": {
                px: 5
              },
              '& .react-multi-carousel-item': {
                maxWidth: '200px'
              }
            }}
          >
            <Carousel
              arrows
              renderButtonGroupOutside
              containerClass="carousel-wrapper"
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: countSlideToShow - 1,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 4,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              showDots={false}
              slidesToSlide={countSlideToShow - 1}
              swipeable
            >
              {queryMatchedContent?.map((slideData) => (
                <div
                  style={{ width: "fit-content" }}
                  key={slideData?.contentId}
                >
                  <QueryMatchedContentCard data={slideData} />
                </div>
              ))}
            </Carousel>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};