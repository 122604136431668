import React, { useRef, useState } from "react"
import {
  TableRow,
  TableCell,
  IconButton,
  Stack,
  Typography,
  Chip,
  Button,
  Tooltip,
} from "@mui/material"
import { Link } from "react-router-dom"
import Iconify from "../../../components/iconify"
import {
  getPngCountryFlags,
  pretifyBigNum,
  findCountryIsoCode,
  constructLinkToWECFileServer,
} from "../../../../utils"
import { ProfileAvatar } from "./ProfileAvatar"
import {
  AdditionalFieldDetailsOverlay,
  IconVerifiedWrapper,
  ModalContainer,
  ProfileImagesWrapper,
  ProfileImgWrapper,
} from "../../../../components/profileCommon/profileListTable/tableStyles"
import { VerifiedProfileIcon } from "../../../../components/common/icons/icons"
import useTranslation from "../../../../localization/useTranslation"
import { SearchUserGrowthDynamics } from "./UserGrowthDynamics"
import RelativePortal from "react-relative-portal"
import LazyImage from "../../../../components/lazyImage"
import ProfileTools from "./ProfileTools/ProfileTools"
import InfoRoundedIcon from "@mui/icons-material/InfoRounded"
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded"
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded"
import { QueryMatchedContent } from "./QueryMatchedContent"
import EditCollectionModal from "../../../../components/collections/collectionTools/EditCollectionModal"
import AddToCollectionModal from "../../../../components/collections/collectionTools/AddToCollectionModal"

const stylesLabel = {
  color: `#6B4545`,
  fontFamily: `Inter`,
  fontSize: `14px`,
  fontStyle: `normal`,
  fontWeight: 650,
  lineHeight: `21.5px`,
}

export const SearchTableRow = (props) => {
  const { countryOptions, languageOptions, labelStrings } = useTranslation()

  const modalRef = useRef(null)

  const {
    row,
    setIsOpenQuickPreview,
    setQuickPreviewAnchorEl,
    setCurrentDataForQuickPreview,
    currentQuery,
    tableWidth,
    initialTableWidth,
  } = props || {}

  const {
    profileScore,
    isInfluDataVerified,
    monthlyGrowthFollowers,
    _index,
    _id,
    profilePicURL,
    displayName,
    username,
    followers,
    engagementMean,
    country,
    language,
    description,
    queryMatchedContent,
    categories,
    city
  } = row || {}

  const renderCategories = categories?.map((el, idx) => {
    return (
      <Chip
        key={idx}
        sx={{ backgroundColor: "#FAEBEB" }}
        size='small'
        label={
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.5}
            sx={{ color: "#D4A9A9" }}
          >
            <Iconify icon='bxs:layer' />
            <Typography
              sx={{
                color: "#6B4545",
                fontSize: "11px",
                fontFamily: "Inter",
              }}
            >
              {el.category}
            </Typography>
          </Stack>
        }
      />
    )
  })

  const keywordsFromQuery = currentQuery.keywords
    ? currentQuery.keywords?.split(",")
    : []

  const brandMentionsFromQuery = currentQuery.brandMentions
  ? currentQuery.brandMentions?.split(",")
  : []

  const creatorCollaborationsFromQuery = currentQuery.creatorCollaborations
  ? currentQuery.creatorCollaborations?.split(",")
  : []

  const combinedBubbleArray = [...keywordsFromQuery, ...brandMentionsFromQuery, ...creatorCollaborationsFromQuery]

  const isQueryMatchedContent = queryMatchedContent?.length > 0

  const link = `/profile/${_id}`

  const [isAddToCollectionOpened, setIsAddToCollectionOpened] = useState(false)
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] =
    useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [openQueryMatchedContent, setOpenQueryMatchedContent] = useState(false)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setQuickPreviewAnchorEl(event.currentTarget)
    setIsOpenQuickPreview((prev) => !prev)
    setCurrentDataForQuickPreview(row)
  }

  const isProfileVerified = !!isInfluDataVerified

  const userCountryFlag = getPngCountryFlags(
    findCountryIsoCode(countryOptions, country),
  )
    ? getPngCountryFlags(findCountryIsoCode(countryOptions, country))
    : country
  const userLanguage =
    languageOptions?.find((el) => el.value === language) || {}

  const onOpenMatchedContent = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenQueryMatchedContent((prev) => !prev)
  }

  return (
    <>
      <TableRow
        className='main-search-row'
        component={Link}
        to={link}
        hover
        sx={{
          cursor: "pointer",
          position: "relative",
          borderBottom: !isQueryMatchedContent && "1px solid #FFF5F5",
          textDecoration: "none",
          '&:hover': {
            textDecoration: "none"
          }
        }}
      >
        <TableCell
          align='left'
          sx={{
            cursor: "pointer",
            pb: isQueryMatchedContent ? "50px" : "16px",
          }}
        >
          <ModalContainer
            ref={modalRef}
            onMouseOver={() => setOpenModal(true)}
            onMouseLeave={() => setOpenModal(false)}
          >
            <Stack sx={{ position: "relative" }}>
              <ProfileAvatar
                score={profileScore.overall}
                profilePicUrl={profilePicURL}
                userindex={_index}
                withRating={true}
                withOverlay={openModal}
                size={75}
              />
              {isProfileVerified && (
                <IconVerifiedWrapper>
                  <VerifiedProfileIcon width={25} height={28} />
                </IconVerifiedWrapper>
              )}
            </Stack>
            {openModal && !!row?.postLinksArray?.length && (
              <RelativePortal component='div'>
                <AdditionalFieldDetailsOverlay>
                  <ProfileImagesWrapper>
                    {row?.postLinksArray?.map((el, index) => (
                      <ProfileImgWrapper key={index}>
                        <LazyImage
                          key={index}
                          src={constructLinkToWECFileServer(el)}
                          alt=''
                        />
                      </ProfileImgWrapper>
                    ))}
                  </ProfileImagesWrapper>
                </AdditionalFieldDetailsOverlay>
              </RelativePortal>
            )}
          </ModalContainer>
        </TableCell>

        <TableCell
          align='left'
          sx={{ pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          <Stack sx={{ minWidth: "200px" }}>
            <Typography sx={{ ...stylesLabel }}>
              {displayName && displayName.length > 25
                ? displayName.slice(0, 23) + "..."
                : displayName}
            </Typography>
            <Typography sx={{ ...stylesLabel, fontSize: "13px" }}>
              @{username}
            </Typography>
            <Stack
              direction='row'
              alignItems='center'
              sx={{ mt: 1, maxWidth: "200px", flexWrap: "wrap", gap: "4px" }}
            >
              {userCountryFlag && <Chip
                sx={{ backgroundColor: "#FAEBEB" }}
                size='small'
                label={
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0.5}
                    sx={{ color: "#D4A9A9", fontSize: "11px"}}
                  >
                    <Iconify icon='ic:round-home' />
                    {city ? city + ' ' : ''}{userCountryFlag}
                  </Stack>
                }
              />}
              <Chip
                sx={{ backgroundColor: "#FAEBEB" }}
                size='small'
                label={
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0.5}
                    sx={{ color: "#D4A9A9" }}
                  >
                    <Iconify icon='mdi:message-bubble' />
                    <Typography
                      sx={{
                        color: "#6B4545",
                        fontSize: "11px",
                        fontFamily: "Inter",
                      }}
                    >
                      {userLanguage.label ? userLanguage.label : ""}
                    </Typography>
                  </Stack>
                }
              />
              {renderCategories}
            </Stack>
          </Stack>
        </TableCell>

        <TableCell
          align='left'
          sx={{ pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          <Stack sx={{ maxWidth: "100px" }} alignItems='center'>
            <Typography sx={{ ...stylesLabel }}>
              {pretifyBigNum(followers)}
            </Typography>
            <Typography sx={{ position: "relative", width: "100%" }}>
              {!!monthlyGrowthFollowers?.length && (
                <SearchUserGrowthDynamics growthData={monthlyGrowthFollowers} />
              )}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          align='center'
          sx={{ ...stylesLabel, pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          {engagementMean}
        </TableCell>

        <TableCell
          align='left'
          sx={{ pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          {
            <Typography
              dangerouslySetInnerHTML={{ __html: description }}
              sx={{
                ...stylesLabel,
                maxWidth: "490px",
                minWidth: "150px",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "13px",
              }}
            />
          }
        </TableCell>

        <TableCell
          align='right'
          sx={{ pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          <ProfileTools
            user={{ ...row }}
            isSearchView={true}
            stopEventBubbling={() => {}}
            isSelectedProfileView={false}
            /*for add to collections */
            isAddToCollectionOpened={isAddToCollectionOpened}
            isCreateCollectionOpened={isCreateCollectionOpened}
            setIsAddToCollectionOpened={setIsAddToCollectionOpened}
            setIsCreateCollectionOpened={setIsCreateCollectionOpened}
          />
        </TableCell>

        {/*<TableCell
          align='center'
          sx={{ pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          <IconButton
            sx={{ color: "#784E4E", "&:hover": { color: "#FF5500" } }}
            onClick={handleClick}
          >
            <Iconify icon='jam:search-plus' width='24px' height='24px' />
          </IconButton>
        </TableCell>*/}

        {isQueryMatchedContent && (
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{
              px: 4,
              pb: 0.5,
              width: tableWidth ? tableWidth : initialTableWidth,
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          >
            <Tooltip title={labelStrings.queryMatchedTooltipText}>
              <InfoRoundedIcon sx={{ fill: "#F6DBDB", fontSize: "16px" }} />
            </Tooltip>
            <Typography
              sx={{ fontSize: "13px", fontFamily: "Inter", color: "#6B4545" }}
            >
              {labelStrings.found}
              <Typography
                sx={{
                  px: 0.5,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  color: "#6B4545",
                  fontWeight: 700,
                }}
                component='span'
              >
                {queryMatchedContent?.length}
              </Typography>
              {labelStrings.contentPiecesContaining}
            </Typography>

            {combinedBubbleArray?.map((el, idx) => {
              if (idx > 2) return
              return (
                <Chip
                  key={el}
                  size='small'
                  sx={{
                    color: "#6B4545",
                    backgroundColor: "#EBD0D0",
                    fontFamily: "Inter",
                  }}
                  label={el}
                />
              )
            })}

            <Button
              size='small'
              sx={{
                px: 1,
                "& .MuiButton-endIcon": { ml: "2px" },
                "&:hover": { borderRadius: "16px" },
              }}
              variant='text'
              onClick={onOpenMatchedContent}
              endIcon={
                openQueryMatchedContent ? (
                  <KeyboardArrowUpRoundedIcon />
                ) : (
                  <KeyboardArrowDownRoundedIcon />
                )
              }
            >
              {openQueryMatchedContent ? labelStrings.hide : labelStrings.show}
            </Button>
          </Stack>
        )}
      </TableRow>

      <QueryMatchedContent
        openQueryMatchedContent={openQueryMatchedContent}
        queryMatchedContent={queryMatchedContent}
        tableWidth={tableWidth}
        initialTableWidth={initialTableWidth}
      />

      {isAddToCollectionOpened && (
        <AddToCollectionModal
          profile={row}
          platform={_index}
          collectionArray={row.collectionArray}
          closeModal={() => setIsAddToCollectionOpened(false)}
          openCreateCollectionModal={() => {
            setIsCreateCollectionOpened(true)
            setIsAddToCollectionOpened(false)
          }}
          withoutPortal
        />
      )}

      {isCreateCollectionOpened && (
        <EditCollectionModal
          platform={_index}
          isNewCollection
          closeModal={() => setIsCreateCollectionOpened(false)}
          withoutPortal
        />
      )}
    </>
  )
}
