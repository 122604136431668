import React from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material"

const keywordsCategoriesOptions = [
  { value: "fashion", label: "Fashion & Style" },
  { value: "fitness", label: "Fitness & Wellness" },
  { value: "sports", label: "Athletics & Sports" },
  { value: "beauty", label: "Beauty & Cosmetics" },
  { value: "food", label: "Food & Drink" },
  { value: "diet", label: "Healthy Nutrition & Diet" },
  { value: "veganism", label: "Veganism & Vegetarianism" },
  { value: "travel", label: "Travel & Adventure" },
  { value: "interior", label: "Home & Interior Design" },
  { value: "tech", label: "Technology & Gadgets" },
  { value: "art", label: "Art & Creativity" },
  { value: "comedy", label: "Comedy" },
  { value: "education", label: "Education & Learning" },
  { value: "family", label: "Parenting & Family" },
  { value: "media", label: "Entertainment & Media" },
  { value: "music", label: "Music & Performing Acts" },
  { value: 'lgbtq', label: 'LGBTQ+' },
  { value: "gaming", label: "Gaming" },
  { value: "business", label: "Business & Finance" },
  { value: "automotive", label: "Automotive & Vehicles" },
  { value: "sustainability", label: "Sustainability & Environment" },
  { value: "animals", label: "Animals & Pets" },
  { value: "charity", label: "Charity & Activism" },
  { value: "politics", label: "Politics" },
]

export const CategoriesFilter = (props) => {
  const { selectedPlatform, query, handlersMap, permissions } = props || {}

  const { labelStrings } = useTranslation()

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {}
    handlersMap?.categories(value)
  }

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.pleaseSelectCategories}
        </InputLabel>
        <Select
          // disabled={!!permissions.allowKeywordsSearch}
          size='small'
          value={query.categories ? query.categories : ""}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.pleaseSelectCategories} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                color: "#442424",
              },
            },
          }}
        >
          <MenuItem
            key='clear'
            value=''
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              fontFamily: "Inter",
            }}
          >
            {labelStrings.all}
          </MenuItem>
          {keywordsCategoriesOptions?.map((category) => (
            <MenuItem
              key={category?.value}
              value={category?.value}
              sx={{ fontSize: "12px", fontWeight: 700, fontFamily: "Inter" }}
            >
              {category?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
