import PropTypes from 'prop-types'
import { alpha, useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Iconify from '../../iconify'

export const NAV = {
  W_VERTICAL: 315,
  W_VERTICAL_SMALL: 105,
  W_MINI: 88,
}

export default function NavToggleButton({ sx, navType, onChangeNavType, ...other }) {
  const theme = useTheme()

  const onChangeNav = () => {
    const value = navType === 'default' ? 'min' : 'default'
    onChangeNavType(value)
  }

  return (
    <IconButton
      size="small"
      onClick={onChangeNav}
      sx={{
        p: 0.5,
        top: navType === 'default' ? 93 : 106,
        position: 'absolute',
        left: navType === 'default' ? NAV.W_VERTICAL : NAV.W_VERTICAL_SMALL,
        zIndex: 2147483647,
        border: `dashed 1px ${theme.palette.divider}`,
        backdropFilter: `blur(${6}px)`,
        WebkitBackdropFilter: `blur(${6}px)`,
        backgroundColor: alpha('#000000', 0.05),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={navType === 'min' ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-back-fill'}
      />
    </IconButton>
  )
}

NavToggleButton.propTypes = {
  sx: PropTypes.object,
  onChangeNavType: PropTypes.func,
  navType: PropTypes.string,
}
