// @ts-nocheck
import React, { useEffect } from 'react'
import { Box, Link, Modal, Stack, Typography, useMediaQuery } from '@mui/material'
import PdfLogo from '../../../../images/influData-Logo-Pdf-Layout.png'
import SignupStepTwoForm from '../SignupStepTwoForm'
import { WhichPlatform } from '../afterSuccessSignup/WhichPlatform'
import { LookingFor } from '../afterSuccessSignup/LookingFor'
import { InvitesTeammates } from '../afterSuccessSignup/InvitesTeammates'
import LoadingSpinner from '../../../common/spinners/LoadingSpinner'
import { ScheduleQuickDemo } from '../afterSuccessSignup/ScheduleQuickDemo'
import SignUpSuccess from '../../SignUpSuccess'
import { salesManagerSelector } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { ThankYouPage } from '../afterSuccessSignup/ThankYouForSignup'
import useTranslation from '../../../../localization/useTranslation'
import { DescribeYourBusiness } from '../afterSuccessSignup/DescribeYourBusiness'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

const style = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  overflow: 'hidden',
}

export const SignupVerifiedView = props => {
  const {
    finishSignup,
    authMethod,

    currentStep,
    userData,
    afterSignupData,
    setAfterSignupData,
    setSteps,

    isOpened,
    onClose,
    isLoading,

    isTrialCampaigns,
    isSevenDaysTrial
  } = props

  const { labelStrings } = useTranslation()
  const mediaQueryMaxHeight = useMediaQuery('(max-height:680px)')
  const isMobile = useResponsive('down', 'sm')

  const { calendlyLink } = useSelector(salesManagerSelector)

  const handleContinue = () => {
    setSteps(currentStep + 1)
  }

  const renderedJSX = isTrialCampaigns
    ? {
        [2]: <ThankYouPage isTrialCampaigns={isTrialCampaigns} />,
      }
    : {
        [2]: (
          <DescribeYourBusiness
            username={userData?.firstName}
            afterSignupData={afterSignupData}
            setAfterSignupData={setAfterSignupData}
            handleContinue={handleContinue}
          />
        ),
        [3]: (
          <WhichPlatform
            username={userData?.firstName}
            afterSignupData={afterSignupData}
            setAfterSignupData={setAfterSignupData}
            handleContinue={handleContinue}
          />
        ),
        [4]: (
          <LookingFor
            username={userData?.firstName}
            customerId={userData?.customerId}
            afterSignupData={afterSignupData}
            setAfterSignupData={setAfterSignupData}
            handleContinue={handleContinue}
          />
        ),
        [5]: (
          <InvitesTeammates
            afterSignupData={afterSignupData}
            setAfterSignupData={setAfterSignupData}
            handleContinue={handleContinue}
          />
        ),
        [6]: <ScheduleQuickDemo handleContinue={handleContinue} />,
        [7]: <ThankYouPage />,
      }

  useEffect(() => {
    if (currentStep === 6 && !calendlyLink) {
      handleContinue()
    }
  }, [calendlyLink, currentStep])

  const referralsArray = [
    {
      name: 'Sarah Emmerich - Creator Marketing Expert',
      text:
        '"influData makes it easier for me to find influencers in different niches and sizes for my clients. Also, influData is my most valuable tool in analyzing influencers I’ve already selected, because I get a quality analysis about the target audience and all the important insights without even having contacted the influencer or their management."',
      imageLink: 'https://infludata.com/wp-content/uploads/Sarah_Emmerich.webp',
    },
    {
      name:
        'Hannah Sollé - Teamlead Influencer Marketing, hi!shaare.that (Medialabel Network GmbH)',
      text:
        '"The best foundation for successful influencer marketing is data. InfluData allows marketers to easily see accurate assessments of key KPIs. At hi!share.that, we\'ve been using InfluData for 2 years and are discovering more and more creators for our community."',
      imageLink: 'https://infludata.com/wp-content/uploads/Hannah_Solle-1.webp',
    },
    {
      name: 'Daniel Zoll- Digital Content Creative, Speaker, Consultant',
      text:
        '"influData is a must have-tool for marketers who want to do influencer marketing on a wide professional level. You can get deeper insights about the audiences of your preferred creators and track their content performance on the most important platforms and the latest formats. Thumbs up!"',
      imageLink: 'https://infludata.com/wp-content/uploads/Daniel_Zoll.webp',
    },
    {
      name: 'Ricarda - HSE GmbH (on OMR Reviews)',
      text:
        '"The tool offers intuitive operation. Even when new features are introduced, it quickly becomes clear how they work, thanks in part to helpful hints. In addition, the Chrome plugin is very useful for not having to constantly switch back and forth between the platforms and the tool. The various metrics are also clearly and understandably prepared.[...]"',
      imageLink:
        'https://infludata.com/wp-content/uploads/influData-OMR-Top-Rated-Badge_02-2024.png',
    },
  ]

  return (
    <Modal keepMounted open={isOpened} onClose={onClose}>
      <Box sx={style}>
        <Stack direction="row" justifyContent="start">
          <Stack sx={{ width: isMobile ? '100%' : '50%', height: '100vh' }}>
            {isLoading && (
              <LoadingSpinner
                width={isMobile ? '100%' : '50vw'}
                height="100%"
                showOnFullPage
                isTransparent
                isOuterPageLoad
              />
            )}
            {isMobile && currentStep == 6 ? null : (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={isMobile ? 'center' : 'space-between'}
                sx={{ p: mediaQueryMaxHeight || isMobile ? '15px 80px' : '50px 80px' }}
              >
                <Box>
                  <Link href="/" underline="none">
                    <Box
                      sx={{
                        img: {
                          maxHeight: isMobile ? '70px' : '60px',
                        },
                      }}
                    >
                      <img src={PdfLogo} alt="InfluData" />
                    </Box>
                  </Link>
                </Box>
              </Stack>
            )}
            <Stack
              justifyContent="start"
              alignItems="center"
              sx={{ width: '100%', height: '100%', pt: isMobile ? 0 : 2 }}
            >
              {currentStep !== 1 ? (
                <Box sx={{ px: isMobile ? 4 : 10, width: '100%', height: '100%' }}>
                  {renderedJSX[currentStep]}
                </Box>
              ) : (
                <Stack
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    maxWidth: '400px',
                    width: '100%',
                    '& form': { marginTop: mediaQueryMaxHeight ? '0 !important' : 'inherit' },
                    px: isMobile ? 2 : 0,
                  }}
                >
                  <Stack alignItems="center">
                    <Typography
                      sx={{
                        fontFamily: `Lato, sans-serif`,
                        fontSize: '26px',
                        fontWeight: 600,
                        color: '#1F2937',
                      }}
                    >
                      {labelStrings.signupLocalization.letsGetUpYouAndRunning}
                    </Typography>
                    <Typography
                      sx={{ fontFamily: `Lato, sans-serif`, fontSize: '15px', color: '#6B7280' }}
                    >
                      {labelStrings.signupLocalization.thankYouForVerify}
                    </Typography>
                  </Stack>

                  <SignupStepTwoForm finishSignup={finishSignup} authMethod={authMethod} isSevenDaysTrial={isSevenDaysTrial} />
                </Stack>
              )}
            </Stack>
          </Stack>
          {!isMobile && (
            <Stack
              sx={{
                width: '50%',
                height: mediaQueryMaxHeight && currentStep === 6 ? '100%' : '100vh',
                background: `radial-gradient(circle at 55% 191%, rgb(215, 162, 45), rgb(215, 162, 45) 30%, rgb(215, 74, 71) 100%) 0% 0% no-repeat`,
                p: 2,
              }}
              justifyContent="center"
              alignItems="center"
            >
              {currentStep === 6 && calendlyLink ? (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: mediaQueryMaxHeight ? 'start' : 'inherit',
                  }}
                >
                  <SignUpSuccess isStepSignup />
                </Box>
              ) : (
                <>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: `Lato, sans-serif`,
                      fontSize: '19px',
                      color: '#FFF',
                      fontWeight: 600,
                      mb: 5,
                    }}
                  >
                    {labelStrings.signupLocalization.whatCustomersSaying}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex', // Use flexbox for layout
                      maxWidth: '500px', // Adjust overall width as needed
                      height: '290px',
                    }}
                  >
                    <Box
                      sx={{
                        flex: '1', // Flex grow for text area
                        padding: '0px', // Padding around text
                        color: 'white', // White text color
                      }}
                    >
                      <Typography
                        textAlign="left"
                        sx={{ fontFamily: `Lato, sans-serif`, fontSize: '16px', color: '#FFFFFF' }}
                      >
                        {referralsArray[currentStep - 1]?.text || referralsArray[0].text}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        maxWidth: '222px', // Smaller width for the image
                        maxHeight: '220px', // Smaller height for the image
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <img
                        src={
                          referralsArray[currentStep - 1]?.imageLink || referralsArray[0].imageLink
                        }
                        style={{
                          position: 'absolute',
                          maxWidth: '100%', // Max width to contain within the box
                          maxHeight: '100%', // Max height to contain within the box
                          top: '50%', // Center vertically
                          left: '50%', // Center horizontally
                          transform: 'translate(-50%, -50%)', // Adjust for centering
                          borderRadius: '6px',
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    textAlign="left"
                    sx={{
                      fontFamily: `Lato, sans-serif`,
                      fontSize: '15px',
                      color: '#FFFFFF',
                      fontWeight: 'bold',
                    }}
                  >
                    {referralsArray[currentStep - 1]?.name || referralsArray[0]?.name}
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Modal>
  )
}
