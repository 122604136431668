import React from 'react'
import Cookies from 'js-cookie'
import { withRouter } from "react-router-dom";
import { logoutUser } from '../redux/ducks/userDucks';
import { globalUseDispatch } from '../utils/globalDispatch';

const GrantsContext = React.createContext({});

function getCookie(cookieName) {
  let whoAmICookie = Cookies.get(cookieName) || "{}";
  whoAmICookie = whoAmICookie && JSON.parse(whoAmICookie);
  return whoAmICookie;
}

const logoutUserCb = () => {
  globalUseDispatch(logoutUser());
}

async function refreshGrants(cookieName, [state, setState], refresh = false, setLoggingOut) {
  const whoAmICookie = getCookie(cookieName);
  try {
    const grantsResp = await fetch(refresh ? '/api/profile/grants?refresh' : '/api/profile/grants', {
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    });

    if (grantsResp.status === 401) {
      console.error('Unauthorized access - logging out');
      if (!state.loggingOut) {
        setState(prevState => ({ ...prevState, loggingOut: true })); // Set logging out state to prevent further refresh attempts
        logoutUserCb();
      }
      return; 
    }

    if (grantsResp.ok) {
      const grants = await grantsResp.json();
      const newState = {
        ...state,
        ...whoAmICookie,
        roles: grants.roles || [],
        grants: grants.grants || [],
        props: grants.props || {},
        isValid: state.id === whoAmICookie.id,
        refresh: () => refreshGrants(cookieName, [state, setState], true, setLoggingOut),
        loggingOut: false
      };
      setState(newState);
    } else {
      console.error('Unauthorized access - logging out');
      logoutUserCb();
      return; 
    }
  } catch (err) {
    console.error('An error occurred:', err);
    if (!state.loggingOut) {
      setState(prevState => ({ ...prevState, loggingOut: true })); // Set logging out state to prevent further refresh attempts
      logoutUserCb();
    }
  }
}

function SessionGuard({ cookieName, loginPath, ignorePaths, children }) {
  if (cookieName == null || cookieName == '') {
    console.error('cookieName must be provided!')
    throw new Error('cookieName must be provided!')
  }

  let whoAmICookie = getCookie(cookieName);

  const initialState = Object.assign({}, whoAmICookie,
    {
      grants: [],
      roles: [],
      isValid: true,
      loggingOut: false
    })

  const [state, setState] = React.useState(initialState)
  const [loggingOut, setLoggingOut] = React.useState(false) // Track if we are in the process of logging out

  React.useEffect(() => {
    if (!state.loggingOut) {
      refreshGrants(cookieName, [state, setState], false, setLoggingOut)
    }
  }, [state.loggingOut])

  console.log("whoami:", cookieName, whoAmICookie, state, ignorePaths);

  if (state.isValid &&
    (window.location.pathname == loginPath
      || ignorePaths.reduce((res, path) => {
        return res || window.location.pathname.startsWith(path)
      }, false)
      || whoAmICookie.id)) {
    return <React.Fragment>
      <GrantsContext.Provider value={state}>
        {React.Children.toArray(children)}
      </GrantsContext.Provider>
    </React.Fragment>
  } else {
    console.log('auth needed, redirecting to login...');
    window.location.href = loginPath
    return null
  }
}

export { GrantsContext, SessionGuard };
export default withRouter(SessionGuard);
