import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@mui/material"
import ProfileNavMenu from "./ProfileNavMenu"
import useTranslation from "../../../../localization/useTranslation"
import {
  allCampaignsSelector,
  currentAudienceTokensSelector,
  userAudienceTokensSelector,
  userGrantSelector,
} from "../../../../redux/selectors"
import { logoutUser } from "../../../../redux/ducks/userDucks"
import { toggleSignUp } from "../../../../redux/ducks/appSettingsDucks"

const ProfileNavContainer = ({ isUserAuthorized }) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    package_advanced,
    enable_tracking,
    in_trial: isTrial,
  } = useSelector(userGrantSelector)
  const { isChangeInProgress: showAudienceTokensChange } = useSelector(
    currentAudienceTokensSelector,
  )
  const { audienceTokensUsed, audienceTokensTotal } = useSelector(
    userAudienceTokensSelector,
  )
  const { trackNow = 0 } = useSelector(allCampaignsSelector)

  const onLogoutClick = () => dispatch(logoutUser(history))
  const goToSettings = () => history.push("/settings")

  const allowTokensStats = !!package_advanced
  const showCampaignsTrackStats =
    enable_tracking && allowTokensStats && !!trackNow

  const openMenuManually = showAudienceTokensChange // add more conditions here if needed

  return isUserAuthorized ? (
    <ProfileNavMenu
      onLogoutClick={onLogoutClick}
      goToSettings={goToSettings}
      showTokensStats={
        allowTokensStats ||
        audienceTokensUsed !== 0 ||
        audienceTokensTotal !== 0
      }
      showCampaignsTrackStats={showCampaignsTrackStats}
      openMenuManually={openMenuManually}
      isTrial={isTrial}
    />
  ) : (
    <Button
      sx={{ backgroundColor: "#FAEBEB", color: "#6B4545" }}
      onClick={() => dispatch(toggleSignUp())}
    >
      {labelStrings.startTrial}
    </Button>
  )
}

export default ProfileNavContainer
