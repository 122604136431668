import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { shallowEqual, useSelector } from "react-redux"
import { Stack, Box } from "@mui/material"
import useTranslation from "../../../localization/useTranslation"
import { userGrantSelector } from "../../../redux/selectors"
import WithTip from "../../../components/common/popups/WithTip"
import AnalyzeInlinedNavbarContainer from "../../../components/analyzeBar/AnalyzeInlinedNavbarContainer"
import { NavbarLinkItem } from "./NavbarLinkItem"
import {
  NavbarCampaignsIcon,
  NavbarCollectionsIcon,
  NavbarLookaLikeIcon,
  NavbarInfludataIcon,
  NavbarMessageIcon,
  NavbarSearchIcon,
} from "./navbarIcons"
import { DisabledTipWrapper } from "../../../components/common/tabs/tabsStyles"
import NavBarUserRelated from "./NavbarUserRelated"
import ReactivationPlanContainer from "../../../components/appUserSettings/pricingPlans/ReactivationPlanContainer"
import CompareProfilesDynamicNavItem from "./CompareProfilesDynamicNavItem"

export const NavbarLinks = ({
  isUserAuthorized,
  allowCollections,
  allowTracking,
  onLoginClick,
  currentPagePath,
}) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()

  const {
    enable_collections,
    enable_ig_search,
    enable_tt_search,
    enable_yt_search,
    build_lookalikes,
    no_plan,
    ...otherGrants
  } = useSelector(userGrantSelector, shallowEqual)

  const disabledSearch =
    !enable_ig_search && !enable_tt_search && !enable_yt_search
  const disabledCollection = !enable_collections

  useEffect(() => {
    if (
      disabledSearch &&
      disabledCollection &&
      Object.keys(otherGrants)?.length
    ) {
      history.push("/campaigns")
    }
  }, [Object.keys(otherGrants)?.length])

  return (
    <Box id='navbar' sx={{ width: "100%" }}>
      <ReactivationPlanContainer />
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <Box sx={{ width: "100%", ml: "58px" }}>
          <Link to='/'>
            <NavbarInfludataIcon />
          </Link>
        </Box>

        <Stack
          direction='row'
          alignItems='center'
          spacing={3}
          sx={{
            pointerEvents: !isUserAuthorized || no_plan ? "none" : "auto",
            opacity: !isUserAuthorized || no_plan ? 0.4 : 1,
          }}
        >
          <NavbarLinkItem
            linkPath={"search"}
            Icon={NavbarSearchIcon}
            isActive={currentPagePath?.includes("search")}
            isDisabled={disabledSearch || no_plan}
            hoverText={"Search"}
            disableRipple
          />

          {build_lookalikes ? (
            <NavbarLinkItem
              linkPath={"explore"}
              Icon={NavbarLookaLikeIcon}
              isActive={currentPagePath?.includes("explore")}
              isDisabled={!isUserAuthorized || !build_lookalikes || no_plan}
              hoverText={"Look-A-Likes"}
              disableRipple
            />
          ) : (
            <WithTip
              tipText={
                <DisabledTipWrapper>
                  {labelStrings.featureIsNotAvailable}
                </DisabledTipWrapper>
              }
              userindex={"instagram"}
              position='bottom left'
            >
              <div>
                <NavbarLinkItem
                  linkPath={"explore"}
                  Icon={NavbarLookaLikeIcon}
                  isActive={currentPagePath?.includes("explore")}
                  isDisabled={!isUserAuthorized || !build_lookalikes || no_plan}
                  hoverText={"Look-A-Likes"}
                  disableRipple
                />
              </div>
            </WithTip>
          )}

          {!enable_collections && isUserAuthorized ? (
            <WithTip
              tipText={labelStrings.collectionDisabledTip}
              position='bottom center'
            >
              <div>
                <NavbarLinkItem
                  Icon={NavbarCollectionsIcon}
                  linkPath={"collections/fav"}
                  withSignup={!allowCollections && !isUserAuthorized}
                  signupTipText={labelStrings.useCollections}
                  isActive={currentPagePath?.includes("collections")}
                  isDisabled={!isUserAuthorized || !enable_collections || no_plan}
                  hoverText={"Collections"}
                  disableRipple
                />
              </div>
            </WithTip>
          ) : (
            <NavbarLinkItem
              Icon={NavbarCollectionsIcon}
              linkPath={"collections/fav"}
              withSignup={!allowCollections && !isUserAuthorized}
              signupTipText={labelStrings.useCollections}
              isActive={currentPagePath?.includes("collections")}
              isDisabled={!isUserAuthorized || disabledCollection || no_plan}
              hoverText={"Collections"}
              disableRipple
            />
          )}

          <NavbarLinkItem
            Icon={NavbarCampaignsIcon}
            linkPath={"campaigns"}
            withSignup={!allowTracking && !isUserAuthorized}
            signupTipText={labelStrings.useTracking}
            isActive={currentPagePath?.includes("campaigns")}
            isDisabled={!isUserAuthorized || no_plan}
            hoverText={"Campaigns"}
            disableRipple
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          sx={{ width: "100%", position: "relative" }}
          justifyContent='end'
        >
          <Box
            sx={{
              mr: 1,
              pointerEvents: !isUserAuthorized || no_plan ? "none" : "auto",
              opacity: !isUserAuthorized || no_plan ? 0.4 : 1,
            }}
          >
            <AnalyzeInlinedNavbarContainer />
          </Box>

          {/* <ToolsForBrandsWrapper>
              <NavbarLinkItem
                Icon={BuildIcon}
                linkPath={'tools-for-brands'}
                withSignup={!isUserAuthorized}
                signupTipText={labelStrings.useToolsForBrands}
                isActive={currentPagePath.includes('tools-for-brands')}
              />
            </ToolsForBrandsWrapper> */}

          <NavBarUserRelated
            onLoginClick={onLoginClick}
            isUserAuthorized={isUserAuthorized}
          />

          <CompareProfilesDynamicNavItem
            isUserAuthorized={isUserAuthorized}
            currentPagePath={currentPagePath}
          />

          {false && isUserAuthorized && (
            <NavbarLinkItem
              Icon={NavbarMessageIcon}
              linkPath={"Messages"}
              withSignup={!isUserAuthorized}
              signupTipText={``}
              isActive={currentPagePath?.includes("messages")}
              isMinWidth
              disableRipple
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
