import httpService from '../../services/httpService'
import {
  setCollectionsData,
  setOpenedCollection,
  changeLoadingCollectionsStatus,
  setCollectionInitSorting,
  sortUsersInOpenedCollection,
} from './collectionsDucks'
import { setAllCampaigns, changeCampaignsLoadingStatus, fetchCampaign } from './campaignsDucks'
import { getAvailableCountriesCities, setNewsContentObjectModal } from './appSettingsDucks'
import {
  setUserStartUpDataPresent,
  toggleUserStartUpDataLoading,
  changeAudienceTokensCount,
  changeAudienceTokensTotal,
  changeAudienceTokensResetDate,
  changeUserUsedSeats,
  changeHubspotToken,
  changeAzureAdData,
  changeSearchResultsAndProfilesUsed,
} from './userDucks'
import { fetchProfileById, handleProfileToken, fetchSimilarAccounts, setCpmAdjustments, toggleLoadingProfileAction } from './profileSelectedDucks'
import {
  getAdditionalFieldsPending,
  getAdditionalFieldsSucceeded,
  getAdditionalFieldsFailed,
} from './additionalFieldsDucks'
import {
  isUserAuthenticatedSelector,
  userGrantSelector,
  isUserStartupDataPresentSelector,
  selectedProfileDetailsSelector,
  // currentOpenedCampaignSelector,
  isProfileLoadingSelector,
  userStartupDataLoadingSelector,
  lastPageBeforeProfileRedirectSelector,
} from '../selectors'

import { setError } from './errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'
import { FAV_COLLECTION_NAME } from '../../constants/collections'
import { setRecentSearchArray, setSuggestionsArray } from './exploreDucks'

// main startup action
export const fetchStartUpData = ({
  collectionId = '',
  collectionPage = 1,
  profileId = '',
  campaignId = '',
}) => async (dispatch, getState) => {
  const isUserAuthenticated = isUserAuthenticatedSelector(getState())
  const { package_free } = userGrantSelector(getState())
  if (isUserAuthenticated && !package_free) {
    dispatch(getAvailableCountriesCities())
  }

  if (profileId) {
    // profile fetch can be for not logged-in users
    const currentProfile = selectedProfileDetailsSelector(getState())
    const isLoadingProfile = isProfileLoadingSelector(getState())
    const source = lastPageBeforeProfileRedirectSelector(getState())
    const isSameProfile = !!currentProfile && currentProfile._id === profileId
    if (!isLoadingProfile) {
      if (!currentProfile || currentProfile._id !== profileId) {
        dispatch(fetchProfileById({ profileId, source }))
      } else if (
        isUserAuthenticated &&
        isSameProfile &&
        currentProfile.secToken &&
        !currentProfile.audienceAnalysis
      ) {
        // this case is when we loggin to pro user from already opened profile
        // we need to fetch again to get audience analysis, if we have secToken but no analysis yet
        dispatch(fetchProfileById({ profileId, source }))
      } else if (isSameProfile) {
        dispatch(handleProfileToken()) // check profile token and put it to url
        if (isUserAuthenticated) {
          dispatch(fetchSimilarAccounts()) // fetch similar accounts when users logs in from profile page
        }
      }
    }
  }

  if (!isUserAuthenticated) return

  const { userCollections } = getState()
  const isStartUpDataPresent = isUserStartupDataPresentSelector(getState())
  const isStartUpDataLoading = userStartupDataLoadingSelector(getState())

  if (collectionId) dispatch(setCollectionInitSorting(collectionId))

  if (isStartUpDataPresent) {
    if (collectionId && collectionId !== userCollections.openedCollection.id) {
      dispatch(startUpGetCollectionAction({ collectionId, collectionPage }))
    }
  } else {
    if (!isStartUpDataLoading) {
      dispatch(
        startUpGetFullData({
          collectionId: collectionId || FAV_COLLECTION_NAME,
          collectionPage,
        })
      )
    }
  }

  // if (campaignId && enable_tracking) {
  //   const { campaignId: currentCamapaignId } = currentOpenedCampaignSelector(getState())
  //   if (!currentCamapaignId || currentCamapaignId !== campaignId) {
  //     dispatch(fetchCampaign(campaignId))
  //   }
  // }
}

// single startup actions
export const startUpGetCollectionAction = ({ collectionId, collectionPage }) => async dispatch => {
  try {
    dispatch(changeLoadingCollectionsStatus())
    const users = await httpService.fetchCollection(collectionId, collectionPage)
    const { userdata, metadata, contentData } = users
    dispatch([
      setOpenedCollection({
        collectionId,
        users: {
          userdata: dispatch(sortUsersInOpenedCollection({ userdata })),
          metadata,
          contentData,
        },
      }),
      changeLoadingCollectionsStatus(),
    ])
  } catch (err) {
    dispatch(setError(ERROR_MSG.failLoadCollections))
  }
}

export const startUpGetFullData = ({ collectionId, collectionPage, withCpmSetting = false }) => async (dispatch, getState) => {
  dispatch([
    toggleUserStartUpDataLoading(),
    changeLoadingCollectionsStatus(),
    changeCampaignsLoadingStatus(),
    getAdditionalFieldsPending(),
  ])

  const { no_plan } = userGrantSelector(getState())

  if (no_plan) return

  try {
    const {
      collectionArray: collectionsList = [],
      openCollection = '',
      campaignData = {},
      data: openedCollectionUsers = {
        userdata: [],
        metadata: {},
      },
      additionalFields,
      audienceTokensUsed = 0,
      audienceTokensTotal = 0,
      tokensResetDate = Date.now(),
      hubspotToken = '',
      subUsers = [],
      cpmAdjustments,
      newsModalContentObject,
      suggestedCreatorsArray = [],
      recentSearchArray = [],
      azureSameTenantEnforced,
      azureTenantId,
      searchResultsAndProfilesUsed
    } = await httpService.fetchStartUpData({ collectionId, collectionPage })
    const collectionsPayload = {
      collectionsList,
      openCollection,
      data: {
        userdata: dispatch(
          sortUsersInOpenedCollection({ userdata: openedCollectionUsers.userdata })
        ),
        metadata: openedCollectionUsers.metadata,
        contentData: openedCollectionUsers.contentData,
      },
    }

    dispatch([
      setCollectionsData(collectionsPayload),
      getAdditionalFieldsSucceeded(additionalFields),
      setAllCampaigns({ ...campaignData }),
      changeAudienceTokensCount(audienceTokensUsed),
      changeAudienceTokensTotal(audienceTokensTotal),
      changeHubspotToken(hubspotToken),
      changeAudienceTokensResetDate(tokensResetDate),
      changeUserUsedSeats(subUsers),
      setCpmAdjustments(cpmAdjustments),
      setUserStartUpDataPresent(),
      toggleUserStartUpDataLoading(),
      changeLoadingCollectionsStatus(),
      changeCampaignsLoadingStatus(),
      setNewsContentObjectModal(newsModalContentObject),
      setSuggestionsArray(suggestedCreatorsArray),
      setRecentSearchArray(recentSearchArray),
      changeAzureAdData({
        azureSameTenantEnforced,
        azureTenantId
      }),
      searchResultsAndProfilesUsed && changeSearchResultsAndProfilesUsed(searchResultsAndProfilesUsed)
    ])

    if(cpmAdjustments && withCpmSetting) {
      dispatch(toggleLoadingProfileAction())
    }

  } catch (err) {
    dispatch([
      toggleUserStartUpDataLoading(),
      changeLoadingCollectionsStatus(),
      changeCampaignsLoadingStatus(),
      setError(ERROR_MSG.serverNotRespond),
      getAdditionalFieldsFailed(),
    ])
  }
}
