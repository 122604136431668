import { useState, useEffect } from "react";

const useLocalIP = () => {
  const [ip, setIp] = useState(null);

  useEffect(() => {
    const getLocalIP = async () => {
      try {
        const peerConnection = new RTCPeerConnection({
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }]
        });

        peerConnection.createDataChannel("");
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        peerConnection.onicecandidate = (event) => {
          if (event?.candidate?.candidate) {
            const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}/;
            const ipMatch = event.candidate.candidate.match(ipRegex);
            if (ipMatch) {
              setIp(ipMatch[0]);
              peerConnection.close();
            }
          }
        };
      } catch (error) {
        console.error("Error fetching local IP:", error);
      }
    };

    getLocalIP();
  }, []);

  return ip;
};

export default useLocalIP;
