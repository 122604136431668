import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useTranslation from '../../../../../localization/useTranslation'
import WithUpgradeTip from '../../../../../components/common/popups/WithUpgradeTip'
import WithTip from '../../../../../components/common/popups/WithTip'
import { BlockUserIcon } from '../../../../../components/common/icons'

const IconWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock};
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
  ._icon-container {
    margin: 7.5px;
  }
`
const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.color.black};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.black};
    opacity: 0.9;
  }
`

const ProfileBlackListTool = ({
  isBlack = false,
  userindex = '',
  userId = '',
  onBlockUnblockUser,
  isUserAuthenticated = false,
  enableBlackList = false,
  isQuickPreview
}) => {
  const { labelStrings, currentLang } = useTranslation()

  const TipComponent = useMemo(
    () => (
      <>
        {enableBlackList ? (
          <div>
            <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
            {labelStrings.to} {labelStrings.addUserToBlackListTip}
          </div>
        ) : isBlack ? (
          <div>{labelStrings.removeFromBlackList}</div>
        ) : (
          <div>
            {labelStrings.blockUserTipsFirstPart}
            <br />
            <Link to="/collections/blacklist">
              <u>{labelStrings.blockUserTipsLastPart}</u>
            </Link>
          </div>
        )}
      </>
    ),
    [currentLang, isBlack, enableBlackList]
  )

  return (
    <div onClick={(e) => onBlockUnblockUser({ e, profileId: userId, isBlack })}>
      {!isUserAuthenticated ? (
        <WithUpgradeTip
          tipText={labelStrings.addMoreUsers}
          userindex={userindex}
          withSignup={!isUserAuthenticated}
          position={isQuickPreview ? 'bottom center' : 'top center'}
          arrow={isQuickPreview ? false : true}
        >
          <BlockUserIcon userindex={userindex} />
        </WithUpgradeTip>
      ) : (
        <WithTip TipComponent={TipComponent} arrow={isQuickPreview ? false : true} position={isQuickPreview ? 'bottom center' : 'top center'} userindex={userindex}>
          <span>
            <IconWrapper disabled={enableBlackList}>
              <BlockUserIcon userindex={userindex} isActiveIcon={isBlack} />
            </IconWrapper>
          </span>
        </WithTip>
      )}
    </div>
  )
}

export default ProfileBlackListTool
