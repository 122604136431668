import React from "react"
import PropTypes from "prop-types"
import useTranslation from "../../localization/useTranslation"
import { Paper, ListItemButton, List, ListItemText, Stack } from "@mui/material"
import Scrollbar from "../../new-ui/components/scrollbar/Scrollbar"
import { StyledSkeleton } from "../collections/CollectionOverlap/YourOverlaps/CreateOverlap/styles"

const LocationList = ({
  onLocationSelect,
  locationsList = [],
  userindex,
  suggestedLocationStaticIdPart,
  searchLocationLoading,
  showLocationsList,
  selectedLocation
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const locationsFound = !!locationsList?.length

  const onHandleClickLocation = ({ id, name }) => {
    onLocationSelect(id, name)
  }

  return (
    <Paper
      sx={{
        maxWidth: "376px",
        maxHeight: "336px",
        width: "100%",
        position: "absolute",
        zIndex: 999999,
        top: 5,
        padding: 0,
        visibility: showLocationsList ? "visible" : "hidden",
      }}
    >
      {searchLocationLoading && (
        <Stack spacing={1} sx={{ padding: 2, height: "336px" }}>
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
          <StyledSkeleton
            animation='wave'
            variant='rectangular'
            width='100%'
            height={40}
          />
        </Stack>
      )}
      <List>
        <Scrollbar sx={{ maxHeight: "236px", padding: 1 }}>
          {locationsFound
            ? locationsList?.map((location, idx) => {
                return (
                  <ListItemButton
                    sx={{ backgroundColor: selectedLocation === location.description ? '#FBEBEB' : 'inherit', borderRadius: '8px' }}
                    key={location.place_id + idx}
                    onClick={() =>
                      onHandleClickLocation({
                        id: location.place_id,
                        name: location.description,
                      })
                    }
                    id={`${suggestedLocationStaticIdPart}${location.place_id}${idx}`}
                  >
                    <ListItemText>{location.description}</ListItemText>
                  </ListItemButton>
                )
              })
            : !searchLocationLoading && (
                <ListItemText sx={{ textAlign: "center", color: '#784E4E' }}>
                  {labelStrings.noLocationFound}
                </ListItemText>
              )}
        </Scrollbar>
      </List>
    </Paper>
  )
}

LocationList.propTypes = {
  locationsList: PropTypes.array,
  onLocationSelect: PropTypes.func,
  userindex: PropTypes.string,
  suggestedLocationStaticIdPart: PropTypes.string,
  isInputValue: PropTypes.bool,
  disableSearch: PropTypes.bool,
  searchLocationLoading: PropTypes.bool,
  showLocationsList: PropTypes.bool,
  selectedLocation: PropTypes.string
}

export default LocationList
