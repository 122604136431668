import React, { useEffect } from 'react'
import { Stack, Box } from '@mui/material'
import { Sidebar } from './Sidebar/Sidebar'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { startUpGetFullData } from '../../../redux/ducks/startupActions'
import { useParams, useHistory } from 'react-router-dom'
import { FAV_COLLECTION_NAME } from '../../../constants/collections'
import { Layout } from '../../../components/common/styledWrappers'
import { EXPLORE_VIEW_TYPE, NAVBAR_FOOTER_SUM_HEIGHT } from '../../../constants/appSettings'
import { useState } from 'react'
import { SuggestionsView } from './SuggestionsView'
import { RecentSearchView } from './RecentSearchView'
import {
  currentExploreListDataSelector,
  isUserAuthenticatedSelector,
  suggestedCreatorsArraySelector,
  userCredsSelector,
  userGrantSelector,
  userStartupDataLoadingSelector,
} from '../../../redux/selectors'
import {
  changeSearchType,
  clearSearch,
  fetchSearchResults,
  setSearchResults,
} from '../../../redux/ducks/searchDucks'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import { searchContentTypes } from '../../../constants/search'
import {
  buildLookalike,
  changeCurrentExploreListData,
  changeSuggestionSelectedData,
  deleteLookalikeList,
  getSuggestedUsersById,
} from '../../../redux/ducks/exploreDucks'
import { LookalikeAiModal } from './LookalikeAi/LookalikeAiModal'
import { DeleteSuggestionsListModal } from './LookalikeAi/DeleteListModal'
import { PlanInactiveModal } from '../../components/no-plan-modal/PlanInactiveModal'
import { NoPlanModal } from '../../components/no-plan-modal/NoPlanModal'

const Explore = () => {
  const { collectionId = '', suggestionsListId = '' } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const isUserStartUpDataLoading = useSelector(userStartupDataLoadingSelector)
  const currentSelectedData = useSelector(currentExploreListDataSelector)
  const suggestedCreatorsArray = useSelector(suggestedCreatorsArraySelector)

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { no_plan, is_self_service } = useSelector(userGrantSelector, shallowEqual)
  const { showNoPlanModal } = useSelector(userCredsSelector) || {}

  const [openModalLookalikeAi, setOpenModalLookalikeAi] = useState(false)
  const [openDeleteSuggestionsListModal, setOpenDeleteSuggestionsListModal] = useState({ id: '' })

  const [exploreViewType, setExploreViewType] = useState(EXPLORE_VIEW_TYPE.suggestions)

  const [dataFromLookalike, setDataFromLookalike] = useState({
    userList: [],
    platform: '',
  })

  const isFoundSuggestionsList = suggestedCreatorsArray?.find(el => el.id === suggestionsListId)

  const changeSelectedData = data => {
    dispatch(changeCurrentExploreListData(data))
  }

  const onChangeExploreViewType = type => {
    // @ts-ignore
    dispatch([
      changeCurrentExploreListData({}),
      changeSuggestionSelectedData({ isLoading: false }),
      setSearchResults({
        results: {
          users: [],
          content: [],
          count: 0,
          dataSuccess: {},
        },
      }),
    ])
    setExploreViewType(type)
    history?.replace('/explore')
  }

  useEffect(() => {
    // @ts-ignore
    dispatch([
      clearSearch(),
      changeSearchType(searchContentTypes.CREATOR),
      startUpGetFullData({
        collectionId: collectionId || FAV_COLLECTION_NAME,
        collectionPage: 1,
      }),
    ])
    setOpenDeleteSuggestionsListModal({ id: '' })
  }, [])

  useEffect(() => {
    if (suggestionsListId && isFoundSuggestionsList?.id && !currentSelectedData?.id) {
      onHandleGetSuggestedUsersById({ id: suggestionsListId })
    }
  }, [suggestionsListId, isFoundSuggestionsList, currentSelectedData])

  const onHandleGetSuggestedUsersById = ({ id }) => {
    // @ts-ignore
    dispatch(getSuggestedUsersById({ queryId: id }))
  }

  const onHandleRecentSearchResults = ({ query }) => {
    // @ts-ignore
    dispatch(fetchSearchResults(query))
  }

  const onHandleConfirmLookalike = ({ label }) => {
    dispatch(
      // @ts-ignore
      buildLookalike({
        ...dataFromLookalike,
        label,
        userList: [...new Set(dataFromLookalike.userList)],
      })
    )
  }

  const onOpenModalLookalikeAi = () => {
    setOpenModalLookalikeAi(true)
  }

  const onCloseModalLookalikeAi = () => {
    setOpenModalLookalikeAi(false)
  }

  const onOpenModalDeleteSuggestionsList = ({ id }) => {
    setOpenDeleteSuggestionsListModal({ id })
  }

  const onCloseModalDeleteSuggestionsList = () => {
    setOpenDeleteSuggestionsListModal({ id: '' })
  }

  const onDeleteSuggestionList = () => {
    // @ts-ignore
    dispatch(deleteLookalikeList({ id: openDeleteSuggestionsListModal?.id }))
    history?.replace('/explore')
    onCloseModalDeleteSuggestionsList()
  }

  if (no_plan && isUserAuthenticated) {
    return showNoPlanModal ? (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }} />
    ) : (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }}>
        {is_self_service ? <NoPlanModal /> : <PlanInactiveModal />}
      </Box>
    );
  }

  return (
    <>
      {isUserStartUpDataLoading ? (
        <LoadingSpinner showOnFullPage />
      ) : (
        <Layout>
          <Sidebar
            exploreViewType={exploreViewType}
            onChangeExploreViewType={onChangeExploreViewType}
            selectedData={currentSelectedData}
            setSelectedData={changeSelectedData}
            onHandleGetSuggestedUsersById={onHandleGetSuggestedUsersById}
            onHandleRecentSearchResults={onHandleRecentSearchResults}
            onOpenModalLookalikeAi={onOpenModalLookalikeAi}
            onOpenModalDeleteSuggestionsList={onOpenModalDeleteSuggestionsList}
          />
          <Stack sx={{ p: 1, width: '100%' }}>
            {exploreViewType === EXPLORE_VIEW_TYPE.suggestions && (
              <SuggestionsView
                data={currentSelectedData?.id ? currentSelectedData : isFoundSuggestionsList}
                isFoundSuggestionsList={isFoundSuggestionsList?.id}
              />
            )}
            {exploreViewType === EXPLORE_VIEW_TYPE.recentSearches && (
              <RecentSearchView
                currQuery={currentSelectedData?.query}
                changeQuery={changeSelectedData}
              />
            )}
          </Stack>
        </Layout>
      )}
      <LookalikeAiModal
        openModalLookalikeAi={openModalLookalikeAi}
        onCloseModalLookalikeAi={onCloseModalLookalikeAi}
        onHandleConfirmLookalike={onHandleConfirmLookalike}
        setDataFromLookalike={setDataFromLookalike}
      />
      <DeleteSuggestionsListModal
        openDeleteSuggestionsListModal={!!openDeleteSuggestionsListModal?.id}
        onCloseDeleteSuggestionsModal={onCloseModalDeleteSuggestionsList}
        onDeleteSuggestionList={onDeleteSuggestionList}
      />
    </>
  )
}

export default Explore
