  import { useEffect, useState } from "react";
import httpService from "../services/httpService";

export const useGeoLocation = ({ ip }) => {
  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    if(ip) {
      localStorage.removeItem('currentCountryFromIP');
      getLocation();
    }
  }, [ip]);

  async function getLocation() {
    const locationData = await httpService.getLocationByIp({ ip });
    if (locationData) {
      setLocationData(locationData);
      localStorage.setItem('currentCountryFromIP', locationData.country);
    }
  }

  return {
    city: locationData?.city || "",
    country: locationData?.country_name || "",
    countryCode: locationData?.country_code || 0,
    lat: locationData?.latitude || "",
    lon: locationData?.longitude || "",
    region: locationData?.region || "",
    regionCode: locationData?.region_code || "",
    timezone: locationData?.timezone || "",
    zip: locationData?.postal || 0,
  };
};
