import React from "react"
import { Stack } from "@mui/material"
import GoogleLocationSearchContainer from "../../../../../components/googleLocationByLetters/GoogleLocationSearchContainer"

export const LocationFilter = (props) => {
  const { selectedPlatform, query, handlersMap } = props || {}

  return (
    <Stack sx={{ height: '310px', width: '300px' }}>
      <GoogleLocationSearchContainer changeLocation={handlersMap.location} />
    </Stack>
  )
}
