import React from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from "@mui/material"
import { radiusSelectOptions } from "../utils"

export const LocationRadiusFilter = (props) => {
  const { selectedPlatform, query, handlersMap } = props || {}

  const { labelStrings } = useTranslation()

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {}
    handlersMap?.radiusKM(value)
  }

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.locationRadius}
        </InputLabel>
        <Select
          size='small'
          value={query?.radiusKM ? query?.radiusKM : ""}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.selectLanguage} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
        >
          {radiusSelectOptions?.map((el) => (
            <MenuItem key={el?.value} value={el?.value}>
              {el?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
