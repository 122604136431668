import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { changeAppSocialPlatform } from "../../../redux/ducks/appSettingsDucks";
import {
  SOCIAL_PLATFORMS_NAMES,
  USER_INDEXES,
} from "../../../constants/appSettings";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  searchResultsPresentSelector,
  userGrantSelector,
} from "../../../redux/selectors";
import useTranslation from "../../../localization/useTranslation";
import { TabDisabled, WidthMaxWrapper } from "./tabsStyles";
import { Stack, Button, styled } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { bgGradients } from "../../../new-ui/components/nav-section/CreatorsSuggestions/styles";
import { TikTokSwitchIcon } from "../../../new-ui/components/add-creator/icons";
import { updateSearch } from "../../../redux/ducks/searchDucks";
import { searchContentTypes } from "../../../constants/search";

export const SocialPlatformBtn = styled(Button)(({ isSelected, ownIndex }) => ({
  color: isSelected ? "#FFFFFF" : "#442424",
  svg: {
    fontSize: "20px",
    path: {
      fill: isSelected ? "#FFFFFF" : "#442424",
    },
  },
  background: isSelected ? bgGradients[ownIndex?.toUpperCase()] : "#F6DBDB",
  "&:hover": {
    background: bgGradients[ownIndex?.toUpperCase()],
    svg: {
      fill: "#FFFFFF",
      filter: "brightness(95%)",
      path: {
        fill: "#FFFFFF",
      },
    },
  },
  width: "100%",
  minWidth: "60px !important",
  height: "30px",
  "&:disabled": {
    opacity: 0.5,
    color: "#442424",
    backgroundColor: "#F6DBDB",
    background: "#F6DBDB",
    svg: {
      path: {
        fill: "#442424",
      },
    },
  },
}));

const SocialPlatformTabs = ({ userindex = '', searchType }) => {
  const { labelStrings } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const platformIndex = userindex || USER_INDEXES.instagram;

  const { enable_tt_search, enable_ig_search, enable_yt_search } = useSelector(
    userGrantSelector,
    shallowEqual,
  );
  const searchUsersLength = useSelector(searchResultsPresentSelector);

  const searchGrants = {
    [SOCIAL_PLATFORMS_NAMES.instagram.name]: enable_ig_search,
    [SOCIAL_PLATFORMS_NAMES.tiktok.name]: enable_tt_search,
    [SOCIAL_PLATFORMS_NAMES.youtube.name]: enable_yt_search,
  };

  const toggleSocialPlatform = (newPlatformName) => () => {
    dispatch([
      changeAppSocialPlatform(newPlatformName),
      updateSearch({
        newValuesObj: {
          index: newPlatformName?.toLowerCase(),
          isSimilarContentPieces: false,
          skipCount: 0
        },
      }),
    ]);
  };

  useEffect(() => {
    const availableSearchGrants = Object.keys(searchGrants)?.find(
      (i) => searchGrants[i] === true,
    );

    if (availableSearchGrants && !searchUsersLength) {
      dispatch(changeAppSocialPlatform(availableSearchGrants, history));
    }
  }, []);

  const TabsData = [
    {
      onClick: toggleSocialPlatform(SOCIAL_PLATFORMS_NAMES.instagram.name),
      isSelected: platformIndex === USER_INDEXES.instagram,
      ownIndex: USER_INDEXES.instagram,
      isDisabled: !enable_ig_search,
      name: SOCIAL_PLATFORMS_NAMES.instagram.name,
      tipText: labelStrings.socialPlatformDisabledTip,
      icon: <InstagramIcon />,
    },
    {
      onClick: toggleSocialPlatform(SOCIAL_PLATFORMS_NAMES.tiktok.name),
      isSelected: platformIndex === USER_INDEXES.tiktok,
      ownIndex: USER_INDEXES.tiktok,
      isDisabled: !enable_tt_search,
      name: SOCIAL_PLATFORMS_NAMES.tiktok.name,
      tipText: labelStrings.socialPlatformDisabledTip,
      icon: <TikTokSwitchIcon />,
    },
    {
      onClick: toggleSocialPlatform(SOCIAL_PLATFORMS_NAMES.youtube.name),
      isSelected: platformIndex === USER_INDEXES.youtube,
      ownIndex: USER_INDEXES.youtube,
      isDisabled: !enable_yt_search,
      name: SOCIAL_PLATFORMS_NAMES.youtube.name,
      tipText: labelStrings.socialPlatformDisabledTip,
      icon: <YouTubeIcon />,
    },
  ];

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {TabsData?.map((el) => {
        if (
          searchType === searchContentTypes.BRAND &&
          el.name === SOCIAL_PLATFORMS_NAMES.youtube.name
        )
          return;

        return el.isDisabled ? (
          <WidthMaxWrapper key={el.ownIndex}>
            <Tooltip
              userindex={userindex}
              title={el.tipText}
              arrow
              placement='bottom-start'
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "250px",
                    fontSize: "13px",
                    fontFamily: "Inter",
                    p: 1,
                    px: 2,
                    backgroundColor: "#F6DBDB",
                    color: "black",
                    "& .MuiTooltip-arrow": {
                      color: "#F6DBDB",
                    },
                  },
                },
              }}
            >
              <TabDisabled>
                <SocialPlatformBtn
                  onClick={el.onClick}
                  isSelected={el.isSelected}
                  ownIndex={el.ownIndex}
                  disabled={el.isDisabled}
                >
                  {el.icon}
                </SocialPlatformBtn>
              </TabDisabled>
            </Tooltip>
          </WidthMaxWrapper>
        ) : (
          <WidthMaxWrapper key={el.ownIndex}>
            <SocialPlatformBtn
              onClick={el.onClick}
              userindex={el.userindex}
              isSelected={el.isSelected}
              ownIndex={el.ownIndex}
              isDisabled={el.isDisabled}
            >
              {el.icon}
            </SocialPlatformBtn>
          </WidthMaxWrapper>
        );
      })}
    </Stack>
  );
};

export default SocialPlatformTabs;
