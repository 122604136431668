import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import LocationList from "./LocationList"
import {
  isUserAuthenticatedSelector,
  searchDataSelector,
} from "../../redux/selectors"
import { useDispatch, useSelector } from "react-redux"
import {
  getGoogleLocationByLetters,
  setSearchLocationData,
} from "../../redux/ducks/searchDucks"
import useDebounce from "../../hooks/useDebounce"
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material"
import ClearRoundedIcon from "@mui/icons-material/ClearRounded"
import useOutsideClick from "../../hooks/useOutsideClick"

export const SuggestedLocationsContainer = ({
  selectLocationNextAction,
  userindex,
  placeholderText,
  closeOnLocationSelect,
  resetOnLocationSelect,
  id,
}) => {
  const dispatch = useDispatch()
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { searchLocation, searchLocationLoading } =
    useSelector(searchDataSelector)
  const disabledSearch = !isUserAuthenticated //|| !enable_username_search

  const suggestedUserStaticIdPart = `suggested_`

  const containerRef = useRef(null)

  const defaultState = {
    suggestedLocations: [],
    inputValue: "",
  }

  const [inputValue, changeInputValue] = useState(defaultState.inputValue)
  const [suggestedLocations, changeSuggestedLocations] = useState(
    defaultState.suggestedLocations,
  )
  const [selectedLocation, setSelectedLocation] = useState("")
  const debouncedInputValue = useDebounce(inputValue, 300)

  const [showLocationsList, setShowLocationsList] = useState(false)
  useOutsideClick({
    ref: containerRef,
    callback: () => setShowLocationsList(false),
  })

  const onChange = async ({ target: { value } }) => {
    changeInputValue(
      !value && suggestedLocations?.length ? defaultState.inputValue : value,
    )
  }

  useEffect(() => {
    if (!debouncedInputValue && suggestedLocations?.length) {
      changeSuggestedLocations(defaultState.suggestedLocations)
    }
    if (debouncedInputValue?.length >= 4 && !disabledSearch) {
      dispatch(getGoogleLocationByLetters({ letters: debouncedInputValue }))
    }
  }, [debouncedInputValue, disabledSearch])

  const clearInputHandler = () => {
    changeInputValue(defaultState.inputValue)
    selectLocationNextAction(defaultState.selectedLocation)
    changeSuggestedLocations(defaultState.suggestedLocations)
    dispatch(setSearchLocationData([]))
    setShowLocationsList(false)
    setSelectedLocation("")
  }

  const locationSelectClickHandler = (selectedId, locationName) => {
    resetOnLocationSelect &&
      changeSuggestedLocations(defaultState.suggestedLocations)
    closeOnLocationSelect && changeInputValue(defaultState.inputValue)
    if (selectedId) {
      selectLocationNextAction(selectedId)
    }
    setSelectedLocation(locationName)
    setShowLocationsList(false)
  }

  const onFocus = () => {
    if (!showLocationsList) setShowLocationsList(true)
  }

  return (
    <Stack ref={containerRef} sx={{ width: "100%", height: "100%" }}>
      <TextField
        autoFocus
        sx={{
          "& .MuiInputBase-root": {
            fontWeight: "bold",
            color: "#784E4E",
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#784E4E",
            },
          },
          "& .MuiFormLabel-root": {
            fontWeight: "bold",
            color: "#784E4E",
            "&.Mui-focused": { color: "#784E4E" },
          },
          fieldset: { borderColor: "#784E4E" },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#784E4E",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#784E4E",
            },
          },
        }}
        disabled={disabledSearch}
        size='small'
        id={id}
        value={showLocationsList ? inputValue : selectedLocation}
        onChange={onChange}
        onFocus={onFocus}
        label={placeholderText}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!inputValue?.length && (
                <IconButton onClick={clearInputHandler}>
                  <ClearRoundedIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ position: "relative", height: "auto" }}>
        <LocationList
          showLocationsList={showLocationsList}
          userindex={userindex}
          locationsList={searchLocation}
          suggestedLocationStaticIdPart={suggestedUserStaticIdPart}
          onLocationSelect={locationSelectClickHandler}
          isInputValue={!!inputValue}
          disableSearch={disabledSearch}
          searchLocationLoading={searchLocationLoading}
          selectedLocation={selectedLocation}
        />
      </Box>
    </Stack>
  )
}

SuggestedLocationsContainer.propTypes = {
  selectLocationNextAction: PropTypes.func,
  userindex: PropTypes.string,
  placeholderText: PropTypes.string,
  watchChangeUserindex: PropTypes.bool,
  closeOnLocationSelect: PropTypes.bool,
  resetOnLocationSelect: PropTypes.bool,
  id: PropTypes.string.isRequired,
  openedSuggestListInformer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(null),
  ]),
}

export default SuggestedLocationsContainer
