import axios from 'axios'
import sessionCacheService from '../../../../services/sessionCacheService'
import { areSearchQueriesEqual } from '../../../../utils/search'

const fetchSuggestionsDecorator = () => {
  let cancelToken = axios.CancelToken.source()
  return async function(data) {
    if (!data && cancelToken) {
      cancelToken.cancel('CANCELED SUGGESTIONS')
      return
    }
  }
}

export const fetchSuggestions = fetchSuggestionsDecorator()

export const getSuggestionsFromCacheOrFetch = async currentQuery => {
  let suggestionsKeywords = []
  const fromCache = await sessionCacheService.getCachedSuggestions()
  const { query, suggestionsKeywords: suggestionsKeywordsFromCache } = fromCache || {}
  // split out skipCount and sorting from api call
  const { skipCount: querySkipCount, sorting: querySkipSorting, ...restOfQuery } = query || {}
  const { skipCount: currentSkipCount, sorting: currentSkipSorting, ...restOfCurrentQuery } =
    currentQuery || {}
  if (suggestionsKeywordsFromCache && areSearchQueriesEqual(restOfQuery, restOfCurrentQuery)) {
    suggestionsKeywords = suggestionsKeywordsFromCache
  } else {
    suggestionsKeywords = await fetchSuggestions(restOfCurrentQuery)
    if (suggestionsKeywords && suggestionsKeywords.length)
      sessionCacheService.setCachedSuggestions({ query: { ...currentQuery }, suggestionsKeywords })
  }
  return suggestionsKeywords
}

export const cancelAllFetches = () => {
  ;(async () => {
    await fetchSuggestions()
  })()
}
