import React, { useEffect, useState } from "react";
import useTranslation from "../../../../../localization/useTranslation";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { infludataThemes } from "../../../../../themes/infludataThemes";

export const EngRateFilter = (props) => {
  const { selectedPlatform, query, handlersMap } = props || {};

  const { labelStrings } = useTranslation();

  const [localSliderValue, setLocalSliderValue] = useState(0);

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue);
  };

  const onChangeQueryValues = (e, value) => {
    handlersMap.engagementRate(value);
  };

  useEffect(() => {
    setLocalSliderValue(query?.engagementRate);
  }, []);

  return (
      <Stack sx={{ px: 2, py: 1, overflow: "hidden" }}>
        <Typography
          sx={{
            color: "#784E4E",
            fontWeight: 700,
            fontSize: "11px",
            textTransform: "uppercase",
            fontFamily: "Inter",
          }}
        >
          {labelStrings.minEngagementRate}
        </Typography>
        <Box>
          <Slider
            sx={{
              width: "100%",
              height: "5px",
              borderRadius: "8px",
              color: infludataThemes[selectedPlatform]?.platformColor,
              "& .MuiSlider-valueLabelOpen": {
                color: infludataThemes[selectedPlatform]?.platformColor,
                fontWeight: "bold",
              },
              '& .MuiSlider-rail': {
                color: infludataThemes[selectedPlatform]?.platformColor,
                opacity: 1
              },
              "& .MuiSlider-track": {
                backgroundColor: `#F3DBDB`,
                border: `1px solid #F3DBDB`,
              },
              "& .MuiSlider-valueLabel": {
                background: "unset",
                transformOrigin: "bottom left",
                "&::before": { display: "none" },
                "&.MuiSlider-valueLabelOpen": {
                  transform: "translate(60%, -50%) rotate(-225deg) scale(1)",
                },
                "& > *": {
                  transform: "rotate(-135deg)",
                },
              },
            }}
            value={localSliderValue}
            onChange={handleChange}
            onChangeCommitted={onChangeQueryValues}
            valueLabelDisplay='on'
            valueLabelFormat={(val) => (val === 0 ? "Off" : `≥ ${val}%`)}
            min={0}
            max={20}
            size='small'
          />
        </Box>
      </Stack>
  );
};
