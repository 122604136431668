import React from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material"

export const GenderFilter = (props) => {
  const { selectedPlatform, query, handlersMap } = props || {}

  const { labelStrings, genderOptions } = useTranslation()

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {}
    handlersMap?.gender(value)
  }

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.selectGender}
        </InputLabel>
        <Select
          size='small'
          value={query.gender ? query.gender : ""}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.selectGender} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                color: "#442424",
              },
            },
          }}
        >
          {genderOptions?.map((el) => (
            <MenuItem key={el?.value} value={el?.value} sx={{ fontSize: "12px", fontWeight: 700, fontFamily: "Inter" }}>
              {el?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
