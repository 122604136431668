import React from "react"

export const YouTubeSwitchIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={18}
    height={12}
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      d='M17.156 1.906c.375 1.313.375 4.125.375 4.125s0 2.782-.375 4.125a2.084 2.084 0 0 1-1.5 1.5C14.312 12 9 12 9 12s-5.344 0-6.688-.344a2.084 2.084 0 0 1-1.5-1.5C.438 8.812.438 6.031.438 6.031s0-2.812.376-4.125a2.126 2.126 0 0 1 1.5-1.531C3.655 0 9 0 9 0s5.313 0 6.656.375a2.126 2.126 0 0 1 1.5 1.531ZM7.25 8.563l4.438-2.532L7.25 3.5v5.063Z'
    />
  </svg>
)

export const InstagramSwitchIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={19}
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      d='M9.75 5.008c2.46 0 4.492 2.031 4.492 4.492 0 2.5-2.031 4.492-4.492 4.492A4.468 4.468 0 0 1 5.258 9.5 4.493 4.493 0 0 1 9.75 5.008Zm0 7.422c1.602 0 2.89-1.29 2.89-2.93a2.884 2.884 0 0 0-2.89-2.89c-1.64 0-2.93 1.288-2.93 2.89 0 1.64 1.328 2.93 2.93 2.93Zm5.703-7.578a1.05 1.05 0 0 0-1.055-1.055 1.05 1.05 0 0 0-1.054 1.055 1.05 1.05 0 0 0 1.054 1.054 1.05 1.05 0 0 0 1.055-1.054Zm2.969 1.054c.078 1.446.078 5.782 0 7.227-.078 1.406-.39 2.617-1.406 3.672C16 17.82 14.75 18.133 13.344 18.21c-1.446.078-5.781.078-7.227 0-1.406-.078-2.617-.39-3.672-1.406-1.015-1.055-1.328-2.266-1.406-3.672-.078-1.445-.078-5.781 0-7.227.078-1.406.39-2.656 1.406-3.672C3.5 1.22 4.711.906 6.117.828c1.446-.078 5.781-.078 7.227 0 1.406.078 2.656.39 3.672 1.406C18.03 3.25 18.344 4.5 18.422 5.906Zm-1.875 8.75c.469-1.133.351-3.867.351-5.156 0-1.25.118-3.984-.351-5.156-.313-.742-.899-1.367-1.64-1.64-1.173-.47-3.907-.352-5.157-.352-1.29 0-4.023-.118-5.156.351a3.013 3.013 0 0 0-1.68 1.64C2.445 5.517 2.562 8.25 2.562 9.5c0 1.29-.117 4.023.352 5.156a2.962 2.962 0 0 0 1.68 1.68c1.133.469 3.867.352 5.156.352 1.25 0 3.984.117 5.156-.352.742-.313 1.367-.898 1.64-1.68Z'
    />
  </svg>
)

export const TikTokSwitchIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={14}
    height={17}
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      d='M13.563 7.027c-1.319.03-2.55-.38-3.604-1.142v5.244c0 1.992-1.23 3.75-3.105 4.453a4.725 4.725 0 0 1-5.245-1.318 4.798 4.798 0 0 1-.615-5.42c.938-1.729 2.871-2.696 4.863-2.432V9.05a2.212 2.212 0 0 0-2.46.79c-.528.792-.528 1.817.029 2.579a2.183 2.183 0 0 0 2.431.791 2.199 2.199 0 0 0 1.524-2.08V.875h2.578c0 .234 0 .44.059.674A3.464 3.464 0 0 0 11.6 3.893c.556.38 1.26.586 1.963.586v2.548Z'
    />
  </svg>
)
