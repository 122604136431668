import React, { useEffect, useState } from "react"
import useTranslation from "../../../../../localization/useTranslation"
import { Box, Slider, Stack, Typography } from "@mui/material"
import { infludataThemes } from "../../../../../themes/infludataThemes"

export const AudienceRealPeopleFilter = (props) => {
  const { selectedPlatform, query, handlersMap, permissions } = props || {}

  const { labelStrings } = useTranslation()

  const [localSliderValue, setLocalSliderValue] = useState(0)

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue)
  }

  const onChangeQueryValues = (e, value) => {
    handlersMap.audience({
      audienceCountries: query.audienceCountries,
      audienceGender: query.audienceGender,
      audienceRealPeople: value,
      audienceAgeRanges: query.audienceAgeRanges,
      audienceLanguages: query.audienceLanguages,
    })
  }

  useEffect(() => {
    setLocalSliderValue(query?.audienceRealPeople)
  }, [])

  return (
    <Stack sx={{ px: 1 }}>
      <Typography
        sx={{
          color: "#784E4E",
          fontWeight: 700,
          fontSize: "11px",
          textTransform: "uppercase",
          fontFamily: "Inter",
        }}
      >
        {labelStrings.audienceByRealPeople}
      </Typography>
      <Box sx={{ pb: 2.5 }}>
        <Slider
          sx={{
            width: "100%",
            height: "5px",
            borderRadius: "8px",
            color: infludataThemes[selectedPlatform]?.platformColor,
            "& .MuiSlider-valueLabelOpen": {
              fontWeight: "bold",
              color: infludataThemes[selectedPlatform]?.platformColor
            },
            '& .MuiSlider-valueLabel': {
              background: 'unset',
              transformOrigin: 'bottom left',
              transform: 'translate(50%, 100%) rotate(45deg) scale(0)',
              '&::before': { display: 'none' },
              '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -40%) rotate(-225deg) scale(1)',
              },
              '& > *': {
                transform: 'rotate(-135deg)',
              },
            },
          }}
          value={localSliderValue}
          onChange={handleChange}
          onChangeCommitted={onChangeQueryValues}
          valueLabelDisplay='on'
          valueLabelFormat={(val) => (val === 0 ? "Off" : `≥ ${val}%`)}
          min={0}
          max={80}
        />
      </Box>
    </Stack>
  )
}
