import { useEffect, useState } from "react"

export const getWidth = (ref) => {
  const [width, setWidth] = useState(false)
  if (typeof window != "undefined") {
    useEffect(() => {
      let recalcWidth = () => {
        let w = ref?.current?.offsetWidth || 0
        setWidth(w)
      }
      window.addEventListener("resize", recalcWidth)
    }, [ref])
  }
  return width ? width : 0
}
