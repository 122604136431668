import React, { useEffect, useState } from 'react'
import { Box, Grid, MenuItem, Select, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../../localization/useTranslation'
import { BrandedContentItem } from './BrandedContentItem'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import GradientTransparentButton from '../../../../common/buttons/GradientTransparentButton'
import LoadingSpinner from '../../../../common/spinners/LoadingSpinner'
import { CAMPAIGN_SIDEBAR_WIDTH } from '../../../../../constants/appSettings'
import { DownloadContentModal } from '../../../../../new-ui/sections/content-overview/DownloadContentModal'

const ITEMS_PER_PAGE = 4
const modifyArrayLength = (array, to) => {
  return array?.slice(0, to)
}

export const BrandedContent = props => {
  const { allContentPieces, isLoading, onSortedBrandedContent, brandedCollectionSortType, onGetSimilarContentPieces } = props

  const { labelStrings } = useTranslation()
  const totalLengthAllContentPieces = allContentPieces?.length

  const [currentContentData, setCurrentContentData] = useState([])
  const [pageContentCount, setPageContentCount] = useState(ITEMS_PER_PAGE)
  const [currencyFromStorage] = useLocalStorage('currency')

  const [currentCreatorsData, setCurrentCreatorsData] = useState({})

  useEffect(() => {
    if (allContentPieces?.length) {
      setCurrentContentData(modifyArrayLength(allContentPieces, pageContentCount))
    }
  }, [allContentPieces?.length, pageContentCount])

  const onShowMore = () => {
    setPageContentCount(prevValue => prevValue + ITEMS_PER_PAGE)
  }

  const onHandleChangeSortType = ({ target }) => {
    const { value } = target || {}
    onSortedBrandedContent(value)
    setPageContentCount(ITEMS_PER_PAGE)
  }

  return (
    <>
      <Box sx={{ mt: '50px' }}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack spacing={1}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: '28px',
                  color: '#784E4E',
                }}
              >
                {labelStrings.brandsTimelineText.brandedContent}
              </Typography>
              <Typography sx={{ pl: '10px', color: '#784E4E', fontSize: '14px' }}>
                {labelStrings.brandsTimelineText.filterContentLabel}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ pr: 1 }}>
              <Typography sx={{ color: '#784E4E', fontSize: '14px' }}>
                {labelStrings.sortedBy}:
              </Typography>
              <Select
                size="small"
                value={brandedCollectionSortType}
                disabled={isLoading}
                onChange={onHandleChangeSortType}
                MenuProps={{
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor: '#F4E8E8',
                      fontWeight: 'bold',
                    },
                  },
                  PaperProps: {
                    style: {
                      maxHeight: '318px',
                    },
                  },
                }}
                sx={{
                  minWidth: '120px',
                  color: '#784E4E',
                  background: 'white',
                  '& .MuiSelect-icon': {
                    color: '#784E4E',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#784E4E',
                  },
                  '& .Mui-selected': {
                    backgroundColor: 'red',
                  },
                }}
              >
                <MenuItem
                  sx={{
                    color: '#784E4E',
                  }}
                  value={'uploaded'}
                >
                  {labelStrings.latest}
                </MenuItem>
                <MenuItem sx={{ color: '#784E4E' }} value={'reach'}>
                  {labelStrings.reach}
                </MenuItem>
              </Select>
            </Stack>
          </Stack>

          <>
            {isLoading ? (
              <Box sx={{ width: '1170', height: '578px' }}>
                <LoadingSpinner
                  width={`calc(100vw - ${CAMPAIGN_SIDEBAR_WIDTH}px)`}
                  isColoredBg
                  isInsidePageLoad
                />
              </Box>
            ) : (
              <Grid container spacing={1} sx={{ mt: 2 }}>
                {currentContentData?.map(item => (
                  <Grid item xs={12} md={3} key={item._id}>
                    <BrandedContentItem
                      item={item}
                      isNotWidget={false}
                      isDataEnrichment={false}
                      currencyFromStorage={currencyFromStorage}
                      setCurrentCreatorsData={setCurrentCreatorsData}
                      onGetSimilarContentPieces={onGetSimilarContentPieces}
                    />
                  </Grid>
                ))}
                {!currentContentData?.length && (
                  <Box sx={{ width: '100%', textAlign: 'center', py: 5 }}>
                    <Typography sx={{ color: '#784E4E', fontSize: '14px', fontWeight: 'bold' }}>
                      {labelStrings.noDataAvailable}
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}
            {totalLengthAllContentPieces >= pageContentCount &&
              currentContentData?.length === pageContentCount && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: '30px !important',
                  }}
                >
                  <GradientTransparentButton isDisabled={isLoading} onClick={onShowMore}>
                    {labelStrings.showMore}
                  </GradientTransparentButton>
                </Box>
              )}
          </>
        </Stack>
      </Box>
      <DownloadContentModal
        isOpened={!!currentCreatorsData?._id}
        setOpenedDownloadModal={setCurrentCreatorsData}
        currentCreatorsData={currentCreatorsData}
      />
    </>
  )
}
