import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useTranslation from '../../../../localization/useTranslation'
import CreateCampaignItem from './CreateCampaignItem'
import { Divider } from '../../../../components/common/styledWrappers'

const Title = styled.div`
  color: ${props => props.theme.primaryColor};
  letter-spacing: 2.1px;
  font-size: 14px;
  font-family: ${props => props.theme.fontFamilyInter};
  font-weight: 900;
  text-transform: uppercase;
  margin: 20px 40px;
`

const CampaignsListDivider = styled(Divider)`
  border: 0.5px solid #d5c8c7;
  width: 80%;
  margin: 20px auto;
`

const CampaignsListWrapper = styled.div`
  margin: 0 auto;
  width: 80%;
`

const CampaignItemWrapper = styled(Link)`
  cursor: pointer;
  position: relative;
  font-family: ${props => props.theme.fontFamilyRoboto};
  font-size: 15px;
  margin: 10px 0;
  display: block;
  color: ${props => props.theme.textColor};
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.textColor};
  }
  ${props => props.selected && `font-weight: 900;`}
`

const CampaignsSidebarMainWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock}
`

const CampaignsSidebar = ({ disabled = true, canCreateCampaigns, createNewCampaign, campaignsList }) => {
  const { labelStrings } = useTranslation()
  const renderCampaignsList = !!campaignsList.length

  const createCampaignPageLink = campaignId =>
    `${process.env.REACT_APP_PREFIX}/campaigns/${campaignId}`

  return (
    <CampaignsSidebarMainWrapper disabled={disabled}>
      <Title>{labelStrings.campaigns}</Title>

      <CreateCampaignItem
        canCreateCampaigns={canCreateCampaigns}
        createNewCampaign={createNewCampaign}
      />

      <CampaignsListDivider />

      {renderCampaignsList && (
        <CampaignsListWrapper>
          {campaignsList.map(({ name, campaignId, isSelected }) => (
            <CampaignItemWrapper
              key={campaignId}
              to={createCampaignPageLink(campaignId)}
              selected={isSelected}
            >
              {/* {[some prop flag] && <NotificationMark />} */}
              {/* // TODO: notifications mark */}
              {name}
            </CampaignItemWrapper>
          ))}
        </CampaignsListWrapper>
      )}
    </CampaignsSidebarMainWrapper>
  )
}

CampaignsSidebar.propTypes = {
  disabled: PropTypes.bool,
  canCreateCampaigns: PropTypes.bool,
  createNewCampaign: PropTypes.func,
  campaignsList: PropTypes.array,
}

export default CampaignsSidebar
