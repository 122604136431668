// import routes from '../../routes'
import axios from 'axios'
import httpService from '../../services/httpService'
import urlQueryService from '../../services/urlQueryService'
import { actionProvider } from './actionsProvider'
import { modifyProfileElementEverywhere } from './profilesCommonActions'
import {
  selectedProfileDetailsSelector,
  // userGrantSelector,
  isProfileNotFoundSelector,
  // isProfileLoadingSelector,
  isUserAuthenticatedSelector,
  searchResultsSelector,
  userCredsSelector,
} from '../selectors'
import { setError } from './errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'
import { pagesBeforeProfileRedirect } from '../../constants/pagesBeforeProfileRedirect'
import { startUpGetFullData } from './startupActions'
import { FAV_COLLECTION_NAME } from '../../constants/collections'
import { USER_INDEXES } from '../../constants/appSettings'
import queryString from 'query-string'
import { setSearchResults } from './searchDucks'
import { changeSearchResultsAndProfilesUsed } from './userDucks'

// action types
const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE'
const TOGGLE_LOADING_PROFILE_STATUS = 'TOGGLE_LOADING_PROFILE_STATUS'
const CHANGE_PROFILE = 'CHANGE_PROFILE'
const RESET_PROFILE = 'RESET_PROFILE'
const SET_SIMILAR_ACCOUNTS = 'SET_SIMILAR_ACCOUNTS'
const TOGGLE_NOT_FOUND_PROFILE = 'TOGGLE_NOT_FOUND_PROFILE'
const TOGGLE_LOADING_SIMILAR_ACCOUNTS = 'TOGGLE_LOADING_SIMILAR_ACCOUNTS'
const SET_PROFILE_ID = 'SET_PROFILE_ID'
const SET_CPM_ADJUSTMENTS = 'SET_CPM_ADJUSTMENTS'

/*Brand Collaborations */
const SET_BRAND_COLLABORATIONS_DATA = 'SET_BRAND_COLLABORATIONS_DATA'
const IS_BRAND_COLLABORATIONS_DATA_LOADING = 'IS_BRAND_COLLABORATIONS_DATA_LOADING'

const initState = {
  profile: null,
  openedProfileId: null,
  isLoading: false,
  isProfileNotFound: false,
  similarAccounts: {
    usersList: [],
    skipCount: 0,
    isLoading: false,
  },
  cpmAdjustments: {},
  brandCollaborations: {
    data: {},
    isLoading: false,
  },
}
// reducer
export const profileSelectedReducer = (state = initState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_LOADING_PROFILE_STATUS:
      return { ...state, isLoading: !state.isLoading }
    case SET_SELECTED_PROFILE:
      return { ...state, profile: payload }
    case CHANGE_PROFILE:
      return { ...state, profile: { ...state.profile, ...payload } }
    case RESET_PROFILE:
      return {
        profile: initState.profile,
        openedProfileId: state.openedProfileId,
        isLoading: initState.isLoading,
        isProfileNotFound: initState.isProfileNotFound,
        similarAccounts: {
          usersList: initState.similarAccounts.usersList,
          skipCount: initState.similarAccounts.skipCount,
          isLoading: initState.similarAccounts.isLoading,
        },
      }
    case SET_SIMILAR_ACCOUNTS:
      return {
        ...state,
        similarAccounts: { isLoading: state.similarAccounts.isLoading, ...payload },
      }
    case TOGGLE_NOT_FOUND_PROFILE:
      return { ...state, isProfileNotFound: payload }
    case TOGGLE_LOADING_SIMILAR_ACCOUNTS:
      return {
        ...state,
        similarAccounts: { ...state.similarAccounts, isLoading: payload },
      }
    case SET_PROFILE_ID:
      return {
        ...state,
        openedProfileId: payload,
      }
    case SET_CPM_ADJUSTMENTS:
      return {
        ...state,
        cpmAdjustments: payload,
      }
    case SET_BRAND_COLLABORATIONS_DATA:
      return {
        ...state,
        brandCollaborations: {
          ...state.brandCollaborations,
          data: payload,
        },
      }
    case IS_BRAND_COLLABORATIONS_DATA_LOADING:
      return {
        ...state,
        brandCollaborations: {
          ...state.brandCollaborations,
          isLoading: payload,
        },
      }
    default:
      return state
  }
}

// actions
export const toggleLoadingProfileAction = () => actionProvider(TOGGLE_LOADING_PROFILE_STATUS)
export const setSelectedProfileAction = payload => actionProvider(SET_SELECTED_PROFILE, payload)
export const changeProfileAction = payload => actionProvider(CHANGE_PROFILE, payload)
export const resetProfile = () => actionProvider(RESET_PROFILE)
export const toogleNotFoundProfile = payload => actionProvider(TOGGLE_NOT_FOUND_PROFILE, payload)
export const setSimilarAccounts = payload => actionProvider(SET_SIMILAR_ACCOUNTS, payload)
export const toogleLoadingSimilarAccounts = payload =>
  actionProvider(TOGGLE_LOADING_SIMILAR_ACCOUNTS, payload)
export const setSaveOpenedProfileId = payload => actionProvider(SET_PROFILE_ID, payload)
export const setCpmAdjustments = payload => actionProvider(SET_CPM_ADJUSTMENTS, payload)
export const setBrandCollaborations = payload =>
  actionProvider(SET_BRAND_COLLABORATIONS_DATA, payload)
export const toggleBrandCollaborationsDataLoading = payload =>
  actionProvider(IS_BRAND_COLLABORATIONS_DATA_LOADING, payload)
// async actions
// find user profile actions
export const fetchProfileById = ({
  profileId = '',
  fetchSimilars = true,
  refetchExistingProfile = false,
  source = pagesBeforeProfileRedirect.OTHER,
  withoutReset = false,
  withReplaceCollectionArray = false,
} = {}) => async (dispatch, getState) => {
  if (profileId) {
    const profileToken = urlQueryService.parseProfileQuery()

    if (refetchExistingProfile) {
      return dispatch(
        fetchProfile({
          profileId,
          profileToken,
          fetchSimilars: false,
          source,
          withReplaceCollectionArray,
        })
      )
    } else {
      const currentProfile = selectedProfileDetailsSelector(getState())
      const isProfileNotFound = isProfileNotFoundSelector(getState())
      if (!withoutReset && (currentProfile || isProfileNotFound)) dispatch(resetProfile())
      return dispatch(
        fetchProfile({ profileId, profileToken, fetchSimilars, source, withReplaceCollectionArray })
      )
    }
  }
}

// NOT USED FOR NOW
// export const fetchProfileByName = username => async dispatch => {
//   if (username) dispatch(fetchProfile({ username })) // if you want to use it, modify a little fetchProfile func
// }

// reusable fetch profile action for userId and can be modified for username too
export const fetchProfile = ({
  profileId,
  profileToken,
  source,
  fetchSimilars = true,
  withReplaceCollectionArray = false,
}) => async (dispatch, getState) => {
  const searchResults = searchResultsSelector(getState())
  const { searchResultsAndProfilesUsed } = userCredsSelector(getState())
  const { profileAnalyticsRemaining } = searchResultsAndProfilesUsed || {}

  dispatch(toggleLoadingProfileAction())
  try {
    const response = !!profileToken
      ? await httpService.fetchUserByIdWithToken({ profileId, token: profileToken, source })
      : await httpService.fetchUserById({ profileId, source })

    if (response) {
      const [profile] = response.userArray
      const { deductRequest } = response

      if(deductRequest) {
        dispatch(
          changeSearchResultsAndProfilesUsed({
            profileAnalyticsRemaining: profileAnalyticsRemaining - 1
          })
        )
      }

      await dispatch([
        withReplaceCollectionArray &&
          setSearchResults({
            results: {
              ...searchResults,
              users: searchResults?.users.map(u => {
                if (u._id === profile._id) {
                  return { ...u, collectionArray: profile.collectionArray }
                }
                return u
              }),
            },
          }),
        setSelectedProfileAction(profile || null),
        toggleLoadingProfileAction(),
        !profile && toogleNotFoundProfile(true),
        !!profile && handleProfileToken(),
        !!fetchSimilars && fetchSimilarAccounts(),
      ])
    }
    return true
  } catch (err) {
    if (!axios.isCancel(err)) {
      dispatch(setError(ERROR_MSG.serverNotRespond), toggleLoadingProfileAction())
    }
    return false
  }
}

export const changeUserProfileType = ({ profileId, switchBrandCreator }) => async (dispatch, getState) => {
  const searchResults = searchResultsSelector(getState())
  dispatch(toggleLoadingProfileAction())
  try {
    const response = await httpService.changeTypeUserProfile({ profileId, switchBrandCreator })
    if (response) {
      const [profile] = response.userArray
      await dispatch([
          setSearchResults({
            results: {
              ...searchResults,
              users: searchResults?.users.map(u => {
                if (u._id === profile._id) {
                  return { ...u, collectionArray: profile.collectionArray }
                }
                return u
              }),
            },
          }),
        setSelectedProfileAction(profile || null),
        toggleLoadingProfileAction(),
        !profile && toogleNotFoundProfile(true),
        !!profile && handleProfileToken(),
      ])
    }
    return true
  } catch (err) {
    if (!axios.isCancel(err)) {
      dispatch(setError(ERROR_MSG.serverNotRespond), toggleLoadingProfileAction())
    }
    return false
  }
}

export const handleProfileToken = () => (dispatch, getState) => {
  const currentProfile = selectedProfileDetailsSelector(getState())
  const { secToken: token, audienceAnalysis } = currentProfile || {}
  if (token && !!audienceAnalysis) {
    urlQueryService.setProfileQueryToken(token)
  }
}

export const fetchSimilarAccounts = ({ skipCount = 0 } = {}) => async (dispatch, getState) => {
  const profile = selectedProfileDetailsSelector(getState())
  const isUserAuthenticated = isUserAuthenticatedSelector(getState())

  if (!profile || !profile._id || !isUserAuthenticated) return

  try {
    dispatch(toogleLoadingSimilarAccounts(true))
    const usersList = await httpService.fetchSimilarUsers({
      userId: profile._id,
      username: profile.username,
      platform: profile._index,
      language: profile.language,
      country: profile.country,
      skipCount,
    })
    !!usersList && !!usersList.length && dispatch(setSimilarAccounts({ usersList, skipCount }))
    dispatch(toogleLoadingSimilarAccounts(false))
  } catch (err) {
    if (!axios.isCancel(err)) {
      dispatch([setError(ERROR_MSG.failSimilarAccounts), toogleLoadingSimilarAccounts(false)])
    }
  }
}

// modify profile actions
export const modifyProfileElement = (changeField, newValue, action) => async (
  dispatch,
  getState
) => {
  const profileId = getState().profileSelected.profile._id
  try {
    await httpService.changeProfileElement({ objectId: profileId, changeField, newValue })
    dispatch(changeProfileAction({ [changeField]: newValue }))
  } catch (err) {
    dispatch(setError(ERROR_MSG.modifyProfile))
  }
}

export const changeProfileComment = comment => (dispatch, getState) => {
  const profile = selectedProfileDetailsSelector(getState())
  if (profile) {
    dispatch(
      modifyProfileElementEverywhere({
        profileId: profile._id,
        changeField: 'comment',
        newValue: comment,
      })
    )
  }
}

export const adjustCPMValues = payload => async dispatch => {
  const { userId } = payload
  dispatch(toggleLoadingProfileAction())
  try {
    await httpService.adjustCPMValues(payload)

    dispatch([
      startUpGetFullData({
        collectionId: FAV_COLLECTION_NAME,
        collectionPage: 1,
        withCpmSetting: false,
      }),
      userId && fetchProfileById({ profileId: userId, fetchSimilars: false }),
    ])
  } catch (err) {
    dispatch([setError(ERROR_MSG.modifyProfile), toggleLoadingProfileAction()])
  }
}

export const getContentProfile = searchQueryParams => async dispatch => {
  dispatch(toggleBrandCollaborationsDataLoading(true))
  const stringifiedQuery = queryString.stringify({
    index: USER_INDEXES.instagram,
    sorting: 'uploaded',
    fromContentSearch: false,
    ...searchQueryParams,
  })
  try {
    const response = await httpService.getDataContentProfile(stringifiedQuery)

    if (response) {
      dispatch(setBrandCollaborations(response))
    }

    dispatch(toggleBrandCollaborationsDataLoading(false))
  } catch (err) {
    dispatch([
      setError(ERROR_MSG.modifyProfile),
      toggleLoadingProfileAction(),
      toggleBrandCollaborationsDataLoading(false),
    ])
  }
}
