import availableLangs from './availableLangs'

// TODO: add more messages here
export default {
  [availableLangs.en]: {
    // timeout: "I'm sorry. Your connection timed out.",
    // timeoutSmall: 'Please enter less keywords or specify the location you search in.',
    // noneFound: "I'm sorry. We couldn't find any matches.",
    // noneFoundSmall: 'Please change your settings.',
    // tooManyRequests: "I'm sorry. Your request limit was reached.",
    // tooManyRequestsSmall: 'Please contact our support for more information or wait a few minutes.',
    serverNotRespond: 'Server did not respond in time',
    wait: 'One moment while we complete the previous operation.',
    failLogin: 'Failed to log in. Please try again later',
    invalidUser: 'Failed to log in. Please check your credentials',
    failProceed: 'Failed to proceed the signup process. Please try again later',
    failUpgrade: 'Failed to upgrade. Please try again later',
    failReactivate: 'Failed to reactivate your subscription. Please try again later',
    failPayment: 'Payment failed. Please check your credentials and try again',
    failCancelPlan: 'Failed to cancel your subscription. Please try again later',
    hasBiggerPackage: 'You already have a bigger package',
    alreadyCancelledPlan:
      'You have already cancelled your current subscription. Reactivate before downgrading',
    reactivateBeforeUpgrade:
      'You must reactivate your current subscription before changing subscriptions',
    sendResetPassLinkFail: 'Failed to send the password restore link. Please try again later',
    resetPassFail: 'Failed to reset password. Your reset token might be expired or already used. Please request a new reset link.',
    modifyProfile: 'An error occurred while changing profile',
    failLoadCollections: 'Failed to load your collections. Please try again later',
    notModifiedCollection: 'User is already in collection',
    collectionLimit:
      'Your request exceeded the maximum number of users in the collection. Please upgrade or remove users from collection',
    failSearch: 'Failed to load search results. Please try again later',
    failSimilarAccounts: 'Failed to load similar accounts',
    failUser: 'Failed to apply changes. Please try again later',
    failContact: 'Failed to send contact details. Please try again later',
    failFeedback: 'Failed to send your feedback. Please try again later',
    failCreateCampaign: 'Failed to create campaign. Please try again later',
    failOneCampaign: 'Failed to load campaign. Please try again later',
    failAllCampaign: 'Failed to load your campaigns. Please try again later',
    failChangesCampaign: 'Failed to apply changes to your campaign. Please try again later',
    failDeleteCollection: 'Failed to delete collection. Please try again later',
    failDeleteCampaign: 'Failed to delete campaign. Please try again later',
    failExportPdf: 'Failed to export PDF. Please try again later',
    failUpdatecampaignSeats: 'Failed to update campaign seats. Please try again later',
    exceedRequestsLimit: 'Too many requests - Please try again later.',
    failTrialActivate: 'Failed to activate. Please try again later',
    failDeleteAccount: 'Failed to delete your account. Please try again later',
    deletedAccountWarning:
      'Your account is deactivated and will be removed soon. Please contact the support to activate your account again',
    failChangePassword: 'Failed to change your password. Please try again later',
    failLoadUserDetails: 'Failed to load your profile details. Please try again later',
    failUpdateBillingDetails: 'Failed to update your billing details. Please try again later',
    failGetEditPaymentPage:
      'Sorry, edit payments methods are not available now. Please try again later',
    pleaseAuthorize: 'Please log in to use the app',
    'billing_address[zip]': 'Invalid zip/postal code in billing details',
    vat_number: 'You need to enter a valid VAT number in billing details',
    failAddProfileToInfludata: 'Failed to add profile to influData. Please try again later',
    failUnlockAudienceReport: 'Failed to unlock audience ananysis. Please try again later',
    forbiddenUnlockAudienceReport: "You can't unlock audience analysis. Please check your tokens",
    failToAddProfileToCompare: 'Failed to add profile to compare list. Please try again later',
    failToRemoveProfileFromCompare:
      'Failed to remove profile from compare list. Please try again later',
    forbiddenAddToComparedProfiles: "You can't add users from more than one platform",
    comparedProfilesLimitReach: number =>
      `The compared profiles list limit is reached (max. ${number}). Please remove profiles from the list`,
    comparedProfileAlreadyInList: 'Profile is already in compared profiles list',
    failSortComparedProfiles: 'Failed to sort compared profiles. Pease try again later',
    failAddSubUser:
      'Failed to add new company member. Account might already be added to another account or is subscribed to a paid plan.',
    failremoveSubUser: 'Failed to remove company member. Please try again',
    failChangeCollectionAccess: 'Failed to change collection access. Please try again',
    failOperation: 'Failed to perform the operation',
    failPurchaseOneTimeTokens: 'Failed to purchase one time tokens. Please try again',
    invalidYear: 'Invalid year',
    additionalFieldNameIsNotValid: 'Field name must contain 2-250 characters',
    additionalFieldOptionsAreLessThanTwo: 'Select must have at least 2 options',
    failedToAddNote: 'Failed to add note. Please try again later.',
    failedToEditNote: 'Failed to edit note. Please try again later.',
    failedToDeleteNote: 'Failed to delete note. Please try again later.',
    fieldIsRequired: 'This field is required',
    creatorAlreadyInPipeline: `Creator already in pipeline. It's not possible to add the same creator multiple times.`,
    outCampaignSeats: 'You are out of campaign seats.',
    dontHaveAudienceReports: `You don't have enough audience reports available to create this overlap.`,
    failedSwitchPlatform: 'This function is not included in your plan, please contact our customer support.',
    pleaseSpecifyYourPrompt: `I'm sorry, but our AI couldn't figure out what you were looking for. Please specify your prompt.`
  },
  [availableLangs.de]: {
    // timeout: 'Die Suche hat leider zu lang gedauert.',
    // timeoutSmall: 'Bitte reduziere deine Keywords oder gib uns einen genaueren Ort.',
    // noneFound: 'Leider konnten wir keine Influencer finden.',
    // noneFoundSmall: 'Bitte ändere deine Suche.',
    // tooManyRequests: 'Leider konnten wir Ihre Suchanfrage nicht bearbeiten.',
    // tooManyRequestsSmall: 'Please kontaktieren Sie unseren Support oder warten Sie einige Minuten.',
    serverNotRespond: 'Der Server hat nicht rechtzeitig geantwortet',
    wait: 'Einen Moment, während wir die vorherige Operation abschließen.',
    failLogin: 'Anmeldung fehlgeschlagen. Bitte versuche es später erneut',
    invalidUser: 'Anmeldung fehlgeschlagen. Bitte überprüfe deine Anmeldeinformationen',
    failProceed: 'Fehler beim Fortfahren des Anmeldevorgangs. Bitte versuche es später noch einmal',
    failUpgrade: 'Upgrade fehlgeschlagen. Bitte versuche es später noch einmal',
    failReactivate:
      'Fehler beim Reaktivieren deines Abonnements. Bitte versuche es später noch einmal',
    failPayment:
      'Bezahlung fehlgeschlagen. Bitte überprüfe deine Anmeldeinformationen und versuche es erneut',
    failCancelPlan:
      'Das Abonnement konnte nicht gekündigt werden. Bitte versuche es später noch einmal',
    hasBiggerPackage: 'Du hast bereits ein größeres Paket',
    alreadyCancelledPlan:
      'Du hast das aktuelle Abonnement bereits gekündigt. Vor dem Downgrade reaktivieren.',
    reactivateBeforeUpgrade:
      'Du musst dein aktuelles Abonnement erneut aktivieren, bevor du Abonnements ändern kannst',
    sendResetPassLinkFail:
      'Fehler beim Senden des Links zur Kennwortwiederherstellung. Bitte versuche es später noch einmal',
    resetPassFail:
      'Passwort konnte nicht zurückgesetzt werden. Möglicherweise ist der Link abgelaufen oder wurde bereits genutzt. Bitte fordere einen neuen Link an.',
    modifyProfile: 'Beim Ändern des Profils ist ein Fehler aufgetreten',
    failLoadCollections:
      'Fehler beim Laden Ihrer Collections. Bitte versuche es später noch einmal',
    notModifiedCollection: 'Benutzer ist bereits in der Collection',
    collectionLimit:
      'Deine Anfrage hat die maximale Anzahl von Benutzern in der Collection überschritten. Bitte aktualisiere oder entferne Benutzer aus der Collection',
    failSearch: 'Fehler beim Laden der Suchergebnisse. Bitte versuche es später noch einmal',
    failSimilarAccounts: 'Fehler beim Laden ähnlicher Profile',
    failUser: 'Änderungen konnten nicht übernommen werden. Bitte versuche es später noch einmal',
    failContact: 'Kontaktdaten konnten nicht gesendet werden. Bitte versuche es später noch einmal',
    failFeedback: 'Fehler beim Senden Ihres Feedbacks. Bitte versuche es später noch einmal',
    failCreateCampaign:
      'Kampagne konnte nicht erstellt werden. Bitte versuche es später noch einmal',
    failOneCampaign: 'Camapaign konnte nicht geladen werden. Bitte versuche es später noch einmal',
    failAllCampaign: 'Fehler beim Laden Ihrer Kampagnen. Bitte versuche es später noch einmal',
    failChangesCampaign:
      'Änderungen an Ihrer Kampagne konnten nicht übernommen werden. Bitte versuche es später noch einmal',
    failDeleteCollection:
      'Sammlung konnte nicht gelöscht werden. Bitte versuche es später noch einmal',
    failDeleteCampaign:
      'Kampagne konnte nicht gelöscht werden. Bitte versuche es später noch einmal',
    failExportPdf: 'PDF konnte nicht exportiert werden. Bitte versuche es später noch einmal',
    failUpdatecampaignSeats:
      'Aktualisierung der Kampagnenplätze fehlgeschlagen. Bitte versuche es später noch einmal',
    exceedRequestsLimit: 'Zu viele Anfragen. Bitte versuche es später noch einmal.',
    failTrialActivate: 'Fehler beim Aktivieren. Bitte versuche es später noch einmal',
    failDeleteAccount: 'Fehler beim Löschen deines Kontos. Bitte versuche es später noch einmal',
    deletedAccountWarning:
      'Dein Konto ist deaktiviert und wird bald entfernt. Bitte wende dich an den Support, um dein Konto erneut zu aktivieren',
    failChangePassword: 'Fehler beim Ändern Ihres Passworts. Bitte versuche es später noch einmal',
    failLoadUserDetails:
      'Fehler beim Laden Ihrer Profildetails. Bitte versuche es später noch einmal',
    failUpdateBillingDetails:
      'Fehler beim Aktualisieren deiner Rechnungsdetails. Bitte versuche es später noch einmal',
    failGetEditPaymentPage:
      'Leider ist das Bearbeiten von Zahlungsmethoden derzeit nicht verfügbar. Bitte versuche es später noch einmal',
    pleaseAuthorize: 'Bitte melde dich an, um die App zu nutzen',
    'billing_address[zip]': 'Ungültige Postleitzahl in den Rechnungsdetails',
    vat_number:
      'Du musst eine gültige Umsatzsteuer-Identifikationsnummer in die Rechnungsdetails eingeben',
    failAddProfileToInfludata:
      'Fehler beim Hinzufügen eines Profils zu influData. Bitte versuche es später noch einmal',
    failUnlockAudienceReport:
      'Fehler beim Freischalten des Audience Reports. Bitte versuche es später noch einmal',
    forbiddenUnlockAudienceReport:
      'Du kannst den Report nicht freischalten. Bitte überprüfe deine Token',
    failToAddProfileToCompare:
      'Fehler beim Hinzufügen des Profils zur Vergleichsliste. Bitte versuche es später noch einmal',
    failToRemoveProfileFromCompare:
      'Profil konnte nicht aus der Vergleichsliste entfernt werden. Bitte versuche es später noch einmal',
    forbiddenAddToComparedProfiles:
      'Du kannst keine Profile von mehr als einer Plattform hinzufügen',
    comparedProfilesLimitReach: number =>
      `Das Limit der Vergleichsliste ist erreicht (max. ${number}). Bitte entferne Profile aus der Liste`,
    comparedProfileAlreadyInList: 'Das Profil befindet sich bereits in der Vergleichsliste',
    failSortComparedProfiles:
      'Profile konnten nicht sortiert werden. Bitte versuche es später noch einmal',
    failAddSubUser:
      'Neuer Nutzer konnte nicht hinzugefügt werden. Entweder der Account wurde bereits von einem anderen Account hinzugefügt oder hat selbst ein bezahltes Abo.',
    failremoveSubUser: 'Nutzer konnte nicht entfernt werden. Bitte versuche es erneut',
    failChangeCollectionAccess: 'Fehler beim Ändern des Zugriffs. Bitte versuche es erneut',
    failOperation: 'Operation konnte nicht ausgeführt werden',
    failPurchaseOneTimeTokens:
      'Einmalige Token konnten nicht gekauft werden. Bitte versuche es erneut ',
    invalidYear: 'Ungültiges Jahr',
    additionalFieldNameIsNotValid: 'Feldname muss 2-250 Zeichen enthalten',
    additionalFieldOptionsAreLessThanTwo: 'Select muss mindestens 2 Optionen haben',
    failedToAddNote: 'Notiz konnte nicht hinzugefügt werden. Bitte versuche es später noch einmal.',
    failedToEditNote: 'Notiz konnte nicht bearbeitet werden. Bitte versuche es später noch einmal.',
    failedToDeleteNote: 'Notiz konnte nicht gelöscht werden. Bitte versuche es später noch einmal.',
    fieldIsRequired: 'Dieses Feld wird benötigt',
    creatorAlreadyInPipeline: `Creator bereits in der Pipeline. Es ist nicht möglich, denselben Creator mehrmals hinzuzufügen.`,
    outCampaignSeats: 'Du hast keine Campaign Seats mehr.',
    dontHaveAudienceReports: `Du hast nicht genügend Audience Reports, um diesen Overlap zu erstellen.`,
    failedSwitchPlatform: 'Diese Funktion ist in deinem Abo nicht enthalten. Bitte kontaktiere unseren Support.',
    pleaseSpecifyYourPrompt: `Es tut uns leid, aber unsere KI konnte nicht herausfinden, wonach gesucht werden soll. Bitte erstelle einen spezifischeren Prompt.`
  },
}
