import { useRef, useEffect } from 'react'

export const useEffectOnce = (callback, when) => {
  const hasRunOnce = useRef(false);

  useEffect(() => {
    if (when && !hasRunOnce.current) {
      callback();
      hasRunOnce.current = true;
    }
  }, [when]);
};