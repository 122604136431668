import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DoneIcon, StopIcon } from '../../../../../components/common/icons'
import { PlusIcon } from '../../../../assets/icons/navbar'
import LoadingSpinner from '../../../../../components/common/spinners/LoadingSpinner'

const Wrapper = styled.div`
  // position: absolute;
  ${props => props.theme.flex.centerAll}
  height: 100%;
  width: 40px;
`

const CollectionItemDroppableIcon = ({
  canDragToCollection,
  isDropOperationInProgress,
  isDropOperationSuccess,
}) => {
  const createAllowIcon = () => {
    if (!isDropOperationInProgress && !isDropOperationSuccess) {
      return <PlusIcon />
    }
    if (isDropOperationInProgress) {
      return isDropOperationSuccess ? (
        <DoneIcon />
      ) : (
        <LoadingSpinner spinnerType="spin" spinnerWidth="25px" spinnerHeight="100%" />
      )
    }
  }

  return <Wrapper>{canDragToCollection.forbidden ? <StopIcon /> : createAllowIcon()}</Wrapper>
}

CollectionItemDroppableIcon.propTypes = {
  canDragToCollection: PropTypes.object,
}

export default CollectionItemDroppableIcon
