import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
// import { searchLoadingStatusSelector, selectedPlatformSelector } from "../../../../../redux/selectors"
import useDebounce from "../../../../../hooks/useDebounce"
import httpService from "../../../../../services/httpService"
import { SuggestedCreatorsInput } from "./SuggestedCreatorsInput"
import { Stack, Chip, Box } from "@mui/material"
import { selectedPlatformSelector } from "../../../../../redux/selectors"

const AddCreatorsTo = ({
  isAllowedToAddCreators = false,
  brandMentionsIdsFromState = "",
  addBrandMentions,
  isSetCreatorId = true,
  removeBrandMentions,
  brandMentionsValuesToArray,
  onRemoveChip,
}) => {
  const currentPlatform = useSelector(selectedPlatformSelector)
  // const searchLoading = useSelector(searchLoadingStatusSelector)

  const ref = useRef(null)
  const getContainerHeight = ref?.current?.offsetHeight

  const [loadingCreators, setLoadingCreators] = useState(false)
  const [creatorsData, setCreatorsData] = useState([])
  const [notFoundData, setNotFoundData] = useState(false)
  const [error, setError] = useState(false)

  const [showListCreators, setShowListCreators] = useState(true)

  const [inputValue, setInputValue] = useState("")
  const debouncedInputValue = useDebounce(inputValue, 500)

  const fetchSuggestedCreators = async (searchString) => {
    try {
      setLoadingCreators(true)
      setNotFoundData(false)
      setError(false)
      setCreatorsData([])
      const creatorsUsers = await httpService.fetchSuggestedUsers(
        searchString,
        currentPlatform?.toLowerCase(),
      )
      if (!creatorsUsers?.length) {
        setNotFoundData(true)
      }
      if (creatorsUsers?.length) {
        setCreatorsData(creatorsUsers)
      }
      setLoadingCreators(false)
    } catch (err) {
      setError(true)
      setLoadingCreators(false)
      setCreatorsData([])
      setNotFoundData(false)
    }
  }

  useEffect(() => {
    if (debouncedInputValue === inputValue && debouncedInputValue.length >= 2) {
      fetchSuggestedCreators(debouncedInputValue)
    }
    if (!debouncedInputValue?.length) {
      setCreatorsData([])
      setNotFoundData(false)
      setLoadingCreators(false)
      setError(false)
    }
  }, [debouncedInputValue, currentPlatform])

  return (
    <Stack
      sx={{
        position: "relative",
        height:
          showListCreators && creatorsData?.length > 0
            ? "375px"
            : `calc(56px + ${getContainerHeight}px)`,
      }}
    >
      <SuggestedCreatorsInput
        creatorsData={creatorsData}
        notFoundData={notFoundData}
        error={error}
        currentPlatform={currentPlatform}
        debouncedInputValue={debouncedInputValue}
        inputValue={inputValue}
        loadingCreators={loadingCreators}
        setInputValue={setInputValue}
        setCreatorsData={setCreatorsData}
        setLoadingCreators={setLoadingCreators}
        setNotFoundData={setNotFoundData}
        isAllowedToAddCreators={isAllowedToAddCreators}
        creatorIdFromState={brandMentionsIdsFromState}
        setCreatorIdFromState={addBrandMentions}
        isSetCreatorId={isSetCreatorId}
        removeBrandMentions={removeBrandMentions}
        brandMentionsValuesToArray={brandMentionsValuesToArray}
        showListCreators={showListCreators}
        setShowListCreators={setShowListCreators}
      />

      <Box sx={{ position: "absolute", bottom: 0 }}>
        {!!brandMentionsValuesToArray?.length && (
          <Stack
            ref={ref}
            direction='row'
            sx={{ mt: 2, flexWrap: "wrap", maxWidth: "280px", gap: "8px" }}
          > 
            {brandMentionsValuesToArray?.map((el) => (
              <Chip
                key={el}
                sx={{
                  backgroundColor: "#EBD0D0",
                  color: "#6B4545",
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  "& svg": { fill: "#6B4545" },
                }}
                label={el}
                onDelete={() => onRemoveChip(el)}
              />
            ))}
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

AddCreatorsTo.propTypes = {
  isAllowedToAddCreators: PropTypes.bool,
  brandMentionsIdsFromState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  addBrandMentions: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
  removeBrandMentions: PropTypes.func,
  onRemoveChip: PropTypes.func,
}

export default AddCreatorsTo
