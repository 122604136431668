import queryString from 'query-string'
import axios from 'axios'
import { actionProvider } from './actionsProvider'
//import { setError } from './errorDucks'
import { areSearchQueriesEqual, filterQueryParams, transformValuesToNumberOrBoolean } from '../../utils/search'
import {
  selectedPlatformSelector,
  searchLoadingStatusSelector,
  currentSearchQuerySelector,
  searchDataSelector,
  searchResultsSelector,
  searchResultsPresentSelector,
  searchTypeSelector,
  userGrantSelector,
  currentExploreRecentSearchQuery,
  searchSuggestionsReceiveSurveyResultSelector,
  userCredsSelector
} from '../selectors'
import {
  setSelectedUsersCommonAction,
  modifyProfileElementEverywhere,
} from './profilesCommonActions'
import { triggerSocialPlatform, limitAccess, changeAppSocialPlatform } from './appSettingsDucks'
import { SOCIAL_PLATFORMS_NAMES, API_STATUS_CODES } from '../../constants/appSettings'
import { searchContentTypes, SEARCH_SLIDERS_RANGE } from '../../constants/search'
import {
  SEARCH_SORTING_TYPES,
  SORTING_DIRECTIONS,
  // SORTING_DIRECTIONS_SHORT_TO_LONG_NAMES,
  SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES,
} from '../../constants/sortings'
//import { ERROR_MSG } from '../../constants/errorMessages'
import urlQueryService from '../../services/urlQueryService'
import httpService from '../../services/httpService'
import sessionCacheService from '../../services/sessionCacheService'
import { changeExploreQuery } from './exploreDucks'
import { transformListToDict } from '../../new-ui/pages/Search/utils'
import { setError } from './errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'
import { changeSearchResultsAndProfilesUsed } from './userDucks'

// action types
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS'
const UPDATE_SEARCH_USERS_LIST = 'UPDATE_SEARCH_USERS_LIST'
const CHANGE_CURRENT_SEARCH_QUERY = 'CHANGE_CURRENT_SEARCH_QUERY'
const CHANGE_SEARCH_LOADING_STATUS = 'CHANGE_SEARCH_LOADING_STATUS'
const CHANGE_SEARCH_TYPE = 'CHANGE_SEARCH_TYPE'
const SET_TIMELINE_BUCKET_RESULTS = 'SET_TIMELINE_BUCKET_RESULTS'
const CHANGE_TIMELINE_BUCKET_LOADING = 'CHANGE_TIMELINE_BUCKET_LOADING'
const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION'
const SET_CONTENT_SEARCH_ACTIVE_MARKER = 'SET_CONTENT_SEARCH_ACTIVE_MARKER'
const SET_SEARCH_SUGGESTIONS_DATA = 'SET_SEARCH_SUGGESTIONS_DATA'
const CHANGE_SEARCH_SUGGESTIONS_LOADING = 'CHANGE_SEARCH_SUGGESTIONS_LOADING'
const CHANGE_SEARCH_LOCATION_LOADING = 'CHANGE_SEARCH_LOCATION_LOADING'
const SAVE_SUGGESTIONS_PROMPT_REQ_DATA = 'SAVE_SUGGESTIONS_PROMPT_REQ_DATA'
const SET_SHOW_RECEIVE_SURVEY_RESULT = 'SET_SHOW_RECEIVE_SURVEY_RESULT'

const initState = {
  results: {
    users: [],
    content: [],
    count: 0,
    dataSuccess: {},
  },
  timelineBucket: {
    data: [],
  },
  isTimelineBucketLoaded: false,
  searchLocation: [],
  searchLocationLoading: false,
  currentSearchQuery: {
    city: '',
    country: null,
    language: null,
    followerMin: SEARCH_SLIDERS_RANGE.min,
    followerMax: SEARCH_SLIDERS_RANGE.max,
    gender: null,
    skipCount: 0,
    additionalSkipsForBlacklisting: 0,
    keywords: '',
    brandMentions: '',
    categories: '',
    matchAnyKeyword: false,
    mustIncludeEmail: false,
    appearsOn: '',
    index: SOCIAL_PLATFORMS_NAMES.instagram.index,
    sorting: '',
    growthRate: null,
    engagementRate: null,
    audienceCountries: '',
    audienceGender: '',
    audienceLanguages: '',
    audienceRealPeople: null,
    audienceAgeRanges: '',
    isShortsCreator: '',
    contentTypes: '',
    uploadedFrom: '',
    uploadedTo: '',
    placesId: '',
    radiusKM: '',
    hideCreatorsInCollection: false,
    isSimilarContentPieces: false,
    businessSearch: false
  },
  suggestions: {
    isLoading: false,
    data: []
  },
  suggestionsReceiveSurveyResult: {
    tempRequestData: {},
    showReceiveSurveyResult: false,
  },
  isSearchLoading: false,
  searchType: searchContentTypes.CREATOR,
  searchContentActiveMapMarker: {}
}

const createInitState = () => {
  const socialPlatform = sessionCacheService.getCachedSocialPlatform()
  if (socialPlatform === SOCIAL_PLATFORMS_NAMES.tiktok.name) {
    return {
      ...initState,
      currentSearchQuery: {
        ...initState.currentSearchQuery,
        index: SOCIAL_PLATFORMS_NAMES.tiktok.index,
      },
    }
  }
  if (socialPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name) {
    return {
      ...initState,
      currentSearchQuery: {
        ...initState.currentSearchQuery,
        index: SOCIAL_PLATFORMS_NAMES.youtube.index,
      },
    }
  }
  return initState
}

// reducer
export const searchReducer = (state = createInitState(), action = {}) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      const { results } = action.payload
      return { ...state, results }
    case RESET_SEARCH_RESULTS:
      const { searchType } = action.payload || {}
      return { ...createInitState(), searchType: state.searchType, suggestions: state.suggestions, currentSearchQuery: {
        ...createInitState().currentSearchQuery,
        ...(searchType === searchContentTypes.BRAND && { businessSearch: true })
      } 
    }
    case UPDATE_SEARCH_USERS_LIST:
      return {
        ...state,
        results: { ...state.results, users: action.payload },
      }
    case CHANGE_CURRENT_SEARCH_QUERY:
      return {
        ...state,
        currentSearchQuery: action.payload,
      }
    case CHANGE_SEARCH_TYPE:
      return { ...state, searchType: action.payload }
    case CHANGE_SEARCH_LOADING_STATUS:
      return { ...state, isSearchLoading: action.payload }
    case SET_TIMELINE_BUCKET_RESULTS:
      return { ...state, timelineBucket: action.payload }
    case CHANGE_TIMELINE_BUCKET_LOADING:
      return {
        ...state,
        isTimelineBucketLoaded: action.payload,
      }
    case SET_SEARCH_LOCATION:
      return { ...state, searchLocation: action.payload }
    case CHANGE_SEARCH_LOCATION_LOADING:
      return { ...state, searchLocationLoading: action.payload}
    case SET_CONTENT_SEARCH_ACTIVE_MARKER:
      return { ...state, searchContentActiveMapMarker: action.payload }
    case SET_SEARCH_SUGGESTIONS_DATA: 
      return { ...state, suggestions: { ...state.suggestions, data: action.payload }}
    case CHANGE_SEARCH_SUGGESTIONS_LOADING: 
      return { ...state, suggestions: { ...state.suggestions,  isLoading: action.payload }}
    case SAVE_SUGGESTIONS_PROMPT_REQ_DATA: 
      return { ...state, suggestionsReceiveSurveyResult: { ...state.suggestionsReceiveSurveyResult,  tempRequestData: action.payload }}
    case SET_SHOW_RECEIVE_SURVEY_RESULT: 
      return { ...state, suggestionsReceiveSurveyResult: { ...state.suggestionsReceiveSurveyResult,  showReceiveSurveyResult: action.payload }}
    default:
      return state
  }
}

// actions
export const setSearchResults = payload => actionProvider(SET_SEARCH_RESULTS, payload)
export const clearSearch = (payload) => actionProvider(RESET_SEARCH_RESULTS, payload)
export const updateSearchUsersList = payload => actionProvider(UPDATE_SEARCH_USERS_LIST, payload)
export const updateCurrentSearchQuery = payload =>
  actionProvider(CHANGE_CURRENT_SEARCH_QUERY, payload)
export const changeSearchLoadingStatus = payload =>
  actionProvider(CHANGE_SEARCH_LOADING_STATUS, payload)
export const setTimelineBucketResults = payload =>
  actionProvider(SET_TIMELINE_BUCKET_RESULTS, payload)
export const changeTimelineBucketLoading = payload =>
  actionProvider(CHANGE_TIMELINE_BUCKET_LOADING, payload)
export const setSearchLocationData = payload => actionProvider(SET_SEARCH_LOCATION, payload)
export const setSearchLocationLoading = payload => actionProvider(CHANGE_SEARCH_LOCATION_LOADING, payload)
export const setActiveMapMarker = payload =>
  actionProvider(SET_CONTENT_SEARCH_ACTIVE_MARKER, payload)
export const changeSearchType = payload => actionProvider(CHANGE_SEARCH_TYPE, payload)
export const changeSuggestionsData = payload => actionProvider(SET_SEARCH_SUGGESTIONS_DATA, payload)
export const changeSuggestionsLoading = payload => actionProvider(CHANGE_SEARCH_SUGGESTIONS_LOADING, payload)
export const changeSuggestionsRequestData = payload => actionProvider(SAVE_SUGGESTIONS_PROMPT_REQ_DATA, payload)
export const changeShowReceiveSurveyResult = payload => actionProvider(SET_SHOW_RECEIVE_SURVEY_RESULT, payload)
// async actions

export const abortSearchProccess = () => (dispatch, getState) => {
  const isLoading = searchLoadingStatusSelector(getState())
  if (isLoading) dispatch(changeSearchLoadingStatus(false))
  httpService.fetchSearchResults(null) //aborts current search api call
}

export const resetSearchResults = (history, payload) => dispatch => {
  sessionCacheService.resetSearchCache()
  history && history.push(process.env.REACT_APP_PREFIX + 'search')
  dispatch(clearSearch(payload))
}
// start up search action
export const fetchStartupSearch = location => async (dispatch, getState) => {
  if (location.search) {
    const currentQuery = currentSearchQuerySelector(getState())

    const latestQuery = urlQueryService.parseCurrentSearchQuery(location, currentQuery)
    const areCurrentResultsPresent = searchResultsPresentSelector(getState())
    if (!areSearchQueriesEqual(currentQuery, latestQuery) || !areCurrentResultsPresent) {
      const { index } = latestQuery
      const newPlatform = Object.values(SOCIAL_PLATFORMS_NAMES)?.find(obj => obj.index === index)
      dispatch([
        triggerSocialPlatform(
          newPlatform ? newPlatform.name : SOCIAL_PLATFORMS_NAMES.instagram.name
        ),
        changeCurrentSearchQuery({ ...latestQuery, radiusKM: '', placesId: '' }),
      ])
    }
  } else {
    const {
      currentSearchQuery: latestQuery,
      results: { users },
    } = searchDataSelector(getState())
    if (users?.length) {
      urlQueryService.setNewSearchQuery({
        latestQuery,
      })
    }
  }
}

export const fetchSearchResults = searchQueryParams => async (dispatch, getState) => {
  const { index, isSimilarContentPieces, ...restOfQueryParams } = searchQueryParams // index is not included in api search query
  const currentSearchType = searchTypeSelector(getState())
  const { timelineBucket } = searchDataSelector(getState())
  const { enable_ig_search, enable_tt_search, enable_yt_search } = userGrantSelector(getState())
  const platform = selectedPlatformSelector(getState())
  const { searchResultsAndProfilesUsed } = userCredsSelector(getState())
  const { searchResultsRemaining } = searchResultsAndProfilesUsed || {}

  const stringifiedQuery = queryString.stringify({
    ...restOfQueryParams,
    index,
    fromContentSearch: currentSearchType === searchContentTypes.CONTENT ? true : undefined,
  })

  const searchCred = {
    [SOCIAL_PLATFORMS_NAMES.instagram.name]: enable_ig_search,
    [SOCIAL_PLATFORMS_NAMES.tiktok.name]: enable_tt_search,
    [SOCIAL_PLATFORMS_NAMES.youtube.name]: enable_yt_search,
  }
  
  if (!searchCred[platform] || (isSimilarContentPieces === 'true' || isSimilarContentPieces === true)) return

  dispatch(changeSearchLoadingStatus(true))
  try {
    const response =
      (await httpService.fetchSearchResults(stringifiedQuery, currentSearchType)) || {}
    const { users = [], count = 0, content = [], dataSuccess, deductRequest } = response

    if(deductRequest) {
      dispatch(
        changeSearchResultsAndProfilesUsed({
          searchResultsRemaining: searchResultsRemaining - 1
        })
      )
    }

    dispatch([
      setSearchResults({
        results: {
          users: users.map(u => ({ ...u, isSelected: false })),
          content,
          count,
          dataSuccess,
        },
      }),
      changeSearchLoadingStatus(false),
      !timelineBucket?.data?.length && getTimelineForContent({ ...restOfQueryParams, index }),
    ])
  } catch (err) {
    if (err.response && err.response.status === API_STATUS_CODES.upgradeRequired) {
      return dispatch([limitAccess(), changeSearchLoadingStatus(false)])
    }
    if (!axios.isCancel(err)) {
      //setError(ERROR_MSG.failSearch),
      dispatch(changeSearchLoadingStatus(true))
    }
  }
}

export const updateSearch = ({ newValuesObj }) => (dispatch, getState) => {
  const currentSearchQuery = currentSearchQuerySelector(getState())
  const currentPlatform = selectedPlatformSelector(getState())
  const searchRes = searchResultsSelector(getState())
  const updatedSearchQuery = filterQueryParams(currentSearchQuery, newValuesObj, currentPlatform)
  const transformedQuery = newValuesObj?.contentId ? updatedSearchQuery : transformValuesToNumberOrBoolean(updatedSearchQuery)
  const isQueryTheSame = areSearchQueriesEqual(currentSearchQuery, transformedQuery)

  if (!isQueryTheSame) {
    urlQueryService.setNewSearchQuery({
      latestQuery: transformedQuery,
    })

    const isRefetch = Object.keys(newValuesObj).every(
      el =>
        el === 'contentTypes' ||
        el === 'uploadedFrom' ||
        el === 'uploadedTo' ||
        el === 'skipCount' ||
        el === 'additionalSkipsForBlacklisting'
    )
    if (isRefetch) {
      dispatch(changeCurrentSearchQuery(transformedQuery))
    } else {
      const updatedSearchQueryWithResetTimeline = filterQueryParams(
        transformedQuery,
        { uploadedFrom: '', uploadedTo: '', skipCount: transformedQuery?.skipCount || 0 },
        currentPlatform
      )

      urlQueryService.setNewSearchQuery({
        latestQuery: updatedSearchQueryWithResetTimeline,
      })

      dispatch(updateCurrentSearchQuery(updatedSearchQueryWithResetTimeline))

      if (!!searchRes.content?.length) {
        dispatch([setTimelineBucketResults({ data: [] }), fetchSearchResults(transformedQuery)])
      } else {
        dispatch(fetchSearchResults(transformedQuery))
      }
    }
  } else {
    dispatch(changeSearchLoadingStatus(false))
  }
}

export const updateRecentSearch = ({ newValuesObj }) => (dispatch, getState) => {
  const currentRecentSearchQuery = currentExploreRecentSearchQuery(getState())
  const currentPlatform = selectedPlatformSelector(getState())
  const searchRes = searchResultsSelector(getState())

  const updatedSearchQuery = filterQueryParams(
    currentRecentSearchQuery,
    newValuesObj,
    currentPlatform
  )
  dispatch(changeExploreQuery(updatedSearchQuery))

  const isQueryTheSame = areSearchQueriesEqual(currentRecentSearchQuery, updatedSearchQuery)
  if (!isQueryTheSame) {
    const isRefetch = Object.keys(newValuesObj).every(
      el =>
        el === 'contentTypes' ||
        el === 'uploadedFrom' ||
        el === 'uploadedTo' ||
        el === 'skipCount' ||
        el === 'additionalSkipsForBlacklisting'
    )

    if (isRefetch) {
      dispatch(fetchSearchResults(updatedSearchQuery))
    } else {
      if (!!searchRes.content?.length) {
        dispatch([setTimelineBucketResults({ data: [] }), fetchSearchResults(updatedSearchQuery)])
      } else {
        dispatch(fetchSearchResults(updatedSearchQuery))
      }
    }
  }
}

export const changeCurrentSearchQuery = updatedSearchQuery => dispatch => {
  dispatch([updateCurrentSearchQuery(updatedSearchQuery), fetchSearchResults(updatedSearchQuery)])
}

export const paginateSearchPage = ({
  skipCount,
  additionalSkipsForBlacklisting,
  isExplore = false,
}) => dispatch => {
  isExplore
    ? dispatch(updateRecentSearch({ newValuesObj: { skipCount, additionalSkipsForBlacklisting } }))
    : dispatch(updateSearch({ newValuesObj: { skipCount, additionalSkipsForBlacklisting } }))
}

// TODO: probably refactor this later
export const setSearchUsersSelected = ({ userId = null, selectAll = true }) => (
  dispatch,
  getState
) => {
  const { users } = searchResultsSelector(getState())
  dispatch(
    setSelectedUsersCommonAction({
      nextAction: updateSearchUsersList,
      users,
      userId,
      selectAll,
    })
  )
}

export const changeUserInSearchList = (idToUpdate, changeField, newValue) => async dispatch => {
  dispatch(modifyProfileElementEverywhere({ profileId: idToUpdate, changeField, newValue }))
}

export const sortSearchResults = ({ sortKey, sortDirection }) => async (dispatch, getState) => {
  if (!sortKey || !sortDirection) return
  const currentSearchQuery = currentSearchQuerySelector(getState())
  const { sorting: currentQuerySorting } = currentSearchQuery
  const updatedQuery = { ...currentSearchQuery, skipCount: 0 }

  if (sortKey === SEARCH_SORTING_TYPES.profileScore.sortKey && !!currentQuerySorting) {
    updatedQuery.sorting = '' // set to default
  } else {
    const newSortingDirection =
      sortDirection === SORTING_DIRECTIONS.descend
        ? ''
        : SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES.ascend

    const updatedSortingStr = newSortingDirection
      ? `${newSortingDirection},${sortKey}`
      : `${sortKey}`
    updatedQuery.sorting = updatedSortingStr
    updatedQuery.isSimilarContentPieces = false
  }

  urlQueryService.setNewSearchQuery({
    latestQuery: updatedQuery,
  })
  dispatch(changeCurrentSearchQuery(updatedQuery))
}

export const sortExploreRecentSearchResults = ({ sortKey, sortDirection }) => async (
  dispatch,
  getState
) => {
  if (!sortKey || !sortDirection) return
  const currentSearchQuery = currentExploreRecentSearchQuery(getState())
  const { sorting: currentQuerySorting } = currentSearchQuery
  const updatedQuery = { ...currentSearchQuery, skipCount: 0 }

  if (sortKey === SEARCH_SORTING_TYPES.profileScore.sortKey && !!currentQuerySorting) {
    updatedQuery.sorting = '' // set to default
  } else {
    const newSortingDirection =
      sortDirection === SORTING_DIRECTIONS.descend
        ? ''
        : SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES.ascend

    const updatedSortingStr = newSortingDirection
      ? `${newSortingDirection},${sortKey}`
      : `${sortKey}`
    updatedQuery.sorting = updatedSortingStr
    updatedQuery.isSimilarContentPieces = false
  }

  dispatch([changeExploreQuery(updatedQuery), fetchSearchResults(updatedQuery)])
}

export const updateSearchTypeStatus = searchType => async (dispatch, getState) => {
  const currentPlatform = selectedPlatformSelector(getState())
  const currentQuery =
    searchType === searchContentTypes.CONTENT &&
    currentPlatform === SOCIAL_PLATFORMS_NAMES.instagram
      ? { ...currentSearchQuerySelector(getState()), contentTypes: 'posts,stories,reels' }
      : currentSearchQuerySelector(getState())

  dispatch(updateCurrentSearchQuery(currentQuery))
  urlQueryService.setNewSearchQuery({
    latestQuery: currentQuery,
  })

  dispatch(changeSearchType(searchType))

  if (
    currentPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name &&
    searchType === searchContentTypes.CONTENT
  ) {
    dispatch(triggerSocialPlatform(SOCIAL_PLATFORMS_NAMES.instagram.name))
  }
}

export const getTimelineForContent = query => async (dispatch, getState) => {
  const currentSearchType = searchTypeSelector(getState())
  const stringifiedQuery = queryString.stringify({
    ...query,
    uploadedFrom: '',
    uploadedTo: '',
  })

  if (currentSearchType !== searchContentTypes.CONTENT) return
  dispatch(setTimelineBucketResults({ data: [] }))

  dispatch(changeTimelineBucketLoading(true))
  try {
    const { timelineBucket = [], ...other } =
      (await httpService.fetchTimelineForContent(stringifiedQuery)) || {}
    dispatch(
      setTimelineBucketResults({
        data: timelineBucket,
        ...other,
      })
    )
    dispatch(changeTimelineBucketLoading(false))
  } catch (err) {
    console.error(err)
  }
}

export const getGoogleLocationByLetters = ({ letters }) => async dispatch => {
  dispatch(setSearchLocationLoading(true))
  try {
    const googleLocationByLetters = await httpService.getGoogleLocationByLetters({ letters })
    dispatch(setSearchLocationData(googleLocationByLetters))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setSearchLocationLoading(false))
  }
}

export const addSavedContentToCollection = ({ contentIds, collectionId }) => async dispatch => {
  try {
    await httpService.addElementToCollection({ contentIds, collectionId })
  } catch (err) {
    console.error(err)
  }
}

export const removeSavedContentFromCollection = ({
  contentIds,
  collectionId,
}) => async dispatch => {
  //removeElementFromCollection
  try {
    await httpService.removeElementFromCollection({ contentIds, collectionId })
  } catch (err) {
    console.error(err)
  }
}

export const getSearchBySmartText = ({ inputParam, platform, method = 'textInput', withoutSearchLoading }) => async (dispatch, getState) => { 
  const currentPlatform = selectedPlatformSelector(getState())
  const searchType = searchTypeSelector(getState())
  const { enable_ig_search, enable_tt_search, enable_yt_search } = userGrantSelector(getState()) || {}
  const _RECEIVE_SURVEY_RESULTS_DELAY = 5000

  const grantsPlatform = {
    ['instagram']: enable_ig_search,
    ['tiktok']: enable_tt_search,
    ['youtube']: enable_yt_search
  }

  const isContentType = searchType === searchContentTypes.CONTENT

  dispatch([
    changeSuggestionsLoading(true), 
    !withoutSearchLoading && changeSearchLoadingStatus(true), 
    changeSuggestionsRequestData({}),
    changeShowReceiveSurveyResult(false)
  ])

  try {
    const requestData = {
      inputParam: encodeURIComponent(inputParam), 
      platform: isContentType ? searchContentTypes.CONTENT : platform, 
      method
    }

    const suggestions = await httpService.getSearchBySmartText(requestData)

    if(suggestions?.action === 'analytics') {
      const profileId = suggestions?.searchQuery[0]?._id

      if(profileId) {
        window.open(`/profile/${profileId}`, '_self').focus()
      }
      return;
    }

    if(suggestions) {
      dispatch([changeSuggestionsData(suggestions), changeSuggestionsRequestData(requestData)])

      if(method === 'textInput') {
        setTimeout(() => {
          dispatch(changeShowReceiveSurveyResult(true))
        }, _RECEIVE_SURVEY_RESULTS_DELAY)
      }
    }
    
    if(suggestions.action === 'error') {
      dispatch([
        setError(ERROR_MSG.pleaseSpecifyYourPrompt), 
        changeSuggestionsLoading(false), 
        changeSearchLoadingStatus(false)
      ])
      return;
    }

    if(suggestions?.searchQuery?.length) {
      const newQuery = transformListToDict(suggestions?.searchQuery)

      if(newQuery.index && (currentPlatform?.toLowerCase() !== newQuery?.index?.toLowerCase())) {
        if(grantsPlatform[newQuery.index]) {
          dispatch(changeAppSocialPlatform(newQuery?.index?.toUpperCase()))
        } else {
          dispatch([
            changeSuggestionsLoading(false), 
            changeSearchLoadingStatus(false),
            setError(ERROR_MSG.failedSwitchPlatform)
          ])
          return
        }
      }

      dispatch([
        newQuery.businessSearch && updateSearchTypeStatus(searchContentTypes.BRAND),
        updateSearch({
          newValuesObj: {
            ...newQuery,
            followerMax:
              newQuery?.followerMax === "Infinity"
                ? ""
                : newQuery?.followerMax,
          },
        }),
      ])
    }
  } catch (err) {
    console.error(err)
  } finally {
    dispatch([changeSuggestionsLoading(false)])
  }
}

export const receiveSurveyResult = ({ result }) => async (dispatch, getState) => { 
  const { tempRequestData } = searchSuggestionsReceiveSurveyResultSelector(getState()) || {}
  try {
    await httpService.receiveSurveyResult({
      ...tempRequestData,
      inputParam: decodeURIComponent(tempRequestData.inputParam),
      result
    })
    dispatch(changeShowReceiveSurveyResult(false))
  } catch (err) {
    console.error(err)
  }
}