import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux/init'
import App from './App'
import WithAppThemeProvider from './hocs/WithAppThemeProvider'
import LocalizationProvider from './localization/LocalizationProvider'
import localizationObject from './localization/localizations'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { LicenseInfo } from '@mui/x-license-pro'
import ErrorBoundary from './components/ErrorBoundary'  // Import the ErrorBoundary
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { MSAL_CONFIG } from './authConfig'

import { MUI_PRO_LICENSE_KEY } from './constants/muiPro'
LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY)

import './scss/App.scss'
import './fonts/Inter/inter.css'

const msalInstance = new PublicClientApplication(MSAL_CONFIG)

const container = document.getElementById('root')
if (!container) console.error('Root element not found');  // Add this check
const root = createRoot(container)

console.log('Initial Redux State:', store.getState());  // Log initial Redux state

root.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <MsalProvider instance={msalInstance}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <WithAppThemeProvider>
            <LocalizationProvider localizationObject={localizationObject}>
              <App />
            </LocalizationProvider>
          </WithAppThemeProvider>
        </GoogleOAuthProvider>
      </MsalProvider>
    </ReduxProvider>
  </ErrorBoundary>
)
