import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import useTranslation from "../../../../localization/useTranslation"
import { pretifyBigNum } from "../../../../utils"
import { useHistory } from "react-router-dom"
import {
  AddToCollectionSearchContentIcon,
  AddToFavoriteSearchContentIcon,
  LocationIconSvg,
} from "../../../common/icons"
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined'
import { useDispatch, useSelector } from "react-redux"
import {
  addSavedContentToCollection,
  removeSavedContentFromCollection,
  updateSearch,
} from "../../../../redux/ducks/searchDucks"
import AddToCollectionModal from "../../../collections/collectionTools/AddToCollectionModal"
import { searchContentActiveMapMarker } from "../../../../redux/selectors"
import EditCollectionModal from "../../../collections/collectionTools/EditCollectionModal"
import { Box, Stack, Tooltip } from "@mui/material"
import { capitalizeFirstLetter } from "../../../../new-ui/pages/Search/utils"
import {
  BottomActionButtons,
  BoxItem,
  ContentTypeTypography,
  DateTypography,
  DescriptionBox,
  ImageContainer,
  ImgWrapper,
  InfoHeaderBox,
  ItemsContainer,
  KeywordsBox,
  LabelTypography,
  LocationBox,
  LocationText,
  MainBox,
  MoreButton,
  StyledButton,
  StyledImg,
  TitleTextBox,
  TitleTypography,
  TopActionButtons,
  UsernameTypography,
  ValueTypography,
} from "./styles"

export const ProfileContentItem = ({
  profile = {},
  setActiveCard,
  activeCard = {},
  setRenderedContent,
  isContentFav = false,
  currentQuery
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const activeMapMarker = useSelector(searchContentActiveMapMarker)

  const [showMore, setShowMore] = useState(false)
  const [isAddToCollectionOpened, setIsAddToCollectionOpened] = useState(false)
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] =
    useState(false)
  const [isLoadedCardImg, setIsLoadedCardImg] = useState(false)
  const [isFav, setIsFav] = useState(false)
  const [collectionCount, setCollectionCount] = useState(
    profile.collectionArray,
  )

  useEffect(() => {
    const isFavContent = collectionCount?.find((el) => el === "contentFav")
    isFavContent && setIsFav(true)
  }, [collectionCount])

  const btnShowMoreAction = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowMore(!showMore)
  }

  const goToProfile = (e, profileId) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${process.env.REACT_APP_PREFIX}profile/${profileId}`)
  }

  const onHandleLinkContent = () => {
    if (isAddToCollectionOpened) return

    if (profile.contentType === "story" && profile.user_id) {
      window.open(
        `${process.env.REACT_APP_PREFIX + "profile/" + profile.user_id}`,
        "_blank",
      )
    }
    if (profile.contentType !== "story") {
      window.open(profile.platformLink, "_blank")
    }
    return
  }

  const onAddRemoveFavorites = async (e, ids, isFav) => {
    e.preventDefault()
    e.stopPropagation()

    if (isFav) {
      setIsFav(!isFav)
      const isSuccessful = await dispatch(
        removeSavedContentFromCollection({
          contentIds: [ids],
          collectionId: "contentFav",
        }),
      )
      isContentFav &&
        setRenderedContent((prev) =>
          prev?.filter((el) => el.contentId !== profile.contentId),
        )
      if (!isSuccessful) setIsFav(!isFav)
    } else {
      setIsFav(!isFav)
      const isSuccessful = await dispatch(
        addSavedContentToCollection({
          contentIds: [ids],
          collectionId: "contentFav",
        }),
      )
      if (!isSuccessful) setIsFav(!isFav)
    }
  }

  const onAddToCollection = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsAddToCollectionOpened(true)
  }

  const onGetSimilarContentPieces = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(updateSearch({
      newValuesObj: {
        ...currentQuery,
        contentId: `${activeCard?.contentId}`,
        keywords: ''
      }
    }));
  }

  return (
    <MainBox
      onClick={onHandleLinkContent}
      onMouseEnter={() => setActiveCard(profile)}
      onMouseLeave={() => setActiveCard({})}
      isActive={profile._id === activeMapMarker._id}
      sx={{
        cursor: profile?.contentType === 'story' && 'auto !important'
      }}
    >
      <ImageContainer>
        {isLoadedCardImg && activeCard.contentId === profile.contentId && (
          <TopActionButtons direction='row' alignItems='center' justifyContent='space-between'>
            <Box>
              <Tooltip title={labelStrings.similarContentTooltip}>
                <StyledButton sx={{ minWidth: "40px" }} onClick={onGetSimilarContentPieces}>
                  <AutoFixHighOutlinedIcon />
                </StyledButton>
              </Tooltip>
            </Box>
            <Stack direction='row' alignItems='center' spacing={1}>
              <StyledButton sx={{ minWidth: "40px" }} onClick={onAddToCollection}>
                <AddToCollectionSearchContentIcon />
              </StyledButton>
              <StyledButton
                sx={{ minWidth: "40px" }}
                onClick={(e) => onAddRemoveFavorites(e, profile.contentId, isFav)}
              >
                <AddToFavoriteSearchContentIcon isFav={isFav} />
              </StyledButton>
            </Stack>
          </TopActionButtons>
        )}
        <ImgWrapper className='img-wrapper'>
          <StyledImg
            src={profile.imageUrl}
            alt={profile.username}
            onLoad={() => setIsLoadedCardImg(true)}
          />
        </ImgWrapper>
        {isLoadedCardImg && activeCard.contentId === profile.contentId && (
          <BottomActionButtons direction='row'  alignItems='center' spacing={1}>
            <StyledButton
              variant='contained'
              sx={{
                width: profile.contentType !== "story" ? "60%" : "100%",
              }}
              onClick={(e) => goToProfile(e, profile.profileId)}
            >
              influData Profile
            </StyledButton>
            {profile.contentType !== "story" && (
              <StyledButton
                variant='contained'
                sx={{ width: "40%" }}
                onClick={onHandleLinkContent}
              >
                Go to {capitalizeFirstLetter(profile.contentType || "")}
              </StyledButton>
            )}
          </BottomActionButtons>
        )}
      </ImageContainer>

      <InfoHeaderBox>
        <Box sx={{ width: "50%" }}>
          <ContentTypeTypography>{profile.contentType}</ContentTypeTypography>
        </Box>

        {profile.locationString && (
          <LocationBox>
            <Box sx={{ pl: 1, mr: "4px", color: "#784E4E" }}>
              <LocationIconSvg />
            </Box>
            <LocationText component='span'>
              {profile.locationString}
            </LocationText>
          </LocationBox>
        )}
      </InfoHeaderBox>

      <ItemsContainer>
        <BoxItem>
          <LabelTypography component='span'>
            {labelStrings.uploaded}
          </LabelTypography>
          <DateTypography>{profile.uploaded?.substring(0, 10)}</DateTypography>
        </BoxItem>

        <BoxItem
          sx={{ cursor: "pointer" }}
          onClick={(e) => goToProfile(e, profile.profileId)}
        >
          <LabelTypography component='span'>
            {labelStrings.username}
          </LabelTypography>
          <UsernameTypography>{profile.username}</UsernameTypography>
        </BoxItem>

        {profile.contentType !== "story" && (
          <>
            <BoxItem>
              <LabelTypography component='span'>
                {labelStrings.likes}
              </LabelTypography>
              <ValueTypography>
                {pretifyBigNum(profile.likes || 0)}
              </ValueTypography>
            </BoxItem>

            <BoxItem>
              <LabelTypography component='span'>
                {labelStrings.comments}
              </LabelTypography>
              <ValueTypography>
                {pretifyBigNum(profile.comments || 0)}
              </ValueTypography>
            </BoxItem>
          </>
        )}

        <BoxItem>
          <LabelTypography component='span'>
            {profile.contentType === "reel" ||
            profile.platform === "tiktok" ||
            profile.platform === "youtube"
              ? labelStrings.plays
              : labelStrings.estimatedReach}
          </LabelTypography>
          <ValueTypography>{pretifyBigNum(profile.reach || 0)}</ValueTypography>
        </BoxItem>

        {!!profile.valueFrom && !!profile.valueTo && (
          <BoxItem>
            <LabelTypography component='span'>
              {labelStrings.valueInEur}
            </LabelTypography>
            <ValueTypography>
              {pretifyBigNum(profile.valueFrom)}-
              {pretifyBigNum(profile.valueTo)}
            </ValueTypography>
          </BoxItem>
        )}
      </ItemsContainer>

      <DescriptionBox>
        {profile.title && (
          <>
            <TitleTypography variant='h2'>Title</TitleTypography>
            <TitleTextBox dangerouslySetInnerHTML={{ __html: profile.title }} />
          </>
        )}

        {profile.keywords?.length > 300 ? (
          <>
            <KeywordsBox
              dangerouslySetInnerHTML={{
                __html: showMore
                  ? profile.keywords
                  : `${profile.keywords?.substring(0, 300)}...`,
              }}
            />
            <MoreButton variant='text' onClick={(e) => btnShowMoreAction(e)}>
              {showMore ? labelStrings.showLess : labelStrings.showMore}
            </MoreButton>
          </>
        ) : (
          <KeywordsBox dangerouslySetInnerHTML={{ __html: profile.keywords }} />
        )}
      </DescriptionBox>

      {isAddToCollectionOpened && (
        <AddToCollectionModal
          profile={activeCard}
          platform={profile.platform}
          collectionArray={collectionCount}
          setCollectionCount={setCollectionCount}
          isContent
          closeModal={() => {
            setIsAddToCollectionOpened(false)
          }}
          openCreateCollectionModal={() => {
            setIsCreateCollectionOpened(true)
            setIsAddToCollectionOpened(false)
          }}
        />
      )}

      {isCreateCollectionOpened && (
        <EditCollectionModal
          platform={profile.platform}
          isNewCollection
          closeModal={() => setIsCreateCollectionOpened(false)}
        />
      )}
    </MainBox>
  )
}

ProfileContentItem.propTypes = {
  profile: PropTypes.object,
  setActiveCard: PropTypes.func,
  activeCard: PropTypes.object,
  setRenderedContent: PropTypes.func,
  isContentFav: PropTypes.bool,
  currentQuery: PropTypes.object
}
