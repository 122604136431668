import { keyframes } from "@mui/system"

export const animationHideList = keyframes`
  0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-50px);
	}
`

export const animationShowList = keyframes`
  0% {
		transform: scaleY(0);
		transform-origin: 100% 0%;
	}

	100% {
		transform: scaleY(1);
		transform-origin: 100% 0%;
	}
`

export const animationShowHoverText = keyframes`
  0 0% {
		transform: scaleY(0);
		transform-origin: 100% 0%;
	}

	100% {
		transform: scaleY(1);
		transform-origin: 100% 0%;
	}
`

export const animationHideHoverText = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`

export const animationShowChip = keyframes`
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100px);
	}
`

export const animationHideSearchFilter = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`

export const animationShowSearchFilter = keyframes`
  0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
`

export const animationHidePlatformChip = keyframes`
  0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-50px);
	}
`

export const animationShowPlatformChip = keyframes`
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
`