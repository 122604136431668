import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  findCountryIsoCode,
  replaceAllEmojiInStr,
  validatePassword,
  validatePhone,
} from '../../../utils'
import useTranslation from '../../../localization/useTranslation'
import { Form } from '../../common/styledWrappers/signupFormsStyles/signupStepTwoFormStyles'
import { companySizesOptions, positionOptions } from '../../../constants/signUpStepTwoOptions'
import AmountInfluencersRangeSlider from '../AmountInfluencersRangeSlider'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import GlobeImgFlag from '../../../images/globe-flag-country.png'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import useResponsive from '../../../new-ui/hooks/useResponsive'

const StyledFinishBtn = {
  fontFamily: `Lato, sans-serif`,
  marginTop: '48px',
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#ffffff',
  minWidth: '64px',
  width: 'fit-content',
  height: '46px',
  textTransform: 'capitalize',
  border: 'none',
  borderRadius: '24px',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

const INPUT_TYPES = {
  fN: 'firstName',
  lN: 'lastName',
  com: 'company',
  cntr: 'country',
  phne: 'phone',
  pass: 'password',
  comsz: 'companySize',
  pstn: 'position',
}

const SignupStepTwoForm = props => {
  const { finishSignup, authMethod = '', isSevenDaysTrial } = props || {}

  const { labelStrings, countryOptions } = useTranslation()
  const mediaQueryMaxHeight = useMediaQuery('(max-height:680px)')
  const isMobile = useResponsive('down', 'sm')

  const [formState, changeFormState] = useState({
    [INPUT_TYPES.fN]: '',
    [INPUT_TYPES.lN]: '',
    [INPUT_TYPES.com]: '',
    [INPUT_TYPES.phne]: '',
    [INPUT_TYPES.pass]: '',
    [INPUT_TYPES.cntr]: '',
    [INPUT_TYPES.comsz]: '',
    [INPUT_TYPES.pstn]: '',
    isValidPassword: true,
    isValidFirstName: true,
    isValidLastName: true,
    isValidCompany: true,
    isValidPhone: true,
    isValidCountry: true,
    showPassword: false,
    isValidCompanySize: true,
    isValidPosition: true,
    collaborationCount: 'None',
    signUpMethod: authMethod,
  })

  const createCountriesOptions = useCallback(() => {
    const options = countryOptions.filter(opt => !!opt.value)
    options.push({ value: 'Other', label: labelStrings.other }) // add "Other" option
    return options
  }, [countryOptions])

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'de',
    value: formState.phone,
    countries: defaultCountries,
    onChange: data => {
      const { phone } = data
      changeFormState({ ...formState, [INPUT_TYPES.phne]: phone })
    },
  })

  const countries = createCountriesOptions()

  const validateForExistingValue = value => !!value

  const toggleShowPassword = () =>
    changeFormState({ ...formState, showPassword: !formState.showPassword })

  const onChangeInputHandler = inputType => ({ target }) =>
    changeFormState({ ...formState, [inputType]: target.value })

  const onSelectCountry = event => {
    const {
      target: { value },
    } = event

    const countryCode = findCountryIsoCode(countryOptions, value)
    setCountry(countryCode ? countryCode : 'de')

    return changeFormState({ ...formState, [INPUT_TYPES.cntr]: value })
  }

  const resetInputValidation = validationName => () =>
    !formState[validationName] && changeFormState({ ...formState, [validationName]: true })

  const onSelectCompanySize = event => {
    const {
      target: { value },
    } = event
    return changeFormState({ ...formState, [INPUT_TYPES.comsz]: value })
  }

  const onSelectPosition = event => {
    const {
      target: { value },
    } = event
    return changeFormState({ ...formState, [INPUT_TYPES.pstn]: value })
  }

  const onSelectAmountOfInfluencers = value =>
    changeFormState({ ...formState, collaborationCount: value })

  const submitForm = async e => {
    e.preventDefault()

    const {
      password,
      firstName,
      lastName,
      company,
      phone,
      country,
      position,
      companySize,
    } = formState

    const isValidPassword = (authMethod === 'google' || authMethod === 'azuread') ? true : validatePassword({ password })
    const isValidFirstName = validateForExistingValue(firstName)
    const isValidLastName = validateForExistingValue(lastName)
    const isValidCompany = validateForExistingValue(company)
    const isValidCompanySize = validateForExistingValue(companySize)
    const isValidPosition = validateForExistingValue(position)
    const isValidPhone = validatePhone(phone?.length > 6 ? phone : '')
    const isValidCountry = validateForExistingValue(country)

    const validations = [
      isValidPassword,
      isValidFirstName,
      isValidLastName,
      isValidCompany,
      isValidPhone,
      isValidCountry,
      isValidCompanySize,
      isValidPosition,
    ]

    const isAllValidationsPassed = validations.every(el => el === true)

    changeFormState({
      ...formState,
      isValidPassword,
      isValidFirstName,
      isValidLastName,
      isValidCompany,
      isValidPhone,
      isValidCountry,
      isValidCompanySize,
      isValidPosition,
    })

    if (isAllValidationsPassed) {
      const {
        isValidPassword,
        isValidFirstName,
        isValidLastName,
        isValidCompany,
        isValidPhone,
        isValidCountry,
        isValidCompanySize,
        isValidPosition,
        ...restOfForm
      } = formState

      if (authMethod === 'google' || authMethod === 'azuread') {
        const { password, showPassword, ...payload } = restOfForm
        await finishSignup(payload)
      } else {
        await finishSignup(restOfForm)
      }
    }
  }

  const isValidSelectedCountry = countries?.find(el => el.value === formState.country)
  const isValidCompanySize = companySizesOptions?.find(el => el.value === formState.companySize)
  const isValidPosition = positionOptions?.find(el => el.value === formState.position)

  const countyLabel = ({ value, label, isoCode }) => (
    <Stack direction="row" alignItems="center">
      {isoCode &&
        isoCode
          .split('-')
          .map(code => (
            <img
              key={code}
              src={code === 'all' ? GlobeImgFlag : `https://flagcdn.com/16x12/${code}.png`}
              alt={code}
              style={{ marginRight: '5px' }}
            />
          ))}
      {replaceAllEmojiInStr(label)}
    </Stack>
  )

  useEffect(() => {
    if (!formState[INPUT_TYPES.cntr] && country.name) {
      changeFormState({ ...formState, [INPUT_TYPES.cntr]: country?.name })
    }
  }, [country?.name, formState[INPUT_TYPES.cntr]])

  return (
    <Form onSubmit={submitForm}>
      <Stack direction="row" spacing={2} sx={{ pb: 1.5, pt: isMobile ? 0 : 1.5 }}>
        <TextField
          size="small"
          sx={{
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          label={labelStrings.firstName}
          id="first-name"
          type="text"
          onChange={onChangeInputHandler(INPUT_TYPES.fN)}
          onFocus={resetInputValidation('isValidFirstName')}
          error={!formState.isValidFirstName}
          helperText={!formState.isValidFirstName ? labelStrings.required : null}
        />
        <TextField
          size="small"
          sx={{
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          label={labelStrings.lastName}
          id="last-name"
          type="text"
          onChange={onChangeInputHandler(INPUT_TYPES.lN)}
          onFocus={resetInputValidation('isValidLastName')}
          error={!formState.isValidLastName}
          helperText={!formState.isValidLastName ? labelStrings.required : null}
        />
      </Stack>

      <TextField
        size="small"
        sx={{
          '& .MuiInputBase-input': {
            background: 'white',
            borderRadius: '8px',
          },
        }}
        label={labelStrings.company}
        id="company"
        type="text"
        onChange={onChangeInputHandler(INPUT_TYPES.com)}
        onFocus={resetInputValidation('isValidCompany')}
        error={!formState.isValidCompany}
        helperText={!formState.isValidCompany ? labelStrings.required : null}
      />

      <Stack direction="row" spacing={2} sx={{ py: 1.5 }}>
        <TextField
          select
          size="small"
          id="country-select"
          defaultValue={''}
          value={isValidSelectedCountry?.value || ''}
          label={labelStrings.countryCompany}
          onChange={onSelectCountry}
          onFocus={resetInputValidation('isValidCountry')}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          inputProps={{ 'aria-label': 'label' }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 200,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!formState.isValidCountry}
          helperText={!formState.isValidCountry ? labelStrings.required : null}
        >
          {countries?.map(({ value = '', isoCode = '', label = '' }) => {
            return (
              <MenuItem key={value} value={value}>
                {countyLabel({ value, label, isoCode })}
              </MenuItem>
            )
          })}
        </TextField>

        <TextField
          fullWidth
          id="phone-number"
          size="small"
          label={labelStrings.phone}
          onBlur={() => {
            const phoneNumValue = formState.phone < 6 ? '' : formState.phone
            const isValidPhone = validatePhone(phoneNumValue)
            changeFormState({ ...formState, isValidPhone })
          }}
          value={inputValue}
          onChange={handlePhoneValueChange}
          type="tel"
          inputRef={inputRef}
          sx={{
            '& .MuiInputBase-root, .MuiOutlinedInput-input, .MuiOutlinedInput-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          error={!formState.isValidPhone}
          helperText={!formState.isValidPhone ? labelStrings.required : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
                <Select
                  disableUnderline
                  variant="standard"
                  MenuProps={{
                    style: {
                      height: '300px',
                      width: '360px',
                      top: '10px',
                      left: '-34px',
                      border: 'none',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  sx={{
                    '& .MuiSelect-root': {
                      border: 'none',
                      outline: 'unset',
                      '&:hover': {
                        border: 'none',
                      },
                    },
                    width: 'max-content',
                    fieldset: {
                      display: 'none',
                    },
                    '&.Mui-focused:has(div[aria-expanded="false"])': {
                      fieldset: {
                        display: 'block',
                      },
                    },
                    '.MuiSelect-select': {
                      padding: '8px',
                      paddingRight: '24px !important',
                      backgroundColor: 'white',
                      '&:focus': {
                        backgroundColor: 'white',
                      },
                    },
                    svg: {
                      right: 0,
                    },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  value={country.iso2}
                  onChange={e => setCountry(e.target.value)}
                  renderValue={value => <FlagImage iso2={value} style={{ display: 'flex' }} />}
                >
                  {defaultCountries.map(c => {
                    const country = parseCountry(c)
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
                        <Typography marginRight="8px">{country.name}</Typography>
                        <Typography color="gray">+{country.dialCode}</Typography>
                      </MenuItem>
                    )
                  })}
                </Select>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" spacing={2} sx={{ pb: 1.5 }}>
        <TextField
          select
          size="small"
          id="company-size"
          value={isValidCompanySize?.value || ''}
          label={labelStrings.companySize}
          onChange={onSelectCompanySize}
          onFocus={resetInputValidation('isValidCompanySize')}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          inputProps={{ 'aria-label': 'label' }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 200,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!formState.isValidCompanySize}
          helperText={!formState.isValidCompanySize ? labelStrings.required : null}
        >
          {companySizesOptions?.map(({ value = '' }) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            )
          })}
        </TextField>

        <TextField
          select
          size="small"
          id="company-size"
          value={isValidPosition?.value || ''}
          label={labelStrings.position}
          onChange={onSelectPosition}
          onFocus={resetInputValidation('isValidPosition')}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          inputProps={{ 'aria-label': 'label' }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 200,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!formState.isValidPosition}
          helperText={!formState.isValidPosition ? labelStrings.required : null}
        >
          {positionOptions?.map(({ value = '' }) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            )
          })}
        </TextField>
      </Stack>

      <Box>
        <AmountInfluencersRangeSlider
          value={formState.collaborationCount}
          onChange={onSelectAmountOfInfluencers}
          isMobile={isMobile}
        />
      </Box>

      {(authMethod === 'chargebee' || authMethod === 'utm') && (
        <Box sx={{ pt: isMobile ? 3.5 : 1.5 }}>
          <TextField
            size="small"
            sx={{
              width: '100%',
              background: 'white',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                background: 'white',
                borderRadius: '8px',
              },
            }}
            label={labelStrings.password}
            id="password_input"
            type={formState.showPassword ? 'text' : 'password'}
            onChange={onChangeInputHandler(INPUT_TYPES.pass)}
            onFocus={resetInputValidation('isValidPassword')}
            value={formState.password}
            error={!formState.isValidPassword}
            helperText={!formState.isValidPassword ? labelStrings.passwordLimit : null}
            inputProps={{ maxLength: 40 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    onMouseDown={e => e.preventDefault()}
                    edge="end"
                    sx={{ background: 'white' }}
                  >
                    {formState.showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      <Button
        variant="contained"
        sx={{
          ...StyledFinishBtn,
          marginTop: mediaQueryMaxHeight ? '18px' : StyledFinishBtn.marginTop,
          width: isMobile ? '100%' : StyledFinishBtn.width,
        }}
        type="submit"
      >
        {isSevenDaysTrial ? labelStrings.signupLocalization.startTrialNow : labelStrings.signupLocalization.continue}
      </Button>
    </Form>
  )
}

SignupStepTwoForm.propTypes = {
  finishSignup: PropTypes.func,
  authMethod: PropTypes.string,
}

export default SignupStepTwoForm
