import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { withReduxActions } from '../../../../hocs/withRedux'
import { useSelector } from 'react-redux'
import {
  canCreateCampaignsSelector,
  campaignsListSelector,
  currentOpenedCampaignSelector,
} from '../../../../redux/selectors'
import { createCampaign } from '../../../../redux/ducks/campaignsDucks'
import useTranslation from '../../../../localization/useTranslation'

import CampaignsSidebar from './CampaignsSidebar'
import CampaignsLimitMonitorContainer from '../../../../components/CampaignsLimitMonitor/CampaignsLimitMonitorContainer'
import WithUpgradeTip from '../../../../components/common/popups/WithUpgradeTip'
import { WithScrollWrapper } from '../../../../components/common/styledWrappers'


const CampaignsSidebarContainer = ({ disabled, createCampaign }) => {
  const { labelStrings } = useTranslation()
  const { campaignId: campaignIdFromUrl } = useParams()
  const canCreateCampaigns = useSelector(canCreateCampaignsSelector)
  const campaignsList = useSelector(campaignsListSelector)
  const { campaignId: currentCampaignId } = useSelector(currentOpenedCampaignSelector)

  const createSidebarCampaignsList = useCallback(() => {
    if (!campaignsList?.length) return []
    return campaignsList?.map(({ name, campaignId }) => ({
      name,
      campaignId,
      isSelected: campaignId === currentCampaignId && campaignId === campaignIdFromUrl,
      // TODO: add notifications flag if some changes are present
    }))
  }, [campaignsList, currentCampaignId, campaignIdFromUrl])

  const createNewCampaign = campaignData => createCampaign(campaignData)

  const RenderComponent = (
    <div>
      <CampaignsLimitMonitorContainer disabled={disabled} />
      <CampaignsSidebar
        disabled={disabled}
        canCreateCampaigns={canCreateCampaigns}
        createNewCampaign={createNewCampaign}
        campaignsList={createSidebarCampaignsList()}
      />
    </div>
  )

  return disabled ? (
    <WithUpgradeTip tipText={labelStrings.useTracking}>{RenderComponent}</WithUpgradeTip>
  ) : (
    <WithScrollWrapper watchHeight showScroll>
      {RenderComponent}
    </WithScrollWrapper>
  )
}

CampaignsSidebarContainer.propTypes = {
  disabled: PropTypes.bool,
  createCampaign: PropTypes.func,
}

export default withReduxActions({ createCampaign })(CampaignsSidebarContainer)
