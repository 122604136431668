import React from "react"
import { Stack, Skeleton } from "@mui/material"

export const ChipSkeletonLoader = () => {
  const searchParamsSkeleton = (
    <Stack
      direction='row'
      alignItems='center'
      spacing={1}
      sx={{ backgroundColor: "#F6DBDB", p: 1, borderRadius: 3 }}
    >
      <Skeleton
        sx={{ backgroundColor: "#C79C9C" }}
        variant='rounded'
        width={85}
        height={11}
      />
      <Skeleton
        sx={{ backgroundColor: "#C79C9C" }}
        variant='rounded'
        width={17}
        height={11}
      />
    </Stack>
  )

  const suggestionSkeleton = (
    <Stack
      direction='row'
      alignItems='center'
      spacing={1}
      sx={{ backgroundColor: "#FFFFFF", p: 1, borderRadius: 3 }}
    >
      <Skeleton
        sx={{ backgroundColor: "#F6DBDB" }}
        variant='rounded'
        width={110}
        height={11}
      />
      <Skeleton
        sx={{ backgroundColor: "#F6DBDB" }}
        variant='rounded'
        width={30}
        height={11}
      />
    </Stack>
  )

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {searchParamsSkeleton}
      {searchParamsSkeleton}
      {searchParamsSkeleton}
      {searchParamsSkeleton}
      {searchParamsSkeleton}
      {suggestionSkeleton}
      {suggestionSkeleton}
    </Stack>
  )
}
