import queryString from 'query-string'

class UrlQueryService {
  convertStringToNumber(stringedNumber) {
    return stringedNumber === 'Infinity' ? Infinity : parseInt(stringedNumber, 10)
  }

  parseCurrentSearchQuery(location, existingQuery) {
    const {
      playsMin,
      playsMax,
      viewsMin,
      viewsMax,
      reelPlaysMin,
      reelPlaysMax,
      followerMin,
      followerMax,
      businessSearch,
      growthRate,
      engagementRate,
      audienceRealPeople,
      ...queryParameters
    } = queryString.parse(location.search)

    const queryObj = {
      ...queryParameters,
      ...(followerMin && { followerMin: this.convertStringToNumber(followerMin) }),
      ...(followerMax && { followerMax: this.convertStringToNumber(followerMax) }),
      mustIncludeEmail: queryParameters.mustIncludeEmail === 'true', // convert bool value in string to bool
      matchAnyKeyword: queryParameters.matchAnyKeyword === 'true', // convert bool value in string to bool
      skipCount: this.convertStringToNumber(queryParameters.skipCount || 0),
      additionalSkipsForBlacklisting: this.convertStringToNumber(queryParameters.additionalSkipsForBlacklisting || 0),
      ...(playsMax && { playsMax: this.convertStringToNumber(playsMax) }),
      ...(playsMin && { playsMin: this.convertStringToNumber(playsMin) }),
      ...(viewsMin && { viewsMin: this.convertStringToNumber(viewsMin) }),
      ...(viewsMax && { viewsMax: this.convertStringToNumber(viewsMax) }),
      ...(reelPlaysMin && { reelPlaysMin: this.convertStringToNumber(reelPlaysMin) }),
      ...(reelPlaysMax && { reelPlaysMax: this.convertStringToNumber(reelPlaysMax) }),
      ...(businessSearch === 'true' && { businessSearch: true }),
      ...(growthRate && { growthRate: this.convertStringToNumber(growthRate) || null }), // we avoid putting 0 to store, because 0 means "off" in this case
      ...(engagementRate && { engagementRate: this.convertStringToNumber(engagementRate) || null }),
      ...(audienceRealPeople && {
        audienceRealPeople: this.convertStringToNumber(audienceRealPeople) || null,
      }),
    }
    const returnQuery = { ...existingQuery, ...queryObj } // merge with existing query params to replace diff ones and correspond with query shape for api
    return returnQuery
  }

  setNewSearchQuery({
    // location, history,
    latestQuery,
  }) {
    const query = queryString.stringify(latestQuery, {
      skipNull: true,
      skipEmptyString: true,
    })
    // react history and location replaced with native history object to avoid passing needless args
    window && window.history.replaceState(null, '/search', `/search?${query}`)

    //
    // history.replace({
    //   pathname: location.pathname,
    //   search: queryString.stringify(latestQuery),
    // })
  }

  parseProfileQuery() {
    if (!window || !window.location) return null
    const { token } = queryString.parse(window.location.search)
    return token || null
  }

  setProfileQueryToken(token) {
    if (!window || !window.location || !token) return
    window.history.replaceState(
      null,
      window.location.pathname,
      `${window.location.pathname}?token=${token}`
    )
  }
}

export default new UrlQueryService()
