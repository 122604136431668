import React, { useEffect, useState } from "react"
import useTranslation from "../../../../../localization/useTranslation"
import { Box, Slider, Stack, Typography, Chip } from "@mui/material"
import { infludataThemes } from "../../../../../themes/infludataThemes"

export const AudienceByAgeFilter = (props) => {
  const { selectedPlatform, query, handlersMap, permissions } = props || {}

  const { labelStrings } = useTranslation()

  const [localSliderValue, setLocalSliderValue] = useState([13, 37])

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue)
  }

  const onChangeQueryValues = (e, value) => {
    handlersMap.audience({
      audienceCountries: query.audienceCountries,
      audienceGender: query.audienceGender,
      audienceRealPeople: query.audienceRealPeople,
      audienceAgeRanges: value?.toString(),
      audienceLanguages: query.audienceLanguages,
    })
  }

  useEffect(() => {
    const splittedValue = query?.audienceAgeRanges?.length ? query?.audienceAgeRanges?.split(',') : [13, 37]
    setLocalSliderValue(splittedValue)
  }, [])


  return (
    <Stack sx={{ px: 1}}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Typography
        sx={{
          color: "#784E4E",
          fontWeight: 700,
          fontSize: "11px",
          textTransform: "uppercase",
          fontFamily: "Inter",
        }}
      >
        {labelStrings.audienceByAvgAge}
      </Typography>
      <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ width: "80px" }}
        >
          <Chip
            size='small'
            label={`${localSliderValue[0]}-${localSliderValue[1] > 37 ? 37 : localSliderValue[1]}`}
            sx={{
              backgroundColor: infludataThemes[selectedPlatform]?.platformColor,
              color: "white",
              fontWeight: "bold",
            }}
          />
        </Stack>
      </Stack>
      <Box sx={{ pb: 0.5 }}>
        <Slider
          sx={{
            width: "100%",
            height: "5px",
            borderRadius: "8px",
            color: infludataThemes[selectedPlatform]?.platformColor,
            "& .MuiSlider-valueLabelOpen": {
              fontWeight: "bold",
              color: infludataThemes[selectedPlatform]?.platformColor
            },
            '& .MuiSlider-valueLabel': {
              background: 'unset',
              transformOrigin: 'bottom left',
              transform: 'translate(50%, 100%) rotate(45deg) scale(0)',
              '&::before': { display: 'none' },
              '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -40%) rotate(-225deg) scale(1)',
              },
              '& > *': {
                transform: 'rotate(-135deg)',
              },
            },
          }}
          value={localSliderValue}
          onChange={handleChange}
          onChangeCommitted={onChangeQueryValues}
          valueLabelDisplay='on'
          min={13}
          max={37}
        />
      </Box>
    </Stack>
  )
}
