import { useEffect, useState } from "react";

export const useElementHeight = (className, dependency) => {
  const [height, setHeight] = useState(null)

  useEffect(() => {
    const sanitizedClassName = className
      .replace(/^\./, "")
      .replace(/\\/g, "\\\\")
    const selector = `.${CSS.escape(sanitizedClassName)}`
    const element = document.querySelector(selector)

    const updateHeight = () => {
      if (element) {
        setHeight(element.clientHeight)
      }
    }

    updateHeight()

    const observer = new MutationObserver(updateHeight)
    if (element)
      observer.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
      })

    if (dependency) updateHeight()

    return () => {
      if (element) observer.disconnect()
    };
  }, [className, dependency])

  return height
}
